/* tslint:disable */
/* eslint-disable */
/**
 * BackendMain
 * The LifefitMainBack API description
 *
 * The version of the OpenAPI document: 1738747913557
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ActiveCouponsInfo
 */
export interface ActiveCouponsInfo {
  /**
   *
   * @type {Array<CouponInfo>}
   * @memberof ActiveCouponsInfo
   */
  availableCoupons: Array<CouponInfo>;
  /**
   *
   * @type {Array<CouponInfo>}
   * @memberof ActiveCouponsInfo
   */
  unavailableCoupons: Array<CouponInfo>;
}
/**
 *
 * @export
 * @interface AddPointsRequest
 */
export interface AddPointsRequest {
  /**
   *
   * @type {number}
   * @memberof AddPointsRequest
   */
  points: number;
}
/**
 *
 * @export
 * @interface AdminAppContent
 */
export interface AdminAppContent {
  /**
   *
   * @type {number}
   * @memberof AdminAppContent
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof AdminAppContent
   */
  isPublished: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminAppContent
   */
  title: string;
  /**
   *
   * @type {AdminAppContentSection}
   * @memberof AdminAppContent
   */
  section: AdminAppContentSection;
  /**
   *
   * @type {string}
   * @memberof AdminAppContent
   */
  iconUrl: string;
  /**
   *
   * @type {string}
   * @memberof AdminAppContent
   */
  contentType: AdminAppContentContentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AdminAppContent
   */
  webUrl: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminAppContent
   */
  isShownOnHome: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AdminAppContent
   */
  isRequiredAuth: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AdminAppContent
   */
  isNew: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminAppContent
   */
  publishedDatetime: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum AdminAppContentContentTypeEnum {
  Web = 'Web',
  MySchedule = 'MySchedule',
  FriendInvitation = 'FriendInvitation',
}

/**
 *
 * @export
 * @interface AdminAppContentListContent
 */
export interface AdminAppContentListContent {
  /**
   *
   * @type {number}
   * @memberof AdminAppContentListContent
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof AdminAppContentListContent
   */
  isPublished: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminAppContentListContent
   */
  title: string;
  /**
   *
   * @type {AdminAppContentSection}
   * @memberof AdminAppContentListContent
   */
  section: AdminAppContentSection;
  /**
   *
   * @type {boolean}
   * @memberof AdminAppContentListContent
   */
  isShownOnHome: boolean;
}
/**
 *
 * @export
 * @interface AdminAppContentSection
 */
export interface AdminAppContentSection {
  /**
   *
   * @type {number}
   * @memberof AdminAppContentSection
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminAppContentSection
   */
  title: string;
}
/**
 *
 * @export
 * @interface AdminAppContentSectionWithContents
 */
export interface AdminAppContentSectionWithContents {
  /**
   *
   * @type {number}
   * @memberof AdminAppContentSectionWithContents
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminAppContentSectionWithContents
   */
  title: string;
  /**
   *
   * @type {Array<AdminAppContentListContent>}
   * @memberof AdminAppContentSectionWithContents
   */
  appContents: Array<AdminAppContentListContent>;
}
/**
 *
 * @export
 * @interface AdminCreateAppContentRequest
 */
export interface AdminCreateAppContentRequest {
  /**
   *
   * @type {string}
   * @memberof AdminCreateAppContentRequest
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof AdminCreateAppContentRequest
   */
  sectionId: number;
  /**
   *
   * @type {string}
   * @memberof AdminCreateAppContentRequest
   */
  contentType: AdminCreateAppContentRequestContentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AdminCreateAppContentRequest
   */
  webUrl: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminCreateAppContentRequest
   */
  isShownOnHome: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AdminCreateAppContentRequest
   */
  isRequiredAuth: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum AdminCreateAppContentRequestContentTypeEnum {
  Web = 'Web',
  MySchedule = 'MySchedule',
  FriendInvitation = 'FriendInvitation',
}

/**
 *
 * @export
 * @interface AdminCreateAppContentResponse
 */
export interface AdminCreateAppContentResponse {
  /**
   *
   * @type {AdminAppContent}
   * @memberof AdminCreateAppContentResponse
   */
  appContent: AdminAppContent;
}
/**
 *
 * @export
 * @interface AdminCreateCampaignCodeRequest
 */
export interface AdminCreateCampaignCodeRequest {
  /**
   *
   * @type {number}
   * @memberof AdminCreateCampaignCodeRequest
   */
  campaignId: number;
  /**
   *
   * @type {string}
   * @memberof AdminCreateCampaignCodeRequest
   */
  campaignCode: string;
}
/**
 *
 * @export
 * @interface AdminCreateCampaignCodeResponse
 */
export interface AdminCreateCampaignCodeResponse {
  /**
   *
   * @type {string}
   * @memberof AdminCreateCampaignCodeResponse
   */
  campaignCode: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AdminCreateCampaignCodeResponse
   */
  errorMessages: Array<string>;
}
/**
 *
 * @export
 * @interface AdminCreateCampaignRequest
 */
export interface AdminCreateCampaignRequest {
  /**
   *
   * @type {string}
   * @memberof AdminCreateCampaignRequest
   */
  type: AdminCreateCampaignRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AdminCreateCampaignRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCreateCampaignRequest
   */
  targetType: AdminCreateCampaignRequestTargetTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AdminCreateCampaignRequest
   */
  usageRestriction: AdminCreateCampaignRequestUsageRestrictionEnum;
  /**
   *
   * @type {Array<number>}
   * @memberof AdminCreateCampaignRequest
   */
  targetGymIds: Array<number> | null;
  /**
   *
   * @type {number}
   * @memberof AdminCreateCampaignRequest
   */
  validNumOfUses: number;
  /**
   *
   * @type {number}
   * @memberof AdminCreateCampaignRequest
   */
  minimumSubscriptionRenewalCount: number;
  /**
   *
   * @type {number}
   * @memberof AdminCreateCampaignRequest
   */
  discountRate: number | null;
  /**
   *
   * @type {number}
   * @memberof AdminCreateCampaignRequest
   */
  discountAmount: number | null;
  /**
   *
   * @type {number}
   * @memberof AdminCreateCampaignRequest
   */
  expirationDays: number | null;
  /**
   *
   * @type {string}
   * @memberof AdminCreateCampaignRequest
   */
  expiresDatetime: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AdminCreateCampaignRequest
   */
  onlySpecificGymsRestriction: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof AdminCreateCampaignRequest
   */
  campaignCodes: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof AdminCreateCampaignRequest
   */
  subscribePlanIds: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum AdminCreateCampaignRequestTypeEnum {
  Free = 'Free',
  Referral = 'Referral',
  FriendInvitation = 'FriendInvitation',
}
/**
 * @export
 * @enum {string}
 */
export enum AdminCreateCampaignRequestTargetTypeEnum {
  Subscribe = 'Subscribe',
  TemporaryUse = 'TemporaryUse',
}
/**
 * @export
 * @enum {string}
 */
export enum AdminCreateCampaignRequestUsageRestrictionEnum {
  None = 'None',
  CompletelyFirstTime = 'CompletelyFirstTime',
  SubscribeFirstTime = 'SubscribeFirstTime',
  SubscribeUpdateTime = 'SubscribeUpdateTime',
}

/**
 *
 * @export
 * @interface AdminCreateCampaignResponse
 */
export interface AdminCreateCampaignResponse {
  /**
   *
   * @type {Campaign}
   * @memberof AdminCreateCampaignResponse
   */
  campaign: Campaign;
}
/**
 *
 * @export
 * @interface AdminCreatePresignedUrlForAppContentRequest
 */
export interface AdminCreatePresignedUrlForAppContentRequest {
  /**
   *
   * @type {string}
   * @memberof AdminCreatePresignedUrlForAppContentRequest
   */
  fileExtension: string;
}
/**
 *
 * @export
 * @interface AdminCreatePresignedUrlForAppContentResponse
 */
export interface AdminCreatePresignedUrlForAppContentResponse {
  /**
   *
   * @type {string}
   * @memberof AdminCreatePresignedUrlForAppContentResponse
   */
  presignedUrl: string;
  /**
   *
   * @type {string}
   * @memberof AdminCreatePresignedUrlForAppContentResponse
   */
  src: string;
}
/**
 *
 * @export
 * @interface AdminCreatePresignedUrlForRewardRequest
 */
export interface AdminCreatePresignedUrlForRewardRequest {
  /**
   *
   * @type {string}
   * @memberof AdminCreatePresignedUrlForRewardRequest
   */
  fileExtension: string;
}
/**
 *
 * @export
 * @interface AdminCreatePresignedUrlForRewardResponse
 */
export interface AdminCreatePresignedUrlForRewardResponse {
  /**
   *
   * @type {string}
   * @memberof AdminCreatePresignedUrlForRewardResponse
   */
  presignedUrl: string;
  /**
   *
   * @type {string}
   * @memberof AdminCreatePresignedUrlForRewardResponse
   */
  src: string;
}
/**
 *
 * @export
 * @interface AdminCreateRewardRequest
 */
export interface AdminCreateRewardRequest {
  /**
   *
   * @type {string}
   * @memberof AdminCreateRewardRequest
   */
  usageType: AdminCreateRewardRequestUsageTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AdminCreateRewardRequestUsageTypeEnum {
  InPerson = 'InPerson',
  CouponCode = 'CouponCode',
  UniqueCouponCode = 'UniqueCouponCode',
  Campaign = 'Campaign',
}

/**
 *
 * @export
 * @interface AdminCreateRewardResponse
 */
export interface AdminCreateRewardResponse {
  /**
   *
   * @type {AdminReward}
   * @memberof AdminCreateRewardResponse
   */
  reward: AdminReward;
}
/**
 *
 * @export
 * @interface AdminGetAppContentResponse
 */
export interface AdminGetAppContentResponse {
  /**
   *
   * @type {AdminAppContent}
   * @memberof AdminGetAppContentResponse
   */
  appContent: AdminAppContent;
}
/**
 *
 * @export
 * @interface AdminGetAppContentSectionsResponse
 */
export interface AdminGetAppContentSectionsResponse {
  /**
   *
   * @type {Array<AdminAppContentSection>}
   * @memberof AdminGetAppContentSectionsResponse
   */
  appContentSections: Array<AdminAppContentSection>;
}
/**
 *
 * @export
 * @interface AdminGetAppContentSectionsWithContentsResponse
 */
export interface AdminGetAppContentSectionsWithContentsResponse {
  /**
   *
   * @type {Array<AdminAppContentSectionWithContents>}
   * @memberof AdminGetAppContentSectionsWithContentsResponse
   */
  appContentSectionsWithContents: Array<AdminAppContentSectionWithContents>;
}
/**
 *
 * @export
 * @interface AdminGetCampaignResponse
 */
export interface AdminGetCampaignResponse {
  /**
   *
   * @type {Campaign}
   * @memberof AdminGetCampaignResponse
   */
  campaign: Campaign;
}
/**
 *
 * @export
 * @interface AdminGetCampaignsRequest
 */
export interface AdminGetCampaignsRequest {
  /**
   *
   * @type {number}
   * @memberof AdminGetCampaignsRequest
   */
  pageNumber?: number;
}
/**
 *
 * @export
 * @interface AdminGetCampaignsResponse
 */
export interface AdminGetCampaignsResponse {
  /**
   *
   * @type {number}
   * @memberof AdminGetCampaignsResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof AdminGetCampaignsResponse
   */
  numberOfCampaigns: number;
  /**
   *
   * @type {Array<CampaignListItem>}
   * @memberof AdminGetCampaignsResponse
   */
  campaigns: Array<CampaignListItem>;
}
/**
 *
 * @export
 * @interface AdminGetMessagesResponse
 */
export interface AdminGetMessagesResponse {
  /**
   *
   * @type {Array<MessageText | MessageRadio>}
   * @memberof AdminGetMessagesResponse
   */
  messages: Array<MessageText | MessageRadio>;
}
/**
 *
 * @export
 * @interface AdminGetRewardResponse
 */
export interface AdminGetRewardResponse {
  /**
   *
   * @type {AdminReward}
   * @memberof AdminGetRewardResponse
   */
  reward: AdminReward;
}
/**
 *
 * @export
 * @interface AdminGetRewardTagsResponse
 */
export interface AdminGetRewardTagsResponse {
  /**
   *
   * @type {Array<AdminRewardTag>}
   * @memberof AdminGetRewardTagsResponse
   */
  rewardTags: Array<AdminRewardTag>;
}
/**
 *
 * @export
 * @interface AdminGetRewardsResponse
 */
export interface AdminGetRewardsResponse {
  /**
   *
   * @type {Array<AdminRewardListContent>}
   * @memberof AdminGetRewardsResponse
   */
  rewards: Array<AdminRewardListContent>;
  /**
   *
   * @type {number}
   * @memberof AdminGetRewardsResponse
   */
  currentPage: number;
  /**
   *
   * @type {number}
   * @memberof AdminGetRewardsResponse
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface AdminGetSubscribeRequest
 */
export interface AdminGetSubscribeRequest {
  /**
   *
   * @type {number}
   * @memberof AdminGetSubscribeRequest
   */
  userId: number;
}
/**
 *
 * @export
 * @interface AdminGetSubscribeResponse
 */
export interface AdminGetSubscribeResponse {
  /**
   *
   * @type {Array<Subscribe>}
   * @memberof AdminGetSubscribeResponse
   */
  subscribes: Array<Subscribe>;
}
/**
 *
 * @export
 * @interface AdminGetTempUseTicketRequest
 */
export interface AdminGetTempUseTicketRequest {
  /**
   *
   * @type {number}
   * @memberof AdminGetTempUseTicketRequest
   */
  userId: number;
}
/**
 *
 * @export
 * @interface AdminGetTempUseTicketResponse
 */
export interface AdminGetTempUseTicketResponse {
  /**
   *
   * @type {Array<TemporaryUse>}
   * @memberof AdminGetTempUseTicketResponse
   */
  temporaryUses: Array<TemporaryUse>;
}
/**
 *
 * @export
 * @interface AdminMessagesUsersRequest
 */
export interface AdminMessagesUsersRequest {
  /**
   *
   * @type {string}
   * @memberof AdminMessagesUsersRequest
   */
  order: AdminMessagesUsersRequestOrderEnum;
  /**
   *
   * @type {number}
   * @memberof AdminMessagesUsersRequest
   */
  pageNumber?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum AdminMessagesUsersRequestOrderEnum {
  Desc = 'desc',
  Asc = 'asc',
}

/**
 *
 * @export
 * @interface AdminMessagesUsersResponse
 */
export interface AdminMessagesUsersResponse {
  /**
   *
   * @type {Array<AdminMessagesUsersResponseUsers>}
   * @memberof AdminMessagesUsersResponse
   */
  users: Array<AdminMessagesUsersResponseUsers>;
  /**
   *
   * @type {number}
   * @memberof AdminMessagesUsersResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof AdminMessagesUsersResponse
   */
  numberOfUsers: number;
}
/**
 *
 * @export
 * @interface AdminMessagesUsersResponseUsers
 */
export interface AdminMessagesUsersResponseUsers {
  /**
   *
   * @type {number}
   * @memberof AdminMessagesUsersResponseUsers
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AdminMessagesUsersResponseUsers
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AdminMessagesUsersResponseUsers
   */
  registerDatetime?: string;
}
/**
 *
 * @export
 * @interface AdminNextUserSubscribe
 */
export interface AdminNextUserSubscribe {
  /**
   *
   * @type {string}
   * @memberof AdminNextUserSubscribe
   */
  startDatetime: string;
  /**
   *
   * @type {AdminUserSubscribePlan}
   * @memberof AdminNextUserSubscribe
   */
  plan: AdminUserSubscribePlan;
}
/**
 *
 * @export
 * @interface AdminReward
 */
export interface AdminReward {
  /**
   *
   * @type {number}
   * @memberof AdminReward
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof AdminReward
   */
  published: boolean;
  /**
   *
   * @type {AdminRewardTag}
   * @memberof AdminReward
   */
  tag: AdminRewardTag;
  /**
   *
   * @type {string}
   * @memberof AdminReward
   */
  name: string;
  /**
   *
   * @type {ImageSrc}
   * @memberof AdminReward
   */
  thumbnailSrc: ImageSrc;
  /**
   *
   * @type {number}
   * @memberof AdminReward
   */
  requiredPoints: number;
  /**
   *
   * @type {number}
   * @memberof AdminReward
   */
  usageLimits: number | null;
  /**
   *
   * @type {string}
   * @memberof AdminReward
   */
  expiredDatetime: string | null;
  /**
   *
   * @type {string}
   * @memberof AdminReward
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AdminReward
   */
  caution: string;
  /**
   *
   * @type {string}
   * @memberof AdminReward
   */
  usageType: AdminRewardUsageTypeEnum;
  /**
   *
   * @type {AdminRewardProvider}
   * @memberof AdminReward
   */
  provider: AdminRewardProvider;
  /**
   *
   * @type {Array<string>}
   * @memberof AdminReward
   */
  couponCodes: Array<string>;
  /**
   *
   * @type {number}
   * @memberof AdminReward
   */
  issueLimits: number | null;
  /**
   *
   * @type {Array<AdminRewardTargetGym>}
   * @memberof AdminReward
   */
  targetGyms: Array<AdminRewardTargetGym>;
}

/**
 * @export
 * @enum {string}
 */
export enum AdminRewardUsageTypeEnum {
  InPerson = 'InPerson',
  CouponCode = 'CouponCode',
  UniqueCouponCode = 'UniqueCouponCode',
  Campaign = 'Campaign',
}

/**
 *
 * @export
 * @interface AdminRewardListContent
 */
export interface AdminRewardListContent {
  /**
   *
   * @type {number}
   * @memberof AdminRewardListContent
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminRewardListContent
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminRewardListContent
   */
  providerName: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminRewardListContent
   */
  published: boolean;
  /**
   *
   * @type {Array<AdminRewardTargetGym>}
   * @memberof AdminRewardListContent
   */
  targetGyms: Array<AdminRewardTargetGym>;
}
/**
 *
 * @export
 * @interface AdminRewardProvider
 */
export interface AdminRewardProvider {
  /**
   *
   * @type {number}
   * @memberof AdminRewardProvider
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminRewardProvider
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminRewardProvider
   */
  address: string;
  /**
   *
   * @type {number}
   * @memberof AdminRewardProvider
   */
  longitude: number | null;
  /**
   *
   * @type {number}
   * @memberof AdminRewardProvider
   */
  latitude: number | null;
  /**
   *
   * @type {string}
   * @memberof AdminRewardProvider
   */
  url: string;
  /**
   *
   * @type {AdminRewardReferralGym}
   * @memberof AdminRewardProvider
   */
  referralGym: AdminRewardReferralGym | null;
}
/**
 *
 * @export
 * @interface AdminRewardProviderRequest
 */
export interface AdminRewardProviderRequest {
  /**
   *
   * @type {string}
   * @memberof AdminRewardProviderRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminRewardProviderRequest
   */
  address: string;
  /**
   *
   * @type {number}
   * @memberof AdminRewardProviderRequest
   */
  longitude: number | null;
  /**
   *
   * @type {number}
   * @memberof AdminRewardProviderRequest
   */
  latitude: number | null;
  /**
   *
   * @type {string}
   * @memberof AdminRewardProviderRequest
   */
  url: string;
  /**
   *
   * @type {number}
   * @memberof AdminRewardProviderRequest
   */
  referralGymId: number | null;
}
/**
 *
 * @export
 * @interface AdminRewardReferralGym
 */
export interface AdminRewardReferralGym {
  /**
   *
   * @type {number}
   * @memberof AdminRewardReferralGym
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminRewardReferralGym
   */
  name: string;
}
/**
 *
 * @export
 * @interface AdminRewardTag
 */
export interface AdminRewardTag {
  /**
   *
   * @type {number}
   * @memberof AdminRewardTag
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminRewardTag
   */
  tagKey: string;
  /**
   *
   * @type {string}
   * @memberof AdminRewardTag
   */
  displayName: string;
  /**
   *
   * @type {number}
   * @memberof AdminRewardTag
   */
  priority: number;
}
/**
 *
 * @export
 * @interface AdminRewardTargetGym
 */
export interface AdminRewardTargetGym {
  /**
   *
   * @type {number}
   * @memberof AdminRewardTargetGym
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminRewardTargetGym
   */
  name: string;
}
/**
 *
 * @export
 * @interface AdminSendMessageRequest
 */
export interface AdminSendMessageRequest {
  /**
   *
   * @type {string}
   * @memberof AdminSendMessageRequest
   */
  messageText: string;
  /**
   *
   * @type {number}
   * @memberof AdminSendMessageRequest
   */
  userId: number;
}
/**
 *
 * @export
 * @interface AdminSendMessageResponse
 */
export interface AdminSendMessageResponse {
  /**
   *
   * @type {boolean}
   * @memberof AdminSendMessageResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface AdminUpdateAppContentRequest
 */
export interface AdminUpdateAppContentRequest {
  /**
   *
   * @type {boolean}
   * @memberof AdminUpdateAppContentRequest
   */
  isPublished: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateAppContentRequest
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof AdminUpdateAppContentRequest
   */
  sectionId: number;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateAppContentRequest
   */
  iconUrl: string;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateAppContentRequest
   */
  contentType: AdminUpdateAppContentRequestContentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateAppContentRequest
   */
  webUrl: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminUpdateAppContentRequest
   */
  isShownOnHome: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AdminUpdateAppContentRequest
   */
  isRequiredAuth: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum AdminUpdateAppContentRequestContentTypeEnum {
  Web = 'Web',
  MySchedule = 'MySchedule',
  FriendInvitation = 'FriendInvitation',
}

/**
 *
 * @export
 * @interface AdminUpdateAppContentResponse
 */
export interface AdminUpdateAppContentResponse {
  /**
   *
   * @type {AdminAppContent}
   * @memberof AdminUpdateAppContentResponse
   */
  appContent: AdminAppContent;
}
/**
 *
 * @export
 * @interface AdminUpdateAppContentSectionContentsPriorityRequest
 */
export interface AdminUpdateAppContentSectionContentsPriorityRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof AdminUpdateAppContentSectionContentsPriorityRequest
   */
  orderedAppContentIds: Array<number>;
}
/**
 *
 * @export
 * @interface AdminUpdateAppContentSectionContentsPriorityResponse
 */
export interface AdminUpdateAppContentSectionContentsPriorityResponse {
  /**
   *
   * @type {Array<AdminAppContentListContent>}
   * @memberof AdminUpdateAppContentSectionContentsPriorityResponse
   */
  appContents: Array<AdminAppContentListContent>;
}
/**
 *
 * @export
 * @interface AdminUpdateCampaignRequest
 */
export interface AdminUpdateCampaignRequest {
  /**
   *
   * @type {object}
   * @memberof AdminUpdateCampaignRequest
   */
  expiration: object;
  /**
   *
   * @type {number}
   * @memberof AdminUpdateCampaignRequest
   */
  campaignId: number;
}
/**
 *
 * @export
 * @interface AdminUpdateCampaignResponse
 */
export interface AdminUpdateCampaignResponse {
  /**
   *
   * @type {CampaignInfo}
   * @memberof AdminUpdateCampaignResponse
   */
  campaign: CampaignInfo;
  /**
   *
   * @type {Array<string>}
   * @memberof AdminUpdateCampaignResponse
   */
  errorMessages: Array<string>;
}
/**
 *
 * @export
 * @interface AdminUpdateRewardRequest
 */
export interface AdminUpdateRewardRequest {
  /**
   *
   * @type {boolean}
   * @memberof AdminUpdateRewardRequest
   */
  published: boolean;
  /**
   *
   * @type {number}
   * @memberof AdminUpdateRewardRequest
   */
  tagId: number;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateRewardRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateRewardRequest
   */
  thumbnailSrc: string;
  /**
   *
   * @type {number}
   * @memberof AdminUpdateRewardRequest
   */
  requiredPoints: number;
  /**
   *
   * @type {number}
   * @memberof AdminUpdateRewardRequest
   */
  usageLimits: number | null;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateRewardRequest
   */
  expiredDatetime: string | null;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateRewardRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateRewardRequest
   */
  caution: string;
  /**
   *
   * @type {AdminRewardProviderRequest}
   * @memberof AdminUpdateRewardRequest
   */
  provider: AdminRewardProviderRequest;
  /**
   *
   * @type {Array<string>}
   * @memberof AdminUpdateRewardRequest
   */
  couponCodes: Array<string>;
  /**
   *
   * @type {number}
   * @memberof AdminUpdateRewardRequest
   */
  issueLimits: number | null;
  /**
   *
   * @type {Array<number>}
   * @memberof AdminUpdateRewardRequest
   */
  targetGymIds: Array<number>;
}
/**
 *
 * @export
 * @interface AdminUpdateRewardResponse
 */
export interface AdminUpdateRewardResponse {
  /**
   *
   * @type {AdminReward}
   * @memberof AdminUpdateRewardResponse
   */
  reward: AdminReward;
}
/**
 *
 * @export
 * @interface AdminUser
 */
export interface AdminUser {
  /**
   *
   * @type {number}
   * @memberof AdminUser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  gender: AdminUserGenderEnum;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  birthDay: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  postalCode1: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  postalCode2: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  prefecture: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  address: string;
  /**
   *
   * @type {Array<AdminUserPaymentWay>}
   * @memberof AdminUser
   */
  paymentWays: Array<AdminUserPaymentWay>;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  registerDatetime: string;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  status: AdminUserStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AdminUser
   */
  userSubscriptionStatus: AdminUserUserSubscriptionStatusEnum;
  /**
   *
   * @type {AdminUserPointSummary}
   * @memberof AdminUser
   */
  pointSummary: AdminUserPointSummary;
  /**
   *
   * @type {AdminUserSubscribe}
   * @memberof AdminUser
   */
  subscribe?: AdminUserSubscribe;
  /**
   *
   * @type {Array<AdminUserTempUse>}
   * @memberof AdminUser
   */
  temporaryUses?: Array<AdminUserTempUse>;
}

/**
 * @export
 * @enum {string}
 */
export enum AdminUserGenderEnum {
  Man = 'Man',
  Woman = 'Woman',
  Other = 'Other',
  No = 'No',
}
/**
 * @export
 * @enum {string}
 */
export enum AdminUserStatusEnum {
  VoluntaryDeleted = 'voluntaryDeleted',
  Banned = 'banned',
  Subscribe = 'subscribe',
  SubValid0TmpUseNull = 'subValid0TmpUseNull',
  SubValidNullTmpUseNull = 'subValidNullTmpUseNull',
  SubValidNullTmpUseThisMonth = 'subValidNullTmpUseThisMonth',
  SubValid0TmpUseThisMonth = 'subValid0TmpUseThisMonth',
  SubValidNullTmpUseNotThisMonth = 'subValidNullTmpUseNotThisMonth',
  SubValid0TmpUseNotThisMonth = 'subValid0TmpUseNotThisMonth',
}
/**
 * @export
 * @enum {string}
 */
export enum AdminUserUserSubscriptionStatusEnum {
  NeverContracted = 'NeverContracted',
  Active = 'Active',
  Paused = 'Paused',
  CancellationRequested = 'CancellationRequested',
  Cancelled = 'Cancelled',
}

/**
 *
 * @export
 * @interface AdminUserAddTempUseTicketRequest
 */
export interface AdminUserAddTempUseTicketRequest {
  /**
   *
   * @type {number}
   * @memberof AdminUserAddTempUseTicketRequest
   */
  userId: number;
  /**
   *
   * @type {number}
   * @memberof AdminUserAddTempUseTicketRequest
   */
  gymId: number;
}
/**
 *
 * @export
 * @interface AdminUserAddTempUseTicketResponse
 */
export interface AdminUserAddTempUseTicketResponse {
  /**
   *
   * @type {boolean}
   * @memberof AdminUserAddTempUseTicketResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminUserAddTempUseTicketResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface AdminUserCancelSubscribe
 */
export interface AdminUserCancelSubscribe {
  /**
   *
   * @type {number}
   * @memberof AdminUserCancelSubscribe
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminUserCancelSubscribe
   */
  startDatetime: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserCancelSubscribe
   */
  expiredDatetime: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminUserCancelSubscribe
   */
  canceled: boolean;
  /**
   *
   * @type {AdminUserSubscribePlan}
   * @memberof AdminUserCancelSubscribe
   */
  plan: AdminUserSubscribePlan;
}
/**
 *
 * @export
 * @interface AdminUserDeleteTempUseTicketRequest
 */
export interface AdminUserDeleteTempUseTicketRequest {
  /**
   *
   * @type {number}
   * @memberof AdminUserDeleteTempUseTicketRequest
   */
  temporaryUseId: number;
}
/**
 *
 * @export
 * @interface AdminUserDeleteTempUseTicketResponse
 */
export interface AdminUserDeleteTempUseTicketResponse {
  /**
   *
   * @type {boolean}
   * @memberof AdminUserDeleteTempUseTicketResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminUserDeleteTempUseTicketResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface AdminUserGym
 */
export interface AdminUserGym {
  /**
   *
   * @type {number}
   * @memberof AdminUserGym
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminUserGym
   */
  name: string;
}
/**
 *
 * @export
 * @interface AdminUserListContent
 */
export interface AdminUserListContent {
  /**
   *
   * @type {number}
   * @memberof AdminUserListContent
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminUserListContent
   */
  name: string;
}
/**
 *
 * @export
 * @interface AdminUserPaymentWay
 */
export interface AdminUserPaymentWay {
  /**
   *
   * @type {number}
   * @memberof AdminUserPaymentWay
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof AdminUserPaymentWay
   */
  isDefault: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminUserPaymentWay
   */
  way: AdminUserPaymentWayWayEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AdminUserPaymentWayWayEnum {
  Paidy = 'Paidy',
  Payjp = 'Payjp',
}

/**
 *
 * @export
 * @interface AdminUserPointSummary
 */
export interface AdminUserPointSummary {
  /**
   *
   * @type {number}
   * @memberof AdminUserPointSummary
   */
  currentPoints: number;
  /**
   *
   * @type {number}
   * @memberof AdminUserPointSummary
   */
  totalPoints: number;
}
/**
 *
 * @export
 * @interface AdminUserSubscribe
 */
export interface AdminUserSubscribe {
  /**
   *
   * @type {number}
   * @memberof AdminUserSubscribe
   */
  id: number;
  /**
   *
   * @type {AdminUserSubscribePlan}
   * @memberof AdminUserSubscribe
   */
  plan: AdminUserSubscribePlan;
  /**
   *
   * @type {string}
   * @memberof AdminUserSubscribe
   */
  cancelledDatetime: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserSubscribe
   */
  startDatetime: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserSubscribe
   */
  expiredDatetime: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserSubscribe
   */
  renewalDatetime: string;
  /**
   *
   * @type {AdminUserGym}
   * @memberof AdminUserSubscribe
   */
  gym: AdminUserGym;
  /**
   *
   * @type {AdminNextUserSubscribe}
   * @memberof AdminUserSubscribe
   */
  next: AdminNextUserSubscribe;
}
/**
 *
 * @export
 * @interface AdminUserSubscribePlan
 */
export interface AdminUserSubscribePlan {
  /**
   *
   * @type {number}
   * @memberof AdminUserSubscribePlan
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AdminUserSubscribePlan
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminUserSubscribePlan
   */
  type: AdminUserSubscribePlanTypeEnum;
  /**
   *
   * @type {number}
   * @memberof AdminUserSubscribePlan
   */
  price: number;
}

/**
 * @export
 * @enum {string}
 */
export enum AdminUserSubscribePlanTypeEnum {
  Standard = 'Standard',
  Organization = 'Organization',
}

/**
 *
 * @export
 * @interface AdminUserTempUse
 */
export interface AdminUserTempUse {
  /**
   *
   * @type {number}
   * @memberof AdminUserTempUse
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof AdminUserTempUse
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof AdminUserTempUse
   */
  registerDatetime: string;
  /**
   *
   * @type {AdminUserGym}
   * @memberof AdminUserTempUse
   */
  gym: AdminUserGym;
}
/**
 *
 * @export
 * @interface AdminUserUpdatePhoneNumberRequest
 */
export interface AdminUserUpdatePhoneNumberRequest {
  /**
   *
   * @type {string}
   * @memberof AdminUserUpdatePhoneNumberRequest
   */
  phoneNumber: string;
}
/**
 *
 * @export
 * @interface AdminUserUpdatePhoneNumberResponse
 */
export interface AdminUserUpdatePhoneNumberResponse {
  /**
   *
   * @type {UserInfo}
   * @memberof AdminUserUpdatePhoneNumberResponse
   */
  user: UserInfo;
}
/**
 *
 * @export
 * @interface AllCoupons
 */
export interface AllCoupons {
  /**
   *
   * @type {Array<Coupon>}
   * @memberof AllCoupons
   */
  availableCoupons: Array<Coupon>;
  /**
   *
   * @type {Array<Coupon>}
   * @memberof AllCoupons
   */
  unavailableCoupons: Array<Coupon>;
}
/**
 *
 * @export
 * @interface AppContent
 */
export interface AppContent {
  /**
   *
   * @type {number}
   * @memberof AppContent
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AppContent
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof AppContent
   */
  iconUrl: string;
  /**
   *
   * @type {string}
   * @memberof AppContent
   */
  transitionUrl: string;
  /**
   *
   * @type {boolean}
   * @memberof AppContent
   */
  isRequiredAuth: boolean;
  /**
   *
   * @type {string}
   * @memberof AppContent
   */
  tag: string;
}
/**
 *
 * @export
 * @interface AppContentSection
 */
export interface AppContentSection {
  /**
   *
   * @type {number}
   * @memberof AppContentSection
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AppContentSection
   */
  title: string;
  /**
   *
   * @type {Array<AppContent>}
   * @memberof AppContentSection
   */
  appContents: Array<AppContent>;
}
/**
 *
 * @export
 * @interface AppliedCoupon
 */
export interface AppliedCoupon {
  /**
   *
   * @type {number}
   * @memberof AppliedCoupon
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AppliedCoupon
   */
  campaignName: string;
  /**
   *
   * @type {string}
   * @memberof AppliedCoupon
   */
  discountLabel: string;
  /**
   *
   * @type {string}
   * @memberof AppliedCoupon
   */
  annotation: string;
}
/**
 *
 * @export
 * @interface Brand
 */
export interface Brand {
  /**
   *
   * @type {number}
   * @memberof Brand
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Brand
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Brand
   */
  iconUrl: string;
  /**
   *
   * @type {string}
   * @memberof Brand
   */
  ticketIconUrl: string;
  /**
   *
   * @type {string}
   * @memberof Brand
   */
  pictureIconUrl: string;
}
/**
 *
 * @export
 * @interface BrandInfo
 */
export interface BrandInfo {
  /**
   *
   * @type {number}
   * @memberof BrandInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BrandInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BrandInfo
   */
  iconUrl: string;
  /**
   *
   * @type {string}
   * @memberof BrandInfo
   */
  ticketIconUrl: string;
  /**
   *
   * @type {string}
   * @memberof BrandInfo
   */
  pictureIconUrl: string;
}
/**
 *
 * @export
 * @interface Campaign
 */
export interface Campaign {
  /**
   *
   * @type {number}
   * @memberof Campaign
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  type: CampaignTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  targetType: CampaignTargetTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  usageRestriction: CampaignUsageRestrictionEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof Campaign
   */
  targetGymIds: Array<string>;
  /**
   *
   * @type {number}
   * @memberof Campaign
   */
  validNumOfUses: number;
  /**
   *
   * @type {number}
   * @memberof Campaign
   */
  minimumSubscriptionRenewalCount: number;
  /**
   *
   * @type {number}
   * @memberof Campaign
   */
  discountRate: number | null;
  /**
   *
   * @type {number}
   * @memberof Campaign
   */
  discountAmount: number | null;
  /**
   *
   * @type {number}
   * @memberof Campaign
   */
  expirationDays: number | null;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  expiresDatetime: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Campaign
   */
  onlySpecificGymsRestriction: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof Campaign
   */
  campaignCodes: Array<string>;
  /**
   *
   * @type {Array<Gym>}
   * @memberof Campaign
   */
  targetGyms: Array<Gym>;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  registerDatetime: string;
  /**
   *
   * @type {Array<SubscribePlan>}
   * @memberof Campaign
   */
  subscribePlans: Array<SubscribePlan>;
}

/**
 * @export
 * @enum {string}
 */
export enum CampaignTypeEnum {
  Free = 'Free',
  Referral = 'Referral',
  FriendInvitation = 'FriendInvitation',
}
/**
 * @export
 * @enum {string}
 */
export enum CampaignTargetTypeEnum {
  Subscribe = 'Subscribe',
  TemporaryUse = 'TemporaryUse',
}
/**
 * @export
 * @enum {string}
 */
export enum CampaignUsageRestrictionEnum {
  None = 'None',
  CompletelyFirstTime = 'CompletelyFirstTime',
  SubscribeFirstTime = 'SubscribeFirstTime',
  SubscribeUpdateTime = 'SubscribeUpdateTime',
}

/**
 *
 * @export
 * @interface CampaignInfo
 */
export interface CampaignInfo {
  /**
   *
   * @type {number}
   * @memberof CampaignInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CampaignInfo
   */
  type: CampaignInfoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CampaignInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CampaignInfo
   */
  targetType: CampaignInfoTargetTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CampaignInfo
   */
  usageRestriction: CampaignInfoUsageRestrictionEnum;
  /**
   *
   * @type {CampaignInfoTargetBrand}
   * @memberof CampaignInfo
   */
  targetBrand: CampaignInfoTargetBrand;
  /**
   *
   * @type {Array<object> | string}
   * @memberof CampaignInfo
   */
  availableGyms: Array<object> | string;
  /**
   *
   * @type {number}
   * @memberof CampaignInfo
   */
  validNumOfUses: number;
  /**
   *
   * @type {object}
   * @memberof CampaignInfo
   */
  discount: object;
  /**
   *
   * @type {object}
   * @memberof CampaignInfo
   */
  expiration: object;
  /**
   *
   * @type {Array<string>}
   * @memberof CampaignInfo
   */
  campaignCodes: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CampaignInfo
   */
  registerDatetime: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CampaignInfoTypeEnum {
  Free = 'Free',
  Referral = 'Referral',
  FriendInvitation = 'FriendInvitation',
}
/**
 * @export
 * @enum {string}
 */
export enum CampaignInfoTargetTypeEnum {
  Subscribe = 'Subscribe',
  TemporaryUse = 'TemporaryUse',
}
/**
 * @export
 * @enum {string}
 */
export enum CampaignInfoUsageRestrictionEnum {
  None = 'None',
  CompletelyFirstTime = 'CompletelyFirstTime',
  SubscribeFirstTime = 'SubscribeFirstTime',
  SubscribeUpdateTime = 'SubscribeUpdateTime',
}

/**
 *
 * @export
 * @interface CampaignInfoTargetBrand
 */
export interface CampaignInfoTargetBrand {
  /**
   *
   * @type {number}
   * @memberof CampaignInfoTargetBrand
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CampaignInfoTargetBrand
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CampaignInfoTargetBrand
   */
  iconUrl?: string;
}
/**
 *
 * @export
 * @interface CampaignListItem
 */
export interface CampaignListItem {
  /**
   *
   * @type {number}
   * @memberof CampaignListItem
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CampaignListItem
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CampaignListItem
   */
  registerDatetime: string;
}
/**
 *
 * @export
 * @interface ChannelTalkBatchRequest
 */
export interface ChannelTalkBatchRequest {
  /**
   *
   * @type {number}
   * @memberof ChannelTalkBatchRequest
   */
  startUserId?: number;
  /**
   *
   * @type {number}
   * @memberof ChannelTalkBatchRequest
   */
  endUserId?: number;
}
/**
 *
 * @export
 * @interface ChannelTalkBatchResponse
 */
export interface ChannelTalkBatchResponse {
  /**
   *
   * @type {boolean}
   * @memberof ChannelTalkBatchResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface ChannelTalkConfig
 */
export interface ChannelTalkConfig {
  /**
   *
   * @type {string}
   * @memberof ChannelTalkConfig
   */
  memberId: string;
  /**
   *
   * @type {string}
   * @memberof ChannelTalkConfig
   */
  memberHash: string;
}
/**
 *
 * @export
 * @interface ChargeLogWithUserInfo
 */
export interface ChargeLogWithUserInfo {
  /**
   *
   * @type {number}
   * @memberof ChargeLogWithUserInfo
   */
  chargeId: number;
  /**
   *
   * @type {number}
   * @memberof ChargeLogWithUserInfo
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof ChargeLogWithUserInfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ChargeLogWithUserInfo
   */
  lastName: string;
  /**
   *
   * @type {number}
   * @memberof ChargeLogWithUserInfo
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof ChargeLogWithUserInfo
   */
  gymName: string;
  /**
   *
   * @type {string}
   * @memberof ChargeLogWithUserInfo
   */
  planName: string;
  /**
   *
   * @type {string}
   * @memberof ChargeLogWithUserInfo
   */
  iconUrl: string;
  /**
   *
   * @type {string}
   * @memberof ChargeLogWithUserInfo
   */
  purchaseDatetime: string;
  /**
   *
   * @type {string}
   * @memberof ChargeLogWithUserInfo
   */
  categoryOfPlan: ChargeLogWithUserInfoCategoryOfPlanEnum;
  /**
   *
   * @type {string}
   * @memberof ChargeLogWithUserInfo
   */
  paymentWay?: ChargeLogWithUserInfoPaymentWayEnum;
  /**
   *
   * @type {string}
   * @memberof ChargeLogWithUserInfo
   */
  paymentWayDescription: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ChargeLogWithUserInfoCategoryOfPlanEnum {
  Subscribe = 'subscribe',
  TemporaryUse = 'temporaryUse',
}
/**
 * @export
 * @enum {string}
 */
export enum ChargeLogWithUserInfoPaymentWayEnum {
  Payjp = 'Payjp',
  Paidy = 'Paidy',
  Paypay = 'Paypay',
}

/**
 *
 * @export
 * @interface CongestionTimelineByHour
 */
export interface CongestionTimelineByHour {
  /**
   *
   * @type {number}
   * @memberof CongestionTimelineByHour
   */
  hour: number;
  /**
   *
   * @type {string}
   * @memberof CongestionTimelineByHour
   */
  congestionLevel: CongestionTimelineByHourCongestionLevelEnum;
  /**
   *
   * @type {number}
   * @memberof CongestionTimelineByHour
   */
  congestionPercentage: number;
}

/**
 * @export
 * @enum {string}
 */
export enum CongestionTimelineByHourCongestionLevelEnum {
  Low = 'low',
  Middle = 'middle',
  High = 'high',
}

/**
 *
 * @export
 * @interface Coupon
 */
export interface Coupon {
  /**
   *
   * @type {number}
   * @memberof Coupon
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  discountLabel: string;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  campaignName: string;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  expiredDatetime: string;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  usageText: string;
  /**
   *
   * @type {boolean}
   * @memberof Coupon
   */
  onlySpecificGymsRestriction: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof Coupon
   */
  availableGymNames: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Coupon
   */
  targetTypes: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  annotation: string;
}
/**
 *
 * @export
 * @interface CouponBatchResponse
 */
export interface CouponBatchResponse {
  /**
   *
   * @type {boolean}
   * @memberof CouponBatchResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface CouponInfo
 */
export interface CouponInfo {
  /**
   *
   * @type {number}
   * @memberof CouponInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CouponInfo
   */
  couponTitle: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CouponInfo
   */
  discountTarget: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CouponInfo
   */
  stores: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CouponInfo
   */
  discountUnit: CouponInfoDiscountUnitEnum;
  /**
   *
   * @type {number}
   * @memberof CouponInfo
   */
  discountPrice: number;
  /**
   *
   * @type {string}
   * @memberof CouponInfo
   */
  expiresDatetime: string;
  /**
   *
   * @type {number}
   * @memberof CouponInfo
   */
  validNumOfUses: number;
  /**
   *
   * @type {number}
   * @memberof CouponInfo
   */
  usedCount: number;
  /**
   *
   * @type {number}
   * @memberof CouponInfo
   */
  minimumSubscriptionRenewalCount: number;
  /**
   *
   * @type {string}
   * @memberof CouponInfo
   */
  annotation: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CouponInfo
   */
  disabled: boolean;
  /**
   *
   * @type {string}
   * @memberof CouponInfo
   */
  discountLabel: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CouponInfo
   */
  targetTypes: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum CouponInfoDiscountUnitEnum {
  Yen = 'yen',
  Percent = 'percent',
}

/**
 *
 * @export
 * @interface CreateGymAnnouncementRequest
 */
export interface CreateGymAnnouncementRequest {
  /**
   *
   * @type {string}
   * @memberof CreateGymAnnouncementRequest
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CreateGymAnnouncementRequest
   */
  status: CreateGymAnnouncementRequestStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateGymAnnouncementRequestStatusEnum {
  Publish = 'Publish',
  Archive = 'Archive',
}

/**
 *
 * @export
 * @interface CreateGymAnnouncementResponse
 */
export interface CreateGymAnnouncementResponse {
  /**
   *
   * @type {GymAnnouncement}
   * @memberof CreateGymAnnouncementResponse
   */
  announcement: GymAnnouncement;
}
/**
 *
 * @export
 * @interface CreateGymRequest
 */
export interface CreateGymRequest {
  /**
   *
   * @type {string}
   * @memberof CreateGymRequest
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof CreateGymRequest
   */
  partnerId: number;
  /**
   *
   * @type {number}
   * @memberof CreateGymRequest
   */
  brandId: number;
  /**
   *
   * @type {string}
   * @memberof CreateGymRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof CreateGymRequest
   */
  assertionMessage: string;
  /**
   *
   * @type {string}
   * @memberof CreateGymRequest
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof CreateGymRequest
   */
  attention: string;
  /**
   *
   * @type {Array<GymDetailInfoUrls>}
   * @memberof CreateGymRequest
   */
  urls: Array<GymDetailInfoUrls>;
  /**
   *
   * @type {number}
   * @memberof CreateGymRequest
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof CreateGymRequest
   */
  latitude: number;
  /**
   *
   * @type {HQAdminOpenHour}
   * @memberof CreateGymRequest
   */
  openHour: HQAdminOpenHour;
  /**
   *
   * @type {string}
   * @memberof CreateGymRequest
   */
  openDate: string;
  /**
   *
   * @type {number}
   * @memberof CreateGymRequest
   */
  basicPlanId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateGymRequest
   */
  planIds: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateGymRequest
   */
  equipmentIds: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CreateGymRequest
   */
  temporaryUsePrice: number;
  /**
   *
   * @type {number}
   * @memberof CreateGymRequest
   */
  permissionId: number;
  /**
   *
   * @type {number}
   * @memberof CreateGymRequest
   */
  customerCapacity: number;
}
/**
 *
 * @export
 * @interface CreateGymResponse
 */
export interface CreateGymResponse {
  /**
   *
   * @type {HQAdminGym}
   * @memberof CreateGymResponse
   */
  gym: HQAdminGym;
}
/**
 *
 * @export
 * @interface CreateNewsRequest
 */
export interface CreateNewsRequest {
  /**
   *
   * @type {string}
   * @memberof CreateNewsRequest
   */
  type: CreateNewsRequestTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CreateNewsRequest
   */
  typeId: number;
  /**
   *
   * @type {string}
   * @memberof CreateNewsRequest
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CreateNewsRequest
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CreateNewsRequest
   */
  sender?: string;
  /**
   *
   * @type {string}
   * @memberof CreateNewsRequest
   */
  transitionPath?: CreateNewsRequestTransitionPathEnum;
  /**
   *
   * @type {string}
   * @memberof CreateNewsRequest
   */
  pictureUrl?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateNewsRequest
   */
  enablePushNotification?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateNewsRequestTypeEnum {
  Admin = 'Admin',
  Brand = 'Brand',
  Gym = 'Gym',
}
/**
 * @export
 * @enum {string}
 */
export enum CreateNewsRequestTransitionPathEnum {
  Home = 'home',
  CouponList = 'coupon_list',
  PaymentMethod = 'payment_method',
  Contruct = 'contruct',
  EntranceHistory = 'entrance_history',
}

/**
 *
 * @export
 * @interface CreateNewsResponse
 */
export interface CreateNewsResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreateNewsResponse
   */
  result: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateNewsResponse
   */
  errorMessages: Array<string>;
  /**
   *
   * @type {CreateNewsResponsePushNotification}
   * @memberof CreateNewsResponse
   */
  pushNotification?: CreateNewsResponsePushNotification;
}
/**
 *
 * @export
 * @interface CreateNewsResponsePushNotification
 */
export interface CreateNewsResponsePushNotification {
  /**
   *
   * @type {boolean}
   * @memberof CreateNewsResponsePushNotification
   */
  result?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateNewsResponsePushNotification
   */
  message?: string;
}
/**
 *
 * @export
 * @interface CreatePaidyRequest
 */
export interface CreatePaidyRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePaidyRequest
   */
  paidySubscribeToken: string;
}
/**
 *
 * @export
 * @interface CreatePaidyResponse
 */
export interface CreatePaidyResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreatePaidyResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof CreatePaidyResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface CreatePartnerRequest
 */
export interface CreatePartnerRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePartnerRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreatePartnerRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreatePartnerRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface CreatePartnerResponse
 */
export interface CreatePartnerResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreatePartnerResponse
   */
  result: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CreatePartnerResponse
   */
  errorMessages: Array<string>;
}
/**
 *
 * @export
 * @interface CreatePayjpRequest
 */
export interface CreatePayjpRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePayjpRequest
   */
  creditCardToken: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayjpRequest
   */
  last4: string;
  /**
   *
   * @type {string}
   * @memberof CreatePayjpRequest
   */
  brand: string;
}
/**
 *
 * @export
 * @interface CreatePayjpResponse
 */
export interface CreatePayjpResponse {
  /**
   *
   * @type {boolean}
   * @memberof CreatePayjpResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof CreatePayjpResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface CreatePlanRequest
 */
export interface CreatePlanRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePlanRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreatePlanRequest
   */
  subtitle: string;
  /**
   *
   * @type {string}
   * @memberof CreatePlanRequest
   */
  explain?: string;
  /**
   *
   * @type {number}
   * @memberof CreatePlanRequest
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof CreatePlanRequest
   */
  planType: CreatePlanRequestPlanTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePlanRequest
   */
  availabilityType: CreatePlanRequestAvailabilityTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CreatePlanRequest
   */
  term: number;
  /**
   *
   * @type {string}
   * @memberof CreatePlanRequest
   */
  termUnit: CreatePlanRequestTermUnitEnum;
  /**
   *
   * @type {boolean}
   * @memberof CreatePlanRequest
   */
  useFirstTimePlan: boolean;
  /**
   *
   * @type {number}
   * @memberof CreatePlanRequest
   */
  firstTimePrice?: number;
  /**
   *
   * @type {number}
   * @memberof CreatePlanRequest
   */
  firstTimeTerm?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum CreatePlanRequestPlanTypeEnum {
  Standard = 'Standard',
  Organization = 'Organization',
}
/**
 * @export
 * @enum {string}
 */
export enum CreatePlanRequestAvailabilityTypeEnum {
  All = 'All',
  Specific = 'Specific',
}
/**
 * @export
 * @enum {string}
 */
export enum CreatePlanRequestTermUnitEnum {
  Day = 'Day',
  Month = 'Month',
}

/**
 *
 * @export
 * @interface CreatePlanResponse
 */
export interface CreatePlanResponse {
  /**
   *
   * @type {SubscribePlan}
   * @memberof CreatePlanResponse
   */
  subscribePlan: SubscribePlan;
}
/**
 *
 * @export
 * @interface CreatePresignedUrlRequest
 */
export interface CreatePresignedUrlRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePresignedUrlRequest
   */
  fileExtension: string;
}
/**
 *
 * @export
 * @interface CreatePresignedUrlResponse
 */
export interface CreatePresignedUrlResponse {
  /**
   *
   * @type {string}
   * @memberof CreatePresignedUrlResponse
   */
  presignedUrl: string;
  /**
   *
   * @type {string}
   * @memberof CreatePresignedUrlResponse
   */
  src: string;
}
/**
 *
 * @export
 * @interface CreateSmsVerificationRequest
 */
export interface CreateSmsVerificationRequest {
  /**
   *
   * @type {string}
   * @memberof CreateSmsVerificationRequest
   */
  phoneNumber: string;
}
/**
 *
 * @export
 * @interface CreateSmsVerificationResponse
 */
export interface CreateSmsVerificationResponse {
  /**
   *
   * @type {SmsVerification}
   * @memberof CreateSmsVerificationResponse
   */
  smsVerification: SmsVerification;
}
/**
 *
 * @export
 * @interface CreateSurveyQuestionResponseInput
 */
export interface CreateSurveyQuestionResponseInput {
  /**
   *
   * @type {number}
   * @memberof CreateSurveyQuestionResponseInput
   */
  surveyQuestionId: number;
  /**
   *
   * @type {number}
   * @memberof CreateSurveyQuestionResponseInput
   */
  surveyQuestionOptionId: number | null;
  /**
   *
   * @type {string}
   * @memberof CreateSurveyQuestionResponseInput
   */
  text: string | null;
}
/**
 *
 * @export
 * @interface CreateSurveyQuestionResponsesRequest
 */
export interface CreateSurveyQuestionResponsesRequest {
  /**
   *
   * @type {Array<CreateSurveyQuestionResponseInput>}
   * @memberof CreateSurveyQuestionResponsesRequest
   */
  surveyQuestionResponses: Array<CreateSurveyQuestionResponseInput>;
}
/**
 *
 * @export
 * @interface CreateSurveyQuestionResponsesResponse
 */
export interface CreateSurveyQuestionResponsesResponse {
  /**
   *
   * @type {Array<SurveyQuestionResponse>}
   * @memberof CreateSurveyQuestionResponsesResponse
   */
  surveyQuestionResponses: Array<SurveyQuestionResponse>;
}
/**
 *
 * @export
 * @interface CreatedUser
 */
export interface CreatedUser {
  /**
   *
   * @type {number}
   * @memberof CreatedUser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  birthDay: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  gender: CreatedUserGenderEnum;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  postalCode1: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  postalCode2: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  prefecture: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  building: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  registerDatetime: string;
  /**
   *
   * @type {string}
   * @memberof CreatedUser
   */
  updateDatetime: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CreatedUserGenderEnum {
  Man = 'Man',
  Woman = 'Woman',
  Other = 'Other',
  No = 'No',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum DayOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

/**
 *
 * @export
 * @interface DeletePaidyResponse
 */
export interface DeletePaidyResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeletePaidyResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof DeletePaidyResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface DeletePartnerRequest
 */
export interface DeletePartnerRequest {
  /**
   *
   * @type {number}
   * @memberof DeletePartnerRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface DeletePartnerResponse
 */
export interface DeletePartnerResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeletePartnerResponse
   */
  result: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof DeletePartnerResponse
   */
  errorMessages: Array<string>;
}
/**
 *
 * @export
 * @interface DeletePayjpResponse
 */
export interface DeletePayjpResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeletePayjpResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof DeletePayjpResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface EditPartnerRequest
 */
export interface EditPartnerRequest {
  /**
   *
   * @type {number}
   * @memberof EditPartnerRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof EditPartnerRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EditPartnerRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof EditPartnerRequest
   */
  password?: string;
}
/**
 *
 * @export
 * @interface EditPartnerResponse
 */
export interface EditPartnerResponse {
  /**
   *
   * @type {boolean}
   * @memberof EditPartnerResponse
   */
  result: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof EditPartnerResponse
   */
  errorMessages: Array<string>;
}
/**
 *
 * @export
 * @interface EquipmentInfo
 */
export interface EquipmentInfo {
  /**
   *
   * @type {number}
   * @memberof EquipmentInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof EquipmentInfo
   */
  iconName: string;
  /**
   *
   * @type {string}
   * @memberof EquipmentInfo
   */
  name: string;
}
/**
 *
 * @export
 * @interface FindNewsRequest
 */
export interface FindNewsRequest {
  /**
   *
   * @type {number}
   * @memberof FindNewsRequest
   */
  newsId: number;
}
/**
 *
 * @export
 * @interface FindNewsResponse
 */
export interface FindNewsResponse {
  /**
   *
   * @type {Array<NewsInfo>}
   * @memberof FindNewsResponse
   */
  news: Array<NewsInfo>;
}
/**
 *
 * @export
 * @interface FriendInvitation
 */
export interface FriendInvitation {
  /**
   *
   * @type {string}
   * @memberof FriendInvitation
   */
  couponCode: string;
  /**
   *
   * @type {string}
   * @memberof FriendInvitation
   */
  description: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FriendInvitation
   */
  conditionsTextList: Array<string>;
  /**
   *
   * @type {string}
   * @memberof FriendInvitation
   */
  shareText: string;
}
/**
 *
 * @export
 * @interface FromEntranceRequest
 */
export interface FromEntranceRequest {
  /**
   *
   * @type {string}
   * @memberof FromEntranceRequest
   */
  token: string;
  /**
   *
   * @type {number}
   * @memberof FromEntranceRequest
   */
  gateId: number;
}
/**
 *
 * @export
 * @interface FromEntranceResponse
 */
export interface FromEntranceResponse {
  /**
   *
   * @type {boolean}
   * @memberof FromEntranceResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface GateLogWithUserInfo
 */
export interface GateLogWithUserInfo {
  /**
   *
   * @type {string}
   * @memberof GateLogWithUserInfo
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof GateLogWithUserInfo
   */
  gateId: number;
  /**
   *
   * @type {string}
   * @memberof GateLogWithUserInfo
   */
  enterDatetime: string;
  /**
   *
   * @type {string}
   * @memberof GateLogWithUserInfo
   */
  updateDatetime: string;
  /**
   *
   * @type {number}
   * @memberof GateLogWithUserInfo
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof GateLogWithUserInfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof GateLogWithUserInfo
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof GateLogWithUserInfo
   */
  iconUrl: string;
  /**
   *
   * @type {string}
   * @memberof GateLogWithUserInfo
   */
  gymName: string;
  /**
   *
   * @type {string}
   * @memberof GateLogWithUserInfo
   */
  planName: string;
  /**
   *
   * @type {string}
   * @memberof GateLogWithUserInfo
   */
  categoryOfPlan: GateLogWithUserInfoCategoryOfPlanEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GateLogWithUserInfoCategoryOfPlanEnum {
  Subscribe = 'subscribe',
  TemporaryUse = 'temporaryUse',
}

/**
 *
 * @export
 * @interface GetActiveCouponsResponse
 */
export interface GetActiveCouponsResponse {
  /**
   *
   * @type {ActiveCouponsInfo}
   * @memberof GetActiveCouponsResponse
   */
  coupons: ActiveCouponsInfo;
}
/**
 *
 * @export
 * @interface GetAddressByPostalCodeRequest
 */
export interface GetAddressByPostalCodeRequest {
  /**
   *
   * @type {string}
   * @memberof GetAddressByPostalCodeRequest
   */
  postalCode1: string;
  /**
   *
   * @type {string}
   * @memberof GetAddressByPostalCodeRequest
   */
  postalCode2: string;
}
/**
 *
 * @export
 * @interface GetAddressByPostalCodeResponse
 */
export interface GetAddressByPostalCodeResponse {
  /**
   *
   * @type {string}
   * @memberof GetAddressByPostalCodeResponse
   */
  prefecture: string;
  /**
   *
   * @type {string}
   * @memberof GetAddressByPostalCodeResponse
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof GetAddressByPostalCodeResponse
   */
  address: string;
}
/**
 *
 * @export
 * @interface GetAdminResponse
 */
export interface GetAdminResponse {
  /**
   *
   * @type {object}
   * @memberof GetAdminResponse
   */
  admin: object;
}
/**
 *
 * @export
 * @interface GetAllBrandsResponse
 */
export interface GetAllBrandsResponse {
  /**
   *
   * @type {Array<HqAdminBrand>}
   * @memberof GetAllBrandsResponse
   */
  brands: Array<HqAdminBrand>;
}
/**
 *
 * @export
 * @interface GetAllEquipmentsResponse
 */
export interface GetAllEquipmentsResponse {
  /**
   *
   * @type {Array<EquipmentInfo>}
   * @memberof GetAllEquipmentsResponse
   */
  equipments: Array<EquipmentInfo>;
}
/**
 *
 * @export
 * @interface GetAllGymsResponse
 */
export interface GetAllGymsResponse {
  /**
   *
   * @type {Array<HQGymForList>}
   * @memberof GetAllGymsResponse
   */
  gyms: Array<HQGymForList>;
}
/**
 *
 * @export
 * @interface GetAllPartnersResponse
 */
export interface GetAllPartnersResponse {
  /**
   *
   * @type {Array<PartnerDisplayInfo>}
   * @memberof GetAllPartnersResponse
   */
  partners: Array<PartnerDisplayInfo>;
}
/**
 *
 * @export
 * @interface GetAllSubscribePlansResponse
 */
export interface GetAllSubscribePlansResponse {
  /**
   *
   * @type {Array<SubscribePlan>}
   * @memberof GetAllSubscribePlansResponse
   */
  subscribePlans: Array<SubscribePlan>;
}
/**
 *
 * @export
 * @interface GetCouponByCodeRequest
 */
export interface GetCouponByCodeRequest {
  /**
   *
   * @type {string}
   * @memberof GetCouponByCodeRequest
   */
  code: string;
}
/**
 *
 * @export
 * @interface GetCouponByCodeResponse
 */
export interface GetCouponByCodeResponse {
  /**
   *
   * @type {CouponInfo}
   * @memberof GetCouponByCodeResponse
   */
  coupon: CouponInfo;
}
/**
 *
 * @export
 * @interface GetCouponsResponse
 */
export interface GetCouponsResponse {
  /**
   *
   * @type {Array<CouponInfo>}
   * @memberof GetCouponsResponse
   */
  coupons: Array<CouponInfo>;
}
/**
 *
 * @export
 * @interface GetGymAnnouncementsResponse
 */
export interface GetGymAnnouncementsResponse {
  /**
   *
   * @type {Array<GymAnnouncement>}
   * @memberof GetGymAnnouncementsResponse
   */
  announcements: Array<GymAnnouncement>;
}
/**
 *
 * @export
 * @interface GetGymResponse
 */
export interface GetGymResponse {
  /**
   *
   * @type {HQAdminGym}
   * @memberof GetGymResponse
   */
  gym: HQAdminGym;
}
/**
 *
 * @export
 * @interface GetMessagesResponse
 */
export interface GetMessagesResponse {
  /**
   *
   * @type {Array<MessageText | MessageRadio>}
   * @memberof GetMessagesResponse
   */
  messages: Array<MessageText | MessageRadio>;
}
/**
 *
 * @export
 * @interface GetOptionPlansResponse
 */
export interface GetOptionPlansResponse {
  /**
   *
   * @type {Array<HqAdminOptionPlan>}
   * @memberof GetOptionPlansResponse
   */
  optionPlans: Array<HqAdminOptionPlan>;
}
/**
 *
 * @export
 * @interface GetPartnerInfoResponse
 */
export interface GetPartnerInfoResponse {
  /**
   *
   * @type {PartnerDisplayInfoPage}
   * @memberof GetPartnerInfoResponse
   */
  partner: PartnerDisplayInfoPage;
}
/**
 *
 * @export
 * @interface GetPaymentWaysResponse
 */
export interface GetPaymentWaysResponse {
  /**
   *
   * @type {Array<PaymentWay>}
   * @memberof GetPaymentWaysResponse
   */
  paymentWays: Array<PaymentWay>;
}
/**
 *
 * @export
 * @interface GetSalesSummariesResponse
 */
export interface GetSalesSummariesResponse {
  /**
   *
   * @type {Array<SalesSummary>}
   * @memberof GetSalesSummariesResponse
   */
  salesSummaries: Array<SalesSummary>;
  /**
   *
   * @type {number}
   * @memberof GetSalesSummariesResponse
   */
  totalCount: number;
}
/**
 *
 * @export
 * @interface GetShotPlansResponse
 */
export interface GetShotPlansResponse {
  /**
   *
   * @type {Array<ShotPlanDisplayInfo>}
   * @memberof GetShotPlansResponse
   */
  shotPlans: Array<ShotPlanDisplayInfo>;
}
/**
 *
 * @export
 * @interface GetStaffResponse
 */
export interface GetStaffResponse {
  /**
   *
   * @type {object}
   * @memberof GetStaffResponse
   */
  staff: object;
}
/**
 *
 * @export
 * @interface GetSubscribePlanResponse
 */
export interface GetSubscribePlanResponse {
  /**
   *
   * @type {SubscribePlan}
   * @memberof GetSubscribePlanResponse
   */
  subscribePlan: SubscribePlan;
}
/**
 *
 * @export
 * @interface GetSurveyResponse
 */
export interface GetSurveyResponse {
  /**
   *
   * @type {Survey}
   * @memberof GetSurveyResponse
   */
  survey: Survey;
}
/**
 *
 * @export
 * @interface GetUserMessageOfficerListResponse
 */
export interface GetUserMessageOfficerListResponse {
  /**
   *
   * @type {Array<OfficerInfo>}
   * @memberof GetUserMessageOfficerListResponse
   */
  officers: Array<OfficerInfo>;
}
/**
 *
 * @export
 * @interface GetUserResponse
 */
export interface GetUserResponse {
  /**
   *
   * @type {User}
   * @memberof GetUserResponse
   */
  user: User;
}
/**
 *
 * @export
 * @interface GetUserUpdatableSubscribePlansResponse
 */
export interface GetUserUpdatableSubscribePlansResponse {
  /**
   *
   * @type {Array<SubscribePlan>}
   * @memberof GetUserUpdatableSubscribePlansResponse
   */
  subscribePlans: Array<SubscribePlan>;
}
/**
 *
 * @export
 * @interface Gym
 */
export interface Gym {
  /**
   *
   * @type {number}
   * @memberof Gym
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Gym
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof Gym
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof Gym
   */
  customerCapacity: number;
  /**
   *
   * @type {Array<GymUrl>}
   * @memberof Gym
   */
  urls: Array<GymUrl>;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  openHour: string;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  tag: string;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  attention: string;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  assertionMessage: string;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  temporaryUseMessage: string;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  openDate: string;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  status: string;
  /**
   *
   * @type {number}
   * @memberof Gym
   */
  partnerId: number;
  /**
   *
   * @type {number}
   * @memberof Gym
   */
  brandId: number;
  /**
   *
   * @type {number}
   * @memberof Gym
   */
  basicPlanId: number;
  /**
   *
   * @type {number}
   * @memberof Gym
   */
  temporaryUsePrice: number;
  /**
   *
   * @type {number}
   * @memberof Gym
   */
  permissionId: number;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  messageNotifyEmail: string;
  /**
   *
   * @type {string}
   * @memberof Gym
   */
  messageNotifySlackChannelId: string;
  /**
   *
   * @type {number}
   * @memberof Gym
   */
  entranceStoreId: number;
  /**
   *
   * @type {Array<GymPhoto>}
   * @memberof Gym
   */
  photos: Array<GymPhoto>;
}
/**
 *
 * @export
 * @interface GymAnnouncement
 */
export interface GymAnnouncement {
  /**
   *
   * @type {number}
   * @memberof GymAnnouncement
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof GymAnnouncement
   */
  gymId: number;
  /**
   *
   * @type {string}
   * @memberof GymAnnouncement
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof GymAnnouncement
   */
  status: GymAnnouncementStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GymAnnouncement
   */
  registerDatetime: string;
  /**
   *
   * @type {string}
   * @memberof GymAnnouncement
   */
  updateDatetime: string;
}

/**
 * @export
 * @enum {string}
 */
export enum GymAnnouncementStatusEnum {
  Publish = 'Publish',
  Archive = 'Archive',
}

/**
 *
 * @export
 * @interface GymCardInfo
 */
export interface GymCardInfo {
  /**
   *
   * @type {number}
   * @memberof GymCardInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GymCardInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GymCardInfo
   */
  location: string;
  /**
   *
   * @type {Array<GymDetailInfoEquipments>}
   * @memberof GymCardInfo
   */
  equipments: Array<GymDetailInfoEquipments>;
  /**
   *
   * @type {Array<string>}
   * @memberof GymCardInfo
   * @deprecated
   */
  pictures: Array<string>;
  /**
   *
   * @type {Array<GymPhoto>}
   * @memberof GymCardInfo
   */
  photos: Array<GymPhoto>;
  /**
   *
   * @type {number}
   * @memberof GymCardInfo
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof GymCardInfo
   */
  latitude: number;
  /**
   *
   * @type {string}
   * @memberof GymCardInfo
   */
  tag: string;
  /**
   *
   * @type {BrandInfo}
   * @memberof GymCardInfo
   */
  brand: BrandInfo;
  /**
   *
   * @type {SubscribePlanInfo}
   * @memberof GymCardInfo
   */
  basicPlan: SubscribePlanInfo;
  /**
   *
   * @type {number}
   * @memberof GymCardInfo
   */
  temporaryUsePrice: number;
  /**
   *
   * @type {string}
   * @memberof GymCardInfo
   */
  openHour: string;
  /**
   *
   * @type {string}
   * @memberof GymCardInfo
   */
  congestionLevel: GymCardInfoCongestionLevelEnum;
  /**
   *
   * @type {number}
   * @memberof GymCardInfo
   */
  congestionPercentage: number;
  /**
   *
   * @type {Array<GymDetailInfoAnnouncements>}
   * @memberof GymCardInfo
   */
  announcements?: Array<GymDetailInfoAnnouncements>;
  /**
   *
   * @type {string}
   * @memberof GymCardInfo
   */
  subscribePlanLowestPriceLabel: string;
}

/**
 * @export
 * @enum {string}
 */
export enum GymCardInfoCongestionLevelEnum {
  Low = 'low',
  Middle = 'middle',
  High = 'high',
}

/**
 *
 * @export
 * @interface GymCardInfoDeprecated
 */
export interface GymCardInfoDeprecated {
  /**
   *
   * @type {number}
   * @memberof GymCardInfoDeprecated
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GymCardInfoDeprecated
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GymCardInfoDeprecated
   */
  location: string;
  /**
   *
   * @type {Array<GymDetailInfoEquipments>}
   * @memberof GymCardInfoDeprecated
   */
  equipments: Array<GymDetailInfoEquipments>;
  /**
   *
   * @type {Array<string>}
   * @memberof GymCardInfoDeprecated
   * @deprecated
   */
  pictures: Array<string>;
  /**
   *
   * @type {Array<GymPhoto>}
   * @memberof GymCardInfoDeprecated
   */
  photos: Array<GymPhoto>;
  /**
   *
   * @type {number}
   * @memberof GymCardInfoDeprecated
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof GymCardInfoDeprecated
   */
  latitude: number;
  /**
   *
   * @type {string}
   * @memberof GymCardInfoDeprecated
   */
  tag: string;
  /**
   *
   * @type {BrandInfo}
   * @memberof GymCardInfoDeprecated
   */
  brand: BrandInfo;
  /**
   *
   * @type {SubscribePlanInfo}
   * @memberof GymCardInfoDeprecated
   */
  basicPlan: SubscribePlanInfo;
  /**
   *
   * @type {number}
   * @memberof GymCardInfoDeprecated
   */
  temporaryUsePrice: number;
  /**
   *
   * @type {string}
   * @memberof GymCardInfoDeprecated
   */
  openHour: string;
  /**
   *
   * @type {number}
   * @memberof GymCardInfoDeprecated
   */
  congestionPercentage: number;
  /**
   *
   * @type {Array<GymDetailInfoAnnouncements>}
   * @memberof GymCardInfoDeprecated
   */
  announcements?: Array<GymDetailInfoAnnouncements>;
}
/**
 *
 * @export
 * @interface GymDetailInfo
 */
export interface GymDetailInfo {
  /**
   *
   * @type {number}
   * @memberof GymDetailInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfo
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GymDetailInfo
   * @deprecated
   */
  pictures: Array<string>;
  /**
   *
   * @type {Array<GymPhoto>}
   * @memberof GymDetailInfo
   */
  photos: Array<GymPhoto>;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfo
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfo
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfo
   */
  attention: string;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfo
   */
  tag: string;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfo
   */
  url: string;
  /**
   *
   * @type {Array<GymDetailInfoUrls>}
   * @memberof GymDetailInfo
   */
  urls: Array<GymDetailInfoUrls>;
  /**
   *
   * @type {Array<GymDetailInfoEquipments>}
   * @memberof GymDetailInfo
   */
  equipments: Array<GymDetailInfoEquipments>;
  /**
   *
   * @type {number}
   * @memberof GymDetailInfo
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof GymDetailInfo
   */
  latitude: number;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfo
   */
  openHour: string;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfo
   */
  openDate: string;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfo
   */
  congestionLevel: GymDetailInfoCongestionLevelEnum;
  /**
   *
   * @type {number}
   * @memberof GymDetailInfo
   */
  congestionPercentage: number;
  /**
   *
   * @type {GymTemporaryUseDetail}
   * @memberof GymDetailInfo
   */
  temporaryUse: GymTemporaryUseDetail;
  /**
   *
   * @type {SubscribePlanInfo}
   * @memberof GymDetailInfo
   */
  basicPlan: SubscribePlanInfo;
  /**
   *
   * @type {Array<SubscribePlanInfo>}
   * @memberof GymDetailInfo
   */
  plans: Array<SubscribePlanInfo>;
  /**
   *
   * @type {Array<ShotPlanInfo>}
   * @memberof GymDetailInfo
   */
  shotPlans: Array<ShotPlanInfo>;
  /**
   *
   * @type {BrandInfo}
   * @memberof GymDetailInfo
   */
  brand: BrandInfo;
  /**
   *
   * @type {Array<GymDetailInfoAnnouncements>}
   * @memberof GymDetailInfo
   */
  announcements?: Array<GymDetailInfoAnnouncements>;
}

/**
 * @export
 * @enum {string}
 */
export enum GymDetailInfoCongestionLevelEnum {
  Low = 'low',
  Middle = 'middle',
  High = 'high',
}

/**
 *
 * @export
 * @interface GymDetailInfoAnnouncements
 */
export interface GymDetailInfoAnnouncements {
  /**
   *
   * @type {number}
   * @memberof GymDetailInfoAnnouncements
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfoAnnouncements
   */
  content?: string;
}
/**
 *
 * @export
 * @interface GymDetailInfoEquipments
 */
export interface GymDetailInfoEquipments {
  /**
   *
   * @type {number}
   * @memberof GymDetailInfoEquipments
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfoEquipments
   */
  iconName?: string;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfoEquipments
   */
  name?: string;
}
/**
 *
 * @export
 * @interface GymDetailInfoUrls
 */
export interface GymDetailInfoUrls {
  /**
   *
   * @type {string}
   * @memberof GymDetailInfoUrls
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof GymDetailInfoUrls
   */
  url: string;
}
/**
 *
 * @export
 * @interface GymInfo
 */
export interface GymInfo {
  /**
   *
   * @type {number}
   * @memberof GymInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GymInfo
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GymInfo
   * @deprecated
   */
  pictures: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GymInfo
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof GymInfo
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof GymInfo
   */
  url: string;
  /**
   *
   * @type {Array<GymDetailInfoEquipments>}
   * @memberof GymInfo
   */
  equipments: Array<GymDetailInfoEquipments>;
  /**
   *
   * @type {number}
   * @memberof GymInfo
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof GymInfo
   */
  latitude: number;
  /**
   *
   * @type {string}
   * @memberof GymInfo
   */
  openHour: string;
  /**
   *
   * @type {GymInfoTemporaryUse}
   * @memberof GymInfo
   */
  temporaryUse: GymInfoTemporaryUse;
  /**
   *
   * @type {GymInfoTemporaryUse}
   * @memberof GymInfo
   */
  subscribe: GymInfoTemporaryUse;
}
/**
 *
 * @export
 * @interface GymInfoTemporaryUse
 */
export interface GymInfoTemporaryUse {
  /**
   *
   * @type {boolean}
   * @memberof GymInfoTemporaryUse
   */
  usable?: boolean;
  /**
   *
   * @type {number}
   * @memberof GymInfoTemporaryUse
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof GymInfoTemporaryUse
   */
  priceInTax?: number;
}
/**
 *
 * @export
 * @interface GymPhoto
 */
export interface GymPhoto {
  /**
   *
   * @type {ImageSrc}
   * @memberof GymPhoto
   */
  src: ImageSrc;
  /**
   *
   * @type {string}
   * @memberof GymPhoto
   */
  caption: string;
}
/**
 *
 * @export
 * @interface GymStatusInfo
 */
export interface GymStatusInfo {
  /**
   *
   * @type {number}
   * @memberof GymStatusInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GymStatusInfo
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof GymStatusInfo
   */
  subscribeUserCount: number;
  /**
   *
   * @type {number}
   * @memberof GymStatusInfo
   */
  todaySubscribeUsedCount: number;
  /**
   *
   * @type {number}
   * @memberof GymStatusInfo
   */
  todayTemporaryUseUsedCount: number;
  /**
   *
   * @type {number}
   * @memberof GymStatusInfo
   */
  todaySubscribePurchaseCount: number;
  /**
   *
   * @type {number}
   * @memberof GymStatusInfo
   */
  todayTemporaryUsePurchaseCount: number;
  /**
   *
   * @type {number}
   * @memberof GymStatusInfo
   */
  yesterdaySubscribeExpiredCount: number;
}
/**
 *
 * @export
 * @interface GymTemporaryUseDetail
 */
export interface GymTemporaryUseDetail {
  /**
   *
   * @type {string}
   * @memberof GymTemporaryUseDetail
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GymTemporaryUseDetail
   */
  subtitle: string;
  /**
   *
   * @type {string}
   * @memberof GymTemporaryUseDetail
   */
  explain: string;
  /**
   *
   * @type {number}
   * @memberof GymTemporaryUseDetail
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof GymTemporaryUseDetail
   */
  nonTaxedPrice: number;
  /**
   *
   * @type {boolean}
   * @memberof GymTemporaryUseDetail
   */
  usable: boolean;
  /**
   *
   * @type {string}
   * @memberof GymTemporaryUseDetail
   */
  purchaseCaution: string;
  /**
   *
   * @type {string}
   * @memberof GymTemporaryUseDetail
   */
  purchaseTerms: string;
}
/**
 *
 * @export
 * @interface GymUrl
 */
export interface GymUrl {
  /**
   *
   * @type {string}
   * @memberof GymUrl
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof GymUrl
   */
  url: string;
}
/**
 *
 * @export
 * @interface HQAdminGym
 */
export interface HQAdminGym {
  /**
   *
   * @type {number}
   * @memberof HQAdminGym
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof HQAdminGym
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof HQAdminGym
   */
  status: HQAdminGymStatusEnum;
  /**
   *
   * @type {Array<HQAdminGymPhoto>}
   * @memberof HQAdminGym
   */
  photos: Array<HQAdminGymPhoto>;
  /**
   *
   * @type {Array<HQAdminGymUrl>}
   * @memberof HQAdminGym
   */
  urls: Array<HQAdminGymUrl>;
  /**
   *
   * @type {string}
   * @memberof HQAdminGym
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof HQAdminGym
   */
  assertionMessage: string;
  /**
   *
   * @type {string}
   * @memberof HQAdminGym
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof HQAdminGym
   */
  attention: string;
  /**
   *
   * @type {Array<HQAdminGymEquipment>}
   * @memberof HQAdminGym
   */
  equipments: Array<HQAdminGymEquipment>;
  /**
   *
   * @type {number}
   * @memberof HQAdminGym
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof HQAdminGym
   */
  latitude: number;
  /**
   *
   * @type {HQAdminOpenHourWithLabel}
   * @memberof HQAdminGym
   */
  openHour: HQAdminOpenHourWithLabel;
  /**
   *
   * @type {string}
   * @memberof HQAdminGym
   */
  openDate: string;
  /**
   *
   * @type {number}
   * @memberof HQAdminGym
   */
  temporaryUsePrice: number;
  /**
   *
   * @type {HqAdminGymSubscribePlan}
   * @memberof HQAdminGym
   */
  basicPlan: HqAdminGymSubscribePlan;
  /**
   *
   * @type {Array<HqAdminGymSubscribePlan>}
   * @memberof HQAdminGym
   */
  plans: Array<HqAdminGymSubscribePlan>;
  /**
   *
   * @type {HQAdminGymBrand}
   * @memberof HQAdminGym
   */
  brand: HQAdminGymBrand;
  /**
   *
   * @type {HQAdminGymPartner}
   * @memberof HQAdminGym
   */
  partner: HQAdminGymPartner;
  /**
   *
   * @type {HQAdminGymAnnouncement}
   * @memberof HQAdminGym
   */
  announcement: HQAdminGymAnnouncement;
  /**
   *
   * @type {number}
   * @memberof HQAdminGym
   */
  permissionId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof HQAdminGym
   */
  entranceGateIds: Array<number>;
  /**
   *
   * @type {number}
   * @memberof HQAdminGym
   */
  customerCapacity: number;
}

/**
 * @export
 * @enum {string}
 */
export enum HQAdminGymStatusEnum {
  Publish = 'publish',
  Unpublish = 'unpublish',
}

/**
 *
 * @export
 * @interface HQAdminGymAnnouncement
 */
export interface HQAdminGymAnnouncement {
  /**
   *
   * @type {number}
   * @memberof HQAdminGymAnnouncement
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof HQAdminGymAnnouncement
   */
  content: string;
}
/**
 *
 * @export
 * @interface HQAdminGymBrand
 */
export interface HQAdminGymBrand {
  /**
   *
   * @type {number}
   * @memberof HQAdminGymBrand
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof HQAdminGymBrand
   */
  name: string;
}
/**
 *
 * @export
 * @interface HQAdminGymEquipment
 */
export interface HQAdminGymEquipment {
  /**
   *
   * @type {number}
   * @memberof HQAdminGymEquipment
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof HQAdminGymEquipment
   */
  name: string;
}
/**
 *
 * @export
 * @interface HQAdminGymPartner
 */
export interface HQAdminGymPartner {
  /**
   *
   * @type {number}
   * @memberof HQAdminGymPartner
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof HQAdminGymPartner
   */
  name: string;
}
/**
 *
 * @export
 * @interface HQAdminGymPhoto
 */
export interface HQAdminGymPhoto {
  /**
   *
   * @type {ImageSrc}
   * @memberof HQAdminGymPhoto
   */
  src: ImageSrc;
  /**
   *
   * @type {string}
   * @memberof HQAdminGymPhoto
   */
  caption: string;
  /**
   *
   * @type {number}
   * @memberof HQAdminGymPhoto
   */
  priority: number;
}
/**
 *
 * @export
 * @interface HQAdminGymUrl
 */
export interface HQAdminGymUrl {
  /**
   *
   * @type {string}
   * @memberof HQAdminGymUrl
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof HQAdminGymUrl
   */
  url: string;
}
/**
 *
 * @export
 * @interface HQAdminOpenHour
 */
export interface HQAdminOpenHour {
  /**
   *
   * @type {string}
   * @memberof HQAdminOpenHour
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof HQAdminOpenHour
   */
  to: string;
}
/**
 *
 * @export
 * @interface HQAdminOpenHourWithLabel
 */
export interface HQAdminOpenHourWithLabel {
  /**
   *
   * @type {string}
   * @memberof HQAdminOpenHourWithLabel
   */
  label: string;
  /**
   *
   * @type {boolean}
   * @memberof HQAdminOpenHourWithLabel
   */
  isOpen24Hours: boolean;
  /**
   *
   * @type {HQAdminOpenHour}
   * @memberof HQAdminOpenHourWithLabel
   */
  value: HQAdminOpenHour;
}
/**
 *
 * @export
 * @interface HQGymForList
 */
export interface HQGymForList {
  /**
   *
   * @type {number}
   * @memberof HQGymForList
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof HQGymForList
   */
  name: string;
}
/**
 *
 * @export
 * @interface HqAdminBrand
 */
export interface HqAdminBrand {
  /**
   *
   * @type {number}
   * @memberof HqAdminBrand
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof HqAdminBrand
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof HqAdminBrand
   */
  iconUrl: string;
  /**
   *
   * @type {string}
   * @memberof HqAdminBrand
   */
  ticketIconUrl: string;
  /**
   *
   * @type {string}
   * @memberof HqAdminBrand
   */
  pictureIconUrl: string;
}
/**
 *
 * @export
 * @interface HqAdminGymSubscribePlan
 */
export interface HqAdminGymSubscribePlan {
  /**
   *
   * @type {number}
   * @memberof HqAdminGymSubscribePlan
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof HqAdminGymSubscribePlan
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof HqAdminGymSubscribePlan
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof HqAdminGymSubscribePlan
   */
  explain: string;
  /**
   *
   * @type {number}
   * @memberof HqAdminGymSubscribePlan
   */
  term: number;
  /**
   *
   * @type {string}
   * @memberof HqAdminGymSubscribePlan
   */
  termUnit: HqAdminGymSubscribePlanTermUnitEnum;
  /**
   *
   * @type {string}
   * @memberof HqAdminGymSubscribePlan
   */
  brandName: string;
  /**
   *
   * @type {boolean}
   * @memberof HqAdminGymSubscribePlan
   */
  isBasic: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum HqAdminGymSubscribePlanTermUnitEnum {
  Day = 'Day',
  Month = 'Month',
}

/**
 *
 * @export
 * @interface HqAdminOptionPlan
 */
export interface HqAdminOptionPlan {
  /**
   *
   * @type {number}
   * @memberof HqAdminOptionPlan
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof HqAdminOptionPlan
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof HqAdminOptionPlan
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof HqAdminOptionPlan
   */
  explain: string;
  /**
   *
   * @type {number}
   * @memberof HqAdminOptionPlan
   */
  term: number;
}
/**
 *
 * @export
 * @interface ImageSrc
 */
export interface ImageSrc {
  /**
   *
   * @type {string}
   * @memberof ImageSrc
   */
  small: string;
  /**
   *
   * @type {string}
   * @memberof ImageSrc
   */
  medium: string;
  /**
   *
   * @type {string}
   * @memberof ImageSrc
   */
  large: string;
}
/**
 *
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  refreshToken: string;
}
/**
 *
 * @export
 * @interface MessageAddress
 */
export interface MessageAddress {
  /**
   *
   * @type {number}
   * @memberof MessageAddress
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MessageAddress
   */
  type: MessageAddressTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MessageAddress
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MessageAddress
   */
  imageUrl: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MessageAddressTypeEnum {
  Gym = 'gym',
  User = 'user',
  Admin = 'admin',
}

/**
 *
 * @export
 * @interface MessageMeta
 */
export interface MessageMeta {
  /**
   *
   * @type {number}
   * @memberof MessageMeta
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MessageMeta
   */
  createDatetime: string;
  /**
   *
   * @type {string}
   * @memberof MessageMeta
   */
  updateDatetime: string;
  /**
   *
   * @type {MessageAddress}
   * @memberof MessageMeta
   */
  from: MessageAddress;
  /**
   *
   * @type {MessageAddress}
   * @memberof MessageMeta
   */
  to: MessageAddress;
  /**
   *
   * @type {boolean}
   * @memberof MessageMeta
   */
  read: boolean;
}
/**
 *
 * @export
 * @interface MessageRadio
 */
export interface MessageRadio {
  /**
   *
   * @type {string}
   * @memberof MessageRadio
   */
  type: MessageRadioTypeEnum;
  /**
   *
   * @type {MessageTextContent}
   * @memberof MessageRadio
   */
  content: MessageTextContent;
  /**
   *
   * @type {MessageMeta}
   * @memberof MessageRadio
   */
  meta: MessageMeta;
}

/**
 * @export
 * @enum {string}
 */
export enum MessageRadioTypeEnum {
  Radio = 'radio',
}

/**
 *
 * @export
 * @interface MessageText
 */
export interface MessageText {
  /**
   *
   * @type {string}
   * @memberof MessageText
   */
  type: MessageTextTypeEnum;
  /**
   *
   * @type {MessageTextContent}
   * @memberof MessageText
   */
  content: MessageTextContent;
  /**
   *
   * @type {MessageMeta}
   * @memberof MessageText
   */
  meta: MessageMeta;
}

/**
 * @export
 * @enum {string}
 */
export enum MessageTextTypeEnum {
  Text = 'text',
}

/**
 *
 * @export
 * @interface MessageTextContent
 */
export interface MessageTextContent {
  /**
   *
   * @type {string}
   * @memberof MessageTextContent
   */
  text?: string;
}
/**
 *
 * @export
 * @interface MySchedule
 */
export interface MySchedule {
  /**
   *
   * @type {number}
   * @memberof MySchedule
   */
  dayOfWeek: number;
  /**
   *
   * @type {string}
   * @memberof MySchedule
   */
  dayOfWeekLabel: string;
  /**
   *
   * @type {boolean}
   * @memberof MySchedule
   */
  enabled: boolean;
}
/**
 *
 * @export
 * @interface NativeChargeLogGetRequest
 */
export interface NativeChargeLogGetRequest {
  /**
   *
   * @type {number}
   * @memberof NativeChargeLogGetRequest
   */
  pageNumber?: number;
}
/**
 *
 * @export
 * @interface NativeChargeLogGetResponse
 */
export interface NativeChargeLogGetResponse {
  /**
   *
   * @type {number}
   * @memberof NativeChargeLogGetResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof NativeChargeLogGetResponse
   */
  numberOfPaymentLogs: number;
  /**
   *
   * @type {Array<ChargeLogWithUserInfo>}
   * @memberof NativeChargeLogGetResponse
   */
  paymentLogs: Array<ChargeLogWithUserInfo>;
}
/**
 *
 * @export
 * @interface NativeGateLogGetRequest
 */
export interface NativeGateLogGetRequest {
  /**
   *
   * @type {number}
   * @memberof NativeGateLogGetRequest
   */
  pageNumber?: number;
}
/**
 *
 * @export
 * @interface NativeGateLogGetResponse
 */
export interface NativeGateLogGetResponse {
  /**
   *
   * @type {number}
   * @memberof NativeGateLogGetResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof NativeGateLogGetResponse
   */
  numberOfGateLogs: number;
  /**
   *
   * @type {Array<GateLogWithUserInfo>}
   * @memberof NativeGateLogGetResponse
   */
  gateLogs: Array<GateLogWithUserInfo>;
}
/**
 *
 * @export
 * @interface NativeGateLogStatusGetResponse
 */
export interface NativeGateLogStatusGetResponse {
  /**
   *
   * @type {string}
   * @memberof NativeGateLogStatusGetResponse
   */
  nextSubscribeUpdateDatetime?: string;
  /**
   *
   * @type {number}
   * @memberof NativeGateLogStatusGetResponse
   */
  thisMonthGoingGymCount?: number;
}
/**
 *
 * @export
 * @interface NearGymRequest
 */
export interface NearGymRequest {
  /**
   *
   * @type {number}
   * @memberof NearGymRequest
   */
  longitude?: number;
  /**
   *
   * @type {number}
   * @memberof NearGymRequest
   */
  latitude?: number;
}
/**
 *
 * @export
 * @interface NearGymResponse
 */
export interface NearGymResponse {
  /**
   *
   * @type {Array<GymInfo>}
   * @memberof NearGymResponse
   */
  gyms: Array<GymInfo>;
}
/**
 *
 * @export
 * @interface NewsGetRequest
 */
export interface NewsGetRequest {
  /**
   *
   * @type {number}
   * @memberof NewsGetRequest
   */
  pageNumber?: number;
}
/**
 *
 * @export
 * @interface NewsGetResponse
 */
export interface NewsGetResponse {
  /**
   *
   * @type {number}
   * @memberof NewsGetResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof NewsGetResponse
   */
  numberOfNews: number;
  /**
   *
   * @type {Array<NewsInfo>}
   * @memberof NewsGetResponse
   */
  news: Array<NewsInfo>;
}
/**
 *
 * @export
 * @interface NewsInfo
 */
export interface NewsInfo {
  /**
   *
   * @type {number}
   * @memberof NewsInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof NewsInfo
   */
  type: NewsInfoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NewsInfo
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof NewsInfo
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof NewsInfo
   */
  pictureUrl?: string;
  /**
   *
   * @type {string}
   * @memberof NewsInfo
   */
  logoUrl: string;
  /**
   *
   * @type {string}
   * @memberof NewsInfo
   */
  sender: string;
  /**
   *
   * @type {string}
   * @memberof NewsInfo
   */
  transitionUrl: string;
  /**
   *
   * @type {boolean}
   * @memberof NewsInfo
   */
  read: boolean;
  /**
   *
   * @type {string}
   * @memberof NewsInfo
   */
  registerDatetime: string;
}

/**
 * @export
 * @enum {string}
 */
export enum NewsInfoTypeEnum {
  Admin = 'Admin',
  Brand = 'Brand',
  Gym = 'Gym',
  User = 'User',
}

/**
 *
 * @export
 * @interface NextUserSubscribe
 */
export interface NextUserSubscribe {
  /**
   *
   * @type {string}
   * @memberof NextUserSubscribe
   */
  startDatetime: string;
  /**
   *
   * @type {UserSubscribePlan}
   * @memberof NextUserSubscribe
   */
  plan: UserSubscribePlan;
  /**
   *
   * @type {TicketGym}
   * @memberof NextUserSubscribe
   */
  gym: TicketGym;
  /**
   *
   * @type {NextUserSubscribePrice}
   * @memberof NextUserSubscribe
   */
  price: NextUserSubscribePrice;
  /**
   *
   * @type {NextUserSubscribeCoupon}
   * @memberof NextUserSubscribe
   */
  coupon?: NextUserSubscribeCoupon;
  /**
   *
   * @type {boolean}
   * @memberof NextUserSubscribe
   */
  canApplyCoupon: boolean;
}
/**
 *
 * @export
 * @interface NextUserSubscribeCoupon
 */
export interface NextUserSubscribeCoupon {
  /**
   *
   * @type {number}
   * @memberof NextUserSubscribeCoupon
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof NextUserSubscribeCoupon
   */
  campaignName: string;
  /**
   *
   * @type {string}
   * @memberof NextUserSubscribeCoupon
   */
  discountLabel: string;
}
/**
 *
 * @export
 * @interface NextUserSubscribePrice
 */
export interface NextUserSubscribePrice {
  /**
   *
   * @type {number}
   * @memberof NextUserSubscribePrice
   */
  subtotal: number;
  /**
   *
   * @type {number}
   * @memberof NextUserSubscribePrice
   */
  discount: number;
  /**
   *
   * @type {number}
   * @memberof NextUserSubscribePrice
   */
  total: number;
}
/**
 *
 * @export
 * @interface NextUserUpdateSubscribe
 */
export interface NextUserUpdateSubscribe {
  /**
   *
   * @type {string}
   * @memberof NextUserUpdateSubscribe
   */
  startDatetime: string;
  /**
   *
   * @type {UserUpdateSubscribePlan}
   * @memberof NextUserUpdateSubscribe
   */
  plan: UserUpdateSubscribePlan;
  /**
   *
   * @type {NextUserSubscribePrice}
   * @memberof NextUserUpdateSubscribe
   */
  price: NextUserSubscribePrice;
  /**
   *
   * @type {NextUserSubscribeCoupon}
   * @memberof NextUserUpdateSubscribe
   */
  coupon: NextUserSubscribeCoupon | null;
  /**
   *
   * @type {boolean}
   * @memberof NextUserUpdateSubscribe
   */
  canApplyCoupon: boolean;
}
/**
 *
 * @export
 * @interface OfficerInfo
 */
export interface OfficerInfo {
  /**
   *
   * @type {number}
   * @memberof OfficerInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof OfficerInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof OfficerInfo
   */
  type: OfficerInfoTypeEnum;
  /**
   *
   * @type {number}
   * @memberof OfficerInfo
   */
  unread: number;
  /**
   *
   * @type {string}
   * @memberof OfficerInfo
   */
  iconUrl: string;
}

/**
 * @export
 * @enum {string}
 */
export enum OfficerInfoTypeEnum {
  Gym = 'gym',
  Admin = 'admin',
}

/**
 *
 * @export
 * @interface OptionPlan
 */
export interface OptionPlan {
  /**
   *
   * @type {number}
   * @memberof OptionPlan
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof OptionPlan
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof OptionPlan
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof OptionPlan
   */
  explain: string;
  /**
   *
   * @type {number}
   * @memberof OptionPlan
   */
  term: number;
}
/**
 *
 * @export
 * @interface OptionPlanInfo
 */
export interface OptionPlanInfo {
  /**
   *
   * @type {number}
   * @memberof OptionPlanInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof OptionPlanInfo
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof OptionPlanInfo
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof OptionPlanInfo
   */
  explain: string;
  /**
   *
   * @type {number}
   * @memberof OptionPlanInfo
   */
  term: number;
}
/**
 *
 * @export
 * @interface PartnerDisplayInfo
 */
export interface PartnerDisplayInfo {
  /**
   *
   * @type {number}
   * @memberof PartnerDisplayInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PartnerDisplayInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PartnerDisplayInfo
   */
  email: string;
}
/**
 *
 * @export
 * @interface PartnerDisplayInfoPage
 */
export interface PartnerDisplayInfoPage {
  /**
   *
   * @type {number}
   * @memberof PartnerDisplayInfoPage
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PartnerDisplayInfoPage
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PartnerDisplayInfoPage
   */
  email: string;
  /**
   *
   * @type {Array<UserInfoMainStore>}
   * @memberof PartnerDisplayInfoPage
   */
  gyms: Array<UserInfoMainStore>;
}
/**
 *
 * @export
 * @interface PaymentLogWithUserInfo
 */
export interface PaymentLogWithUserInfo {
  /**
   *
   * @type {string}
   * @memberof PaymentLogWithUserInfo
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof PaymentLogWithUserInfo
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof PaymentLogWithUserInfo
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof PaymentLogWithUserInfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PaymentLogWithUserInfo
   */
  paymentDatetime: string;
  /**
   *
   * @type {string}
   * @memberof PaymentLogWithUserInfo
   */
  nextPaymentDatetime: string;
  /**
   *
   * @type {string}
   * @memberof PaymentLogWithUserInfo
   */
  paymentWays: PaymentLogWithUserInfoPaymentWaysEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentLogWithUserInfo
   */
  categoryOfPlan: PaymentLogWithUserInfoCategoryOfPlanEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentLogWithUserInfoPaymentWaysEnum {
  Payjp = 'Payjp',
  Paidy = 'Paidy',
  Paypay = 'Paypay',
}
/**
 * @export
 * @enum {string}
 */
export enum PaymentLogWithUserInfoCategoryOfPlanEnum {
  Subscribe = 'subscribe',
  TemporaryUse = 'temporaryUse',
}

/**
 *
 * @export
 * @interface PaymentWay
 */
export interface PaymentWay {
  /**
   *
   * @type {number}
   * @memberof PaymentWay
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PaymentWay
   */
  way: PaymentWayWayEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentWay
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof PaymentWay
   */
  isDefault: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PaymentWay
   */
  isDeletable: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentWayWayEnum {
  Payjp = 'Payjp',
  Paidy = 'Paidy',
  Paypay = 'Paypay',
}

/**
 *
 * @export
 * @interface PresentPointBatchRequest
 */
export interface PresentPointBatchRequest {
  /**
   *
   * @type {string}
   * @memberof PresentPointBatchRequest
   */
  reason: PresentPointBatchRequestReasonEnum;
  /**
   *
   * @type {number}
   * @memberof PresentPointBatchRequest
   */
  startUserId: number;
  /**
   *
   * @type {number}
   * @memberof PresentPointBatchRequest
   */
  endUserId: number;
}

/**
 * @export
 * @enum {string}
 */
export enum PresentPointBatchRequestReasonEnum {
  Present100StoreOpeningsConfirmed = 'Present100StoreOpeningsConfirmed',
  PresentChristmas2024 = 'PresentChristmas2024',
}

/**
 *
 * @export
 * @interface PresentPointBatchResponse
 */
export interface PresentPointBatchResponse {
  /**
   *
   * @type {boolean}
   * @memberof PresentPointBatchResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface ReadMessagesRequest
 */
export interface ReadMessagesRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof ReadMessagesRequest
   */
  messageIds: Array<number>;
}
/**
 *
 * @export
 * @interface ReadMessagesResponse
 */
export interface ReadMessagesResponse {
  /**
   *
   * @type {boolean}
   * @memberof ReadMessagesResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface ReadNewsRequest
 */
export interface ReadNewsRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof ReadNewsRequest
   */
  newsIds: Array<number>;
}
/**
 *
 * @export
 * @interface ReadNewsResponse
 */
export interface ReadNewsResponse {
  /**
   *
   * @type {boolean}
   * @memberof ReadNewsResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenRequest
   */
  refreshToken: string;
}
/**
 *
 * @export
 * @interface RefreshTokenResponse
 */
export interface RefreshTokenResponse {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenResponse
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof RefreshTokenResponse
   */
  refreshToken: string;
}
/**
 *
 * @export
 * @interface Reward
 */
export interface Reward {
  /**
   *
   * @type {number}
   * @memberof Reward
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Reward
   */
  name: string;
  /**
   *
   * @type {ImageSrc}
   * @memberof Reward
   */
  thumbnailSrc: ImageSrc;
  /**
   *
   * @type {number}
   * @memberof Reward
   */
  usageLimits: number | null;
  /**
   *
   * @type {string}
   * @memberof Reward
   */
  usageType: RewardUsageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Reward
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Reward
   */
  caution: string;
  /**
   *
   * @type {number}
   * @memberof Reward
   */
  requiredPoints: number;
  /**
   *
   * @type {number}
   * @memberof Reward
   */
  shortagePoints: number;
  /**
   *
   * @type {boolean}
   * @memberof Reward
   */
  isExchangeable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Reward
   */
  isUsageLimitExceeded: boolean;
  /**
   *
   * @type {string}
   * @memberof Reward
   */
  couponCode: string;
  /**
   *
   * @type {string}
   * @memberof Reward
   */
  expiredDatetime: string | null;
  /**
   *
   * @type {string}
   * @memberof Reward
   */
  pointExchangeDisabledReason: string;
  /**
   *
   * @type {string}
   * @memberof Reward
   */
  tag: string;
  /**
   *
   * @type {RewardProvider}
   * @memberof Reward
   */
  provider: RewardProvider;
  /**
   *
   * @type {Array<string>}
   * @memberof Reward
   */
  exchangeWarnings: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Reward
   */
  labels: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum RewardUsageTypeEnum {
  InPerson = 'InPerson',
  CouponCode = 'CouponCode',
  UniqueCouponCode = 'UniqueCouponCode',
  Campaign = 'Campaign',
}

/**
 *
 * @export
 * @interface RewardExchangeMessage
 */
export interface RewardExchangeMessage {
  /**
   *
   * @type {string}
   * @memberof RewardExchangeMessage
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof RewardExchangeMessage
   */
  content: string;
}
/**
 *
 * @export
 * @interface RewardListContent
 */
export interface RewardListContent {
  /**
   *
   * @type {number}
   * @memberof RewardListContent
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof RewardListContent
   */
  name: string;
  /**
   *
   * @type {ImageSrc}
   * @memberof RewardListContent
   */
  thumbnailSrc: ImageSrc;
  /**
   *
   * @type {number}
   * @memberof RewardListContent
   */
  requiredPoints: number;
  /**
   *
   * @type {boolean}
   * @memberof RewardListContent
   */
  isExchangeable: boolean;
  /**
   *
   * @type {string}
   * @memberof RewardListContent
   */
  providerName: string;
}
/**
 *
 * @export
 * @interface RewardProvider
 */
export interface RewardProvider {
  /**
   *
   * @type {number}
   * @memberof RewardProvider
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof RewardProvider
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RewardProvider
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof RewardProvider
   */
  url: string;
}
/**
 *
 * @export
 * @interface RewardSection
 */
export interface RewardSection {
  /**
   *
   * @type {string}
   * @memberof RewardSection
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof RewardSection
   */
  tagKey: string;
  /**
   *
   * @type {Array<RewardListContent>}
   * @memberof RewardSection
   */
  rewards: Array<RewardListContent>;
}
/**
 *
 * @export
 * @interface SaleBatchResponse
 */
export interface SaleBatchResponse {
  /**
   *
   * @type {boolean}
   * @memberof SaleBatchResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface SalesSummary
 */
export interface SalesSummary {
  /**
   *
   * @type {string}
   * @memberof SalesSummary
   */
  date: string;
  /**
   *
   * @type {Array<SalesSummarySales>}
   * @memberof SalesSummary
   */
  sales: Array<SalesSummarySales>;
  /**
   *
   * @type {SalesSummaryTotalSales}
   * @memberof SalesSummary
   */
  totalSales: SalesSummaryTotalSales;
}
/**
 *
 * @export
 * @interface SalesSummaryBatchRequest
 */
export interface SalesSummaryBatchRequest {
  /**
   *
   * @type {string}
   * @memberof SalesSummaryBatchRequest
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof SalesSummaryBatchRequest
   */
  endDate: string;
}
/**
 *
 * @export
 * @interface SalesSummaryBatchResponse
 */
export interface SalesSummaryBatchResponse {
  /**
   *
   * @type {boolean}
   * @memberof SalesSummaryBatchResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface SalesSummarySales
 */
export interface SalesSummarySales {
  /**
   *
   * @type {number}
   * @memberof SalesSummarySales
   */
  planId?: number;
  /**
   *
   * @type {string}
   * @memberof SalesSummarySales
   */
  displayName?: string;
  /**
   *
   * @type {number}
   * @memberof SalesSummarySales
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof SalesSummarySales
   */
  count?: number;
}
/**
 *
 * @export
 * @interface SalesSummaryTotalSales
 */
export interface SalesSummaryTotalSales {
  /**
   *
   * @type {number}
   * @memberof SalesSummaryTotalSales
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof SalesSummaryTotalSales
   */
  count?: number;
}
/**
 *
 * @export
 * @interface SearchGymRequest
 */
export interface SearchGymRequest {
  /**
   *
   * @type {string}
   * @memberof SearchGymRequest
   */
  searchString: string;
}
/**
 *
 * @export
 * @interface SearchGymResponse
 */
export interface SearchGymResponse {
  /**
   *
   * @type {Array<GymInfo>}
   * @memberof SearchGymResponse
   */
  gyms: Array<GymInfo>;
}
/**
 *
 * @export
 * @interface SearchNewsRequest
 */
export interface SearchNewsRequest {
  /**
   *
   * @type {number}
   * @memberof SearchNewsRequest
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof SearchNewsRequest
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof SearchNewsRequest
   */
  OR?: boolean;
  /**
   *
   * @type {string}
   * @memberof SearchNewsRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SearchNewsRequest
   */
  type?: SearchNewsRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SearchNewsRequest
   */
  postDatetime?: string;
  /**
   *
   * @type {string}
   * @memberof SearchNewsRequest
   */
  order: SearchNewsRequestOrderEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SearchNewsRequestTypeEnum {
  Admin = 'Admin',
  Brand = 'Brand',
  Gym = 'Gym',
}
/**
 * @export
 * @enum {string}
 */
export enum SearchNewsRequestOrderEnum {
  Desc = 'desc',
  Asc = 'asc',
}

/**
 *
 * @export
 * @interface SearchNewsResponse
 */
export interface SearchNewsResponse {
  /**
   *
   * @type {number}
   * @memberof SearchNewsResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof SearchNewsResponse
   */
  numberOfNews: number;
  /**
   *
   * @type {Array<NewsInfo>}
   * @memberof SearchNewsResponse
   */
  news: Array<NewsInfo>;
}
/**
 *
 * @export
 * @interface ShotPlan
 */
export interface ShotPlan {
  /**
   *
   * @type {number}
   * @memberof ShotPlan
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ShotPlan
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ShotPlan
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof ShotPlan
   */
  explain: string;
  /**
   *
   * @type {number}
   * @memberof ShotPlan
   */
  availableCount: number;
}
/**
 *
 * @export
 * @interface ShotPlanDisplayInfo
 */
export interface ShotPlanDisplayInfo {
  /**
   *
   * @type {number}
   * @memberof ShotPlanDisplayInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ShotPlanDisplayInfo
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ShotPlanDisplayInfo
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof ShotPlanDisplayInfo
   */
  explain: string;
  /**
   *
   * @type {number}
   * @memberof ShotPlanDisplayInfo
   */
  availableCount: number;
}
/**
 *
 * @export
 * @interface ShotPlanInfo
 */
export interface ShotPlanInfo {
  /**
   *
   * @type {number}
   * @memberof ShotPlanInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ShotPlanInfo
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ShotPlanInfo
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof ShotPlanInfo
   */
  explain: string;
  /**
   *
   * @type {number}
   * @memberof ShotPlanInfo
   */
  availableCount: number;
  /**
   *
   * @type {BrandInfo}
   * @memberof ShotPlanInfo
   */
  brand: BrandInfo;
}
/**
 *
 * @export
 * @interface SmsVerification
 */
export interface SmsVerification {
  /**
   *
   * @type {number}
   * @memberof SmsVerification
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof SmsVerification
   */
  userId: number | null;
  /**
   *
   * @type {string}
   * @memberof SmsVerification
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof SmsVerification
   */
  expireDatetime: string;
  /**
   *
   * @type {string}
   * @memberof SmsVerification
   */
  verifyDatetime: string;
  /**
   *
   * @type {string}
   * @memberof SmsVerification
   */
  registerDatetime: string;
  /**
   *
   * @type {string}
   * @memberof SmsVerification
   */
  updateDatetime: string;
}
/**
 *
 * @export
 * @interface StaffChangeMessageReadRequest
 */
export interface StaffChangeMessageReadRequest {
  /**
   *
   * @type {number}
   * @memberof StaffChangeMessageReadRequest
   */
  gymId: number;
  /**
   *
   * @type {number}
   * @memberof StaffChangeMessageReadRequest
   */
  messageId: number;
  /**
   *
   * @type {boolean}
   * @memberof StaffChangeMessageReadRequest
   */
  checkRead: boolean;
}
/**
 *
 * @export
 * @interface StaffGetMessagesRequest
 */
export interface StaffGetMessagesRequest {
  /**
   *
   * @type {number}
   * @memberof StaffGetMessagesRequest
   */
  gymId: number;
  /**
   *
   * @type {number}
   * @memberof StaffGetMessagesRequest
   */
  userId: number;
  /**
   *
   * @type {boolean}
   * @memberof StaffGetMessagesRequest
   */
  checkRead: boolean;
}
/**
 *
 * @export
 * @interface StaffGetMessagesResponse
 */
export interface StaffGetMessagesResponse {
  /**
   *
   * @type {Array<MessageText | MessageRadio>}
   * @memberof StaffGetMessagesResponse
   */
  messages: Array<MessageText | MessageRadio>;
}
/**
 *
 * @export
 * @interface StaffGetSubscribeRequest
 */
export interface StaffGetSubscribeRequest {
  /**
   *
   * @type {number}
   * @memberof StaffGetSubscribeRequest
   */
  userId: number;
}
/**
 *
 * @export
 * @interface StaffGetSubscribeResponse
 */
export interface StaffGetSubscribeResponse {
  /**
   *
   * @type {Array<Subscribe>}
   * @memberof StaffGetSubscribeResponse
   */
  subscribes: Array<Subscribe>;
}
/**
 *
 * @export
 * @interface StaffGetTempUseTicketRequest
 */
export interface StaffGetTempUseTicketRequest {
  /**
   *
   * @type {number}
   * @memberof StaffGetTempUseTicketRequest
   */
  userId: number;
}
/**
 *
 * @export
 * @interface StaffGetTempUseTicketResponse
 */
export interface StaffGetTempUseTicketResponse {
  /**
   *
   * @type {Array<TemporaryUse>}
   * @memberof StaffGetTempUseTicketResponse
   */
  temporaryUses: Array<TemporaryUse>;
}
/**
 *
 * @export
 * @interface StaffGymListRequest
 */
export interface StaffGymListRequest {
  /**
   *
   * @type {string}
   * @memberof StaffGymListRequest
   */
  order: StaffGymListRequestOrderEnum;
  /**
   *
   * @type {number}
   * @memberof StaffGymListRequest
   */
  pageNumber?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum StaffGymListRequestOrderEnum {
  Desc = 'desc',
  Asc = 'asc',
}

/**
 *
 * @export
 * @interface StaffGymListResponse
 */
export interface StaffGymListResponse {
  /**
   *
   * @type {Array<TemporaryUseGym>}
   * @memberof StaffGymListResponse
   */
  gyms: Array<TemporaryUseGym>;
  /**
   *
   * @type {number}
   * @memberof StaffGymListResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof StaffGymListResponse
   */
  numberOfGyms: number;
}
/**
 *
 * @export
 * @interface StaffReadMessageRequest
 */
export interface StaffReadMessageRequest {
  /**
   *
   * @type {number}
   * @memberof StaffReadMessageRequest
   */
  gymId: number;
  /**
   *
   * @type {number}
   * @memberof StaffReadMessageRequest
   */
  userId: number;
}
/**
 *
 * @export
 * @interface StaffUserAddTempUseTicketRequest
 */
export interface StaffUserAddTempUseTicketRequest {
  /**
   *
   * @type {number}
   * @memberof StaffUserAddTempUseTicketRequest
   */
  userId: number;
  /**
   *
   * @type {number}
   * @memberof StaffUserAddTempUseTicketRequest
   */
  gymId: number;
}
/**
 *
 * @export
 * @interface StaffUserAddTempUseTicketResponse
 */
export interface StaffUserAddTempUseTicketResponse {
  /**
   *
   * @type {boolean}
   * @memberof StaffUserAddTempUseTicketResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof StaffUserAddTempUseTicketResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface StaffUserMessagesByGymRequest
 */
export interface StaffUserMessagesByGymRequest {
  /**
   *
   * @type {string}
   * @memberof StaffUserMessagesByGymRequest
   */
  order: StaffUserMessagesByGymRequestOrderEnum;
  /**
   *
   * @type {number}
   * @memberof StaffUserMessagesByGymRequest
   */
  pageNumber?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum StaffUserMessagesByGymRequestOrderEnum {
  Desc = 'desc',
  Asc = 'asc',
}

/**
 *
 * @export
 * @interface StaffUserMessagesByGymResponse
 */
export interface StaffUserMessagesByGymResponse {
  /**
   *
   * @type {Array<StaffUserMessagesByGymResponseUsers>}
   * @memberof StaffUserMessagesByGymResponse
   */
  users: Array<StaffUserMessagesByGymResponseUsers>;
  /**
   *
   * @type {number}
   * @memberof StaffUserMessagesByGymResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof StaffUserMessagesByGymResponse
   */
  numberOfUsers: number;
}
/**
 *
 * @export
 * @interface StaffUserMessagesByGymResponseUsers
 */
export interface StaffUserMessagesByGymResponseUsers {
  /**
   *
   * @type {number}
   * @memberof StaffUserMessagesByGymResponseUsers
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof StaffUserMessagesByGymResponseUsers
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof StaffUserMessagesByGymResponseUsers
   */
  unread?: number;
}
/**
 *
 * @export
 * @interface StartSubscribeRequest
 */
export interface StartSubscribeRequest {
  /**
   *
   * @type {string}
   * @memberof StartSubscribeRequest
   */
  date: string;
}
/**
 *
 * @export
 * @interface StartSubscribeResponse
 */
export interface StartSubscribeResponse {
  /**
   *
   * @type {boolean}
   * @memberof StartSubscribeResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface StoreCreateStaffRequest
 */
export interface StoreCreateStaffRequest {
  /**
   *
   * @type {string}
   * @memberof StoreCreateStaffRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof StoreCreateStaffRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof StoreCreateStaffRequest
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof StoreCreateStaffRequest
   */
  roleId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof StoreCreateStaffRequest
   */
  gymIds: Array<number>;
}
/**
 *
 * @export
 * @interface StoreCreateStaffResponse
 */
export interface StoreCreateStaffResponse {
  /**
   *
   * @type {boolean}
   * @memberof StoreCreateStaffResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof StoreCreateStaffResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface StoreDeleteStaffRequest
 */
export interface StoreDeleteStaffRequest {
  /**
   *
   * @type {number}
   * @memberof StoreDeleteStaffRequest
   */
  staffId: number;
}
/**
 *
 * @export
 * @interface StoreDeleteStaffResponse
 */
export interface StoreDeleteStaffResponse {
  /**
   *
   * @type {boolean}
   * @memberof StoreDeleteStaffResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof StoreDeleteStaffResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface StoreEditStaffRequest
 */
export interface StoreEditStaffRequest {
  /**
   *
   * @type {number}
   * @memberof StoreEditStaffRequest
   */
  staffId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof StoreEditStaffRequest
   */
  gymIds: Array<number>;
  /**
   *
   * @type {string}
   * @memberof StoreEditStaffRequest
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof StoreEditStaffRequest
   */
  roleId?: number;
}
/**
 *
 * @export
 * @interface StoreEditStaffResponse
 */
export interface StoreEditStaffResponse {
  /**
   *
   * @type {boolean}
   * @memberof StoreEditStaffResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof StoreEditStaffResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface StoreGateLogGetRequest
 */
export interface StoreGateLogGetRequest {
  /**
   *
   * @type {string}
   * @memberof StoreGateLogGetRequest
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof StoreGateLogGetRequest
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof StoreGateLogGetRequest
   */
  categoryOfPlan?: StoreGateLogGetRequestCategoryOfPlanEnum;
  /**
   *
   * @type {number}
   * @memberof StoreGateLogGetRequest
   */
  gateId?: number;
  /**
   *
   * @type {number}
   * @memberof StoreGateLogGetRequest
   */
  gymId: number;
  /**
   *
   * @type {number}
   * @memberof StoreGateLogGetRequest
   */
  userId?: number;
  /**
   *
   * @type {number}
   * @memberof StoreGateLogGetRequest
   */
  pageNumber?: number;
  /**
   *
   * @type {string}
   * @memberof StoreGateLogGetRequest
   */
  registerStartDatetime?: string;
  /**
   *
   * @type {string}
   * @memberof StoreGateLogGetRequest
   */
  registerEndDatetime?: string;
  /**
   *
   * @type {string}
   * @memberof StoreGateLogGetRequest
   */
  order: StoreGateLogGetRequestOrderEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum StoreGateLogGetRequestCategoryOfPlanEnum {
  Subscribe = 'subscribe',
  TemporaryUse = 'temporaryUse',
}
/**
 * @export
 * @enum {string}
 */
export enum StoreGateLogGetRequestOrderEnum {
  Desc = 'desc',
  Asc = 'asc',
}

/**
 *
 * @export
 * @interface StoreGateLogGetResponse
 */
export interface StoreGateLogGetResponse {
  /**
   *
   * @type {number}
   * @memberof StoreGateLogGetResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof StoreGateLogGetResponse
   */
  numberOfGateLogs: number;
  /**
   *
   * @type {Array<GateLogWithUserInfo>}
   * @memberof StoreGateLogGetResponse
   */
  gateLogs: Array<GateLogWithUserInfo>;
}
/**
 *
 * @export
 * @interface StoreGetAllStaffGymsResponse
 */
export interface StoreGetAllStaffGymsResponse {
  /**
   *
   * @type {Array<GymInfo>}
   * @memberof StoreGetAllStaffGymsResponse
   */
  gymInfos: Array<GymInfo>;
}
/**
 *
 * @export
 * @interface StoreGetGymsResponse
 */
export interface StoreGetGymsResponse {
  /**
   *
   * @type {Array<UserInfoMainStore>}
   * @memberof StoreGetGymsResponse
   */
  gyms: Array<UserInfoMainStore>;
}
/**
 *
 * @export
 * @interface StoreGetRolesResponse
 */
export interface StoreGetRolesResponse {
  /**
   *
   * @type {Array<StoreGetRolesResponseRoles>}
   * @memberof StoreGetRolesResponse
   */
  roles: Array<StoreGetRolesResponseRoles>;
}
/**
 *
 * @export
 * @interface StoreGetRolesResponseAuthorities
 */
export interface StoreGetRolesResponseAuthorities {
  /**
   *
   * @type {string}
   * @memberof StoreGetRolesResponseAuthorities
   */
  authority?: StoreGetRolesResponseAuthoritiesAuthorityEnum;
  /**
   *
   * @type {number}
   * @memberof StoreGetRolesResponseAuthorities
   */
  flag?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum StoreGetRolesResponseAuthoritiesAuthorityEnum {
  Message = 'Message',
  Config = 'Config',
  UserView = 'UserView',
  Sale = 'Sale',
  Log = 'Log',
}

/**
 *
 * @export
 * @interface StoreGetRolesResponseRoles
 */
export interface StoreGetRolesResponseRoles {
  /**
   *
   * @type {number}
   * @memberof StoreGetRolesResponseRoles
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof StoreGetRolesResponseRoles
   */
  name?: string;
  /**
   *
   * @type {Array<StoreGetRolesResponseAuthorities>}
   * @memberof StoreGetRolesResponseRoles
   */
  authorities?: Array<StoreGetRolesResponseAuthorities>;
}
/**
 *
 * @export
 * @interface StoreGetStaffsResponse
 */
export interface StoreGetStaffsResponse {
  /**
   *
   * @type {Array<StoreGetStaffsResponseStaffs>}
   * @memberof StoreGetStaffsResponse
   */
  staffs: Array<StoreGetStaffsResponseStaffs>;
}
/**
 *
 * @export
 * @interface StoreGetStaffsResponseStaffs
 */
export interface StoreGetStaffsResponseStaffs {
  /**
   *
   * @type {number}
   * @memberof StoreGetStaffsResponseStaffs
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof StoreGetStaffsResponseStaffs
   */
  name?: string;
  /**
   *
   * @type {UserInfoMainStore}
   * @memberof StoreGetStaffsResponseStaffs
   */
  role?: UserInfoMainStore;
  /**
   *
   * @type {string}
   * @memberof StoreGetStaffsResponseStaffs
   */
  email?: string;
  /**
   *
   * @type {Array<UserInfoMainStore>}
   * @memberof StoreGetStaffsResponseStaffs
   */
  assignedGyms?: Array<UserInfoMainStore>;
}
/**
 *
 * @export
 * @interface StoreGetUserFilterRequest
 */
export interface StoreGetUserFilterRequest {
  /**
   *
   * @type {number}
   * @memberof StoreGetUserFilterRequest
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof StoreGetUserFilterRequest
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof StoreGetUserFilterRequest
   */
  OR?: boolean;
  /**
   *
   * @type {string}
   * @memberof StoreGetUserFilterRequest
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof StoreGetUserFilterRequest
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof StoreGetUserFilterRequest
   */
  gender?: StoreGetUserFilterRequestGenderEnum;
  /**
   *
   * @type {Array<number>}
   * @memberof StoreGetUserFilterRequest
   */
  mainStoreIds: Array<number>;
  /**
   *
   * @type {number}
   * @memberof StoreGetUserFilterRequest
   */
  gymId?: number;
  /**
   *
   * @type {string}
   * @memberof StoreGetUserFilterRequest
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof StoreGetUserFilterRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof StoreGetUserFilterRequest
   */
  registerStartDatetime?: string;
  /**
   *
   * @type {string}
   * @memberof StoreGetUserFilterRequest
   */
  registerEndDatetime?: string;
  /**
   *
   * @type {number}
   * @memberof StoreGetUserFilterRequest
   */
  startAge?: number;
  /**
   *
   * @type {number}
   * @memberof StoreGetUserFilterRequest
   */
  endAge?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof StoreGetUserFilterRequest
   */
  statuses: Array<StoreGetUserFilterRequestStatusesEnum>;
  /**
   *
   * @type {string}
   * @memberof StoreGetUserFilterRequest
   */
  order: StoreGetUserFilterRequestOrderEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum StoreGetUserFilterRequestGenderEnum {
  Man = 'Man',
  Woman = 'Woman',
  Other = 'Other',
  No = 'No',
}
/**
 * @export
 * @enum {string}
 */
export enum StoreGetUserFilterRequestStatusesEnum {
  Banned = 'banned',
  VoluntaryDeleted = 'voluntaryDeleted',
  Subscribe = 'subscribe',
  SubValidNullTmpUseThisMonth = 'subValidNullTmpUseThisMonth',
  SubValid0TmpUseThisMonth = 'subValid0TmpUseThisMonth',
  SubValidNullTmpUseNotThisMonth = 'subValidNullTmpUseNotThisMonth',
  SubValid0TmpUseNotThisMonth = 'subValid0TmpUseNotThisMonth',
  SubValid0TmpUseNull = 'subValid0TmpUseNull',
  SubValidNullTmpUseNull = 'subValidNullTmpUseNull',
}
/**
 * @export
 * @enum {string}
 */
export enum StoreGetUserFilterRequestOrderEnum {
  Desc = 'desc',
  Asc = 'asc',
}

/**
 *
 * @export
 * @interface StoreGetUserResponse
 */
export interface StoreGetUserResponse {
  /**
   *
   * @type {number}
   * @memberof StoreGetUserResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof StoreGetUserResponse
   */
  numberOfUsers: number;
  /**
   *
   * @type {Array<UserInfo>}
   * @memberof StoreGetUserResponse
   */
  usersInfo: Array<UserInfo>;
}
/**
 *
 * @export
 * @interface StoreGymGetResponse
 */
export interface StoreGymGetResponse {
  /**
   *
   * @type {Array<GymStatusInfo>}
   * @memberof StoreGymGetResponse
   */
  gymStatuses: Array<GymStatusInfo>;
}
/**
 *
 * @export
 * @interface StorePaymentLogGetRequest
 */
export interface StorePaymentLogGetRequest {
  /**
   *
   * @type {number}
   * @memberof StorePaymentLogGetRequest
   */
  gymId: number;
  /**
   *
   * @type {number}
   * @memberof StorePaymentLogGetRequest
   */
  pageNumber?: number;
  /**
   *
   * @type {string}
   * @memberof StorePaymentLogGetRequest
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof StorePaymentLogGetRequest
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof StorePaymentLogGetRequest
   */
  paymentWay?: string;
  /**
   *
   * @type {number}
   * @memberof StorePaymentLogGetRequest
   */
  userId?: number;
  /**
   *
   * @type {string}
   * @memberof StorePaymentLogGetRequest
   */
  categoryOfPlan?: StorePaymentLogGetRequestCategoryOfPlanEnum;
  /**
   *
   * @type {string}
   * @memberof StorePaymentLogGetRequest
   */
  registerStartDatetime?: string;
  /**
   *
   * @type {string}
   * @memberof StorePaymentLogGetRequest
   */
  registerEndDatetime?: string;
  /**
   *
   * @type {string}
   * @memberof StorePaymentLogGetRequest
   */
  order: StorePaymentLogGetRequestOrderEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum StorePaymentLogGetRequestCategoryOfPlanEnum {
  Subscribe = 'subscribe',
  TemporaryUse = 'temporaryUse',
}
/**
 * @export
 * @enum {string}
 */
export enum StorePaymentLogGetRequestOrderEnum {
  Desc = 'desc',
  Asc = 'asc',
}

/**
 *
 * @export
 * @interface StorePaymentLogGetResponse
 */
export interface StorePaymentLogGetResponse {
  /**
   *
   * @type {number}
   * @memberof StorePaymentLogGetResponse
   */
  numberOfPages: number;
  /**
   *
   * @type {number}
   * @memberof StorePaymentLogGetResponse
   */
  numberOfPaymentLogs: number;
  /**
   *
   * @type {Array<PaymentLogWithUserInfo>}
   * @memberof StorePaymentLogGetResponse
   */
  paymentLogs: Array<PaymentLogWithUserInfo>;
}
/**
 *
 * @export
 * @interface Subscribe
 */
export interface Subscribe {
  /**
   *
   * @type {number}
   * @memberof Subscribe
   */
  gymId: number;
  /**
   *
   * @type {number}
   * @memberof Subscribe
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof Subscribe
   */
  planId: number;
  /**
   *
   * @type {number}
   * @memberof Subscribe
   */
  userId: number;
  /**
   *
   * @type {number}
   * @memberof Subscribe
   */
  entranceId: number;
  /**
   *
   * @type {number}
   * @memberof Subscribe
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof Subscribe
   */
  startDatetime: string;
  /**
   *
   * @type {string}
   * @memberof Subscribe
   */
  expiredDatetime: string;
  /**
   *
   * @type {boolean}
   * @memberof Subscribe
   */
  disable: boolean;
  /**
   *
   * @type {string}
   * @memberof Subscribe
   */
  registerDatetime: string;
  /**
   *
   * @type {string}
   * @memberof Subscribe
   */
  updateDatetime: string;
  /**
   *
   * @type {boolean}
   * @memberof Subscribe
   */
  valid: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Subscribe
   */
  continueSubscribe: boolean;
  /**
   *
   * @type {number}
   * @memberof Subscribe
   */
  nextUpdateSubscribePlanId: number | null;
  /**
   *
   * @type {number}
   * @memberof Subscribe
   */
  nextUpdateCouponId: number | null;
  /**
   *
   * @type {string}
   * @memberof Subscribe
   */
  cancelledDatetime: string;
  /**
   *
   * @type {number}
   * @memberof Subscribe
   */
  chargeId: number;
  /**
   *
   * @type {SubscribeEntrance}
   * @memberof Subscribe
   */
  entrance: SubscribeEntrance;
}
/**
 *
 * @export
 * @interface SubscribeBatchRequest
 */
export interface SubscribeBatchRequest {
  /**
   *
   * @type {string}
   * @memberof SubscribeBatchRequest
   */
  date: string;
}
/**
 *
 * @export
 * @interface SubscribeBatchResponse
 */
export interface SubscribeBatchResponse {
  /**
   *
   * @type {boolean}
   * @memberof SubscribeBatchResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface SubscribeEntrance
 */
export interface SubscribeEntrance {
  /**
   *
   * @type {string}
   * @memberof SubscribeEntrance
   */
  token?: string;
}
/**
 *
 * @export
 * @interface SubscribeInfo
 */
export interface SubscribeInfo {
  /**
   *
   * @type {number}
   * @memberof SubscribeInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SubscribeInfo
   */
  name: string;
  /**
   *
   * @type {Array<SubscribeInfoOptions>}
   * @memberof SubscribeInfo
   */
  options: Array<SubscribeInfoOptions>;
  /**
   *
   * @type {SubscribePlanInfo}
   * @memberof SubscribeInfo
   */
  plan: SubscribePlanInfo;
  /**
   *
   * @type {string}
   * @memberof SubscribeInfo
   */
  startDatetime: string;
  /**
   *
   * @type {string}
   * @memberof SubscribeInfo
   */
  expiredDatetime: string;
  /**
   *
   * @type {boolean}
   * @memberof SubscribeInfo
   */
  continueSubscribe: boolean;
  /**
   *
   * @type {Array<GymCardInfoDeprecated>}
   * @memberof SubscribeInfo
   */
  gyms: Array<GymCardInfoDeprecated>;
  /**
   *
   * @type {number}
   * @memberof SubscribeInfo
   */
  myGymId: number;
}
/**
 *
 * @export
 * @interface SubscribeInfoOptions
 */
export interface SubscribeInfoOptions {
  /**
   *
   * @type {number}
   * @memberof SubscribeInfoOptions
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SubscribeInfoOptions
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof SubscribeInfoOptions
   */
  canStop?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SubscribeInfoOptions
   */
  continueSubscribe?: boolean;
}
/**
 *
 * @export
 * @interface SubscribePlan
 */
export interface SubscribePlan {
  /**
   *
   * @type {number}
   * @memberof SubscribePlan
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SubscribePlan
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SubscribePlan
   */
  subtitle: string;
  /**
   *
   * @type {number}
   * @memberof SubscribePlan
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof SubscribePlan
   */
  explain: string | null;
  /**
   *
   * @type {string}
   * @memberof SubscribePlan
   */
  planType: SubscribePlanPlanTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SubscribePlan
   */
  availabilityType: SubscribePlanAvailabilityTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SubscribePlan
   */
  term: number;
  /**
   *
   * @type {string}
   * @memberof SubscribePlan
   */
  termUnit: SubscribePlanTermUnitEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum SubscribePlanPlanTypeEnum {
  Standard = 'Standard',
  Organization = 'Organization',
}
/**
 * @export
 * @enum {string}
 */
export enum SubscribePlanAvailabilityTypeEnum {
  All = 'All',
  Specific = 'Specific',
}
/**
 * @export
 * @enum {string}
 */
export enum SubscribePlanTermUnitEnum {
  Day = 'Day',
  Month = 'Month',
}

/**
 *
 * @export
 * @interface SubscribePlanInfo
 */
export interface SubscribePlanInfo {
  /**
   *
   * @type {number}
   * @memberof SubscribePlanInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SubscribePlanInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SubscribePlanInfo
   */
  subtitle: string;
  /**
   *
   * @type {number}
   * @memberof SubscribePlanInfo
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof SubscribePlanInfo
   */
  nonTaxedPrice: number;
  /**
   *
   * @type {string}
   * @memberof SubscribePlanInfo
   */
  explain?: string;
  /**
   *
   * @type {number}
   * @memberof SubscribePlanInfo
   */
  term: number;
  /**
   *
   * @type {boolean}
   * @memberof SubscribePlanInfo
   */
  useFirstTimePlan: boolean;
  /**
   *
   * @type {number}
   * @memberof SubscribePlanInfo
   */
  firstTimeTerm?: number;
  /**
   *
   * @type {number}
   * @memberof SubscribePlanInfo
   */
  firstTimePrice?: number;
  /**
   *
   * @type {string}
   * @memberof SubscribePlanInfo
   */
  purchaseTerms: string;
  /**
   *
   * @type {string}
   * @memberof SubscribePlanInfo
   */
  purchaseCaution: string;
  /**
   *
   * @type {Array<OptionPlanInfo>}
   * @memberof SubscribePlanInfo
   */
  optionalOptionPlans: Array<OptionPlanInfo>;
  /**
   *
   * @type {Array<OptionPlanInfo>}
   * @memberof SubscribePlanInfo
   */
  builtInOptionPlans: Array<OptionPlanInfo>;
  /**
   *
   * @type {Array<ShotPlanInfo>}
   * @memberof SubscribePlanInfo
   */
  builtInShotPlans: Array<ShotPlanInfo>;
  /**
   *
   * @type {BrandInfo}
   * @memberof SubscribePlanInfo
   */
  brand: BrandInfo;
}
/**
 *
 * @export
 * @interface Survey
 */
export interface Survey {
  /**
   *
   * @type {number}
   * @memberof Survey
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Survey
   */
  name: string;
  /**
   *
   * @type {Array<SurveyQuestion>}
   * @memberof Survey
   */
  surveyQuestions: Array<SurveyQuestion>;
}
/**
 *
 * @export
 * @interface SurveyQuestion
 */
export interface SurveyQuestion {
  /**
   *
   * @type {number}
   * @memberof SurveyQuestion
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestion
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestion
   */
  type: SurveyQuestionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SurveyQuestion
   * @deprecated
   */
  order: number;
  /**
   *
   * @type {boolean}
   * @memberof SurveyQuestion
   */
  required: boolean;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestion
   */
  placeholder: string;
  /**
   *
   * @type {Array<SurveyQuestionOption>}
   * @memberof SurveyQuestion
   */
  surveyQuestionOptions: Array<SurveyQuestionOption> | null;
}

/**
 * @export
 * @enum {string}
 */
export enum SurveyQuestionTypeEnum {
  Text = 'Text',
  SingleChoice = 'SingleChoice',
  MultiChoice = 'MultiChoice',
}

/**
 *
 * @export
 * @interface SurveyQuestionOption
 */
export interface SurveyQuestionOption {
  /**
   *
   * @type {number}
   * @memberof SurveyQuestionOption
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof SurveyQuestionOption
   */
  surveyQuestionId: number;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestionOption
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof SurveyQuestionOption
   */
  needsText: boolean;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestionOption
   */
  placeholder: string;
  /**
   *
   * @type {number}
   * @memberof SurveyQuestionOption
   * @deprecated
   */
  order: number;
}
/**
 *
 * @export
 * @interface SurveyQuestionResponse
 */
export interface SurveyQuestionResponse {
  /**
   *
   * @type {number}
   * @memberof SurveyQuestionResponse
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof SurveyQuestionResponse
   */
  userId: number;
  /**
   *
   * @type {number}
   * @memberof SurveyQuestionResponse
   */
  surveyQuestionId: number;
  /**
   *
   * @type {SurveyQuestionOption}
   * @memberof SurveyQuestionResponse
   */
  surveyQuestionOption: SurveyQuestionOption | null;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestionResponse
   */
  text: string | null;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestionResponse
   */
  registerDatetime: string;
  /**
   *
   * @type {string}
   * @memberof SurveyQuestionResponse
   */
  updateDatetime: string;
}
/**
 *
 * @export
 * @interface TemporaryUse
 */
export interface TemporaryUse {
  /**
   *
   * @type {number}
   * @memberof TemporaryUse
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof TemporaryUse
   */
  gymId: number;
  /**
   *
   * @type {number}
   * @memberof TemporaryUse
   */
  userId: number;
  /**
   *
   * @type {number}
   * @memberof TemporaryUse
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof TemporaryUse
   */
  entranceId: number;
  /**
   *
   * @type {boolean}
   * @memberof TemporaryUse
   */
  disable: boolean;
  /**
   *
   * @type {string}
   * @memberof TemporaryUse
   */
  registerDatetime: string;
  /**
   *
   * @type {string}
   * @memberof TemporaryUse
   */
  updateDatetime: string;
  /**
   *
   * @type {boolean}
   * @memberof TemporaryUse
   */
  valid: boolean;
  /**
   *
   * @type {SubscribeEntrance}
   * @memberof TemporaryUse
   */
  entrance: SubscribeEntrance;
  /**
   *
   * @type {number}
   * @memberof TemporaryUse
   */
  chargeId: number;
  /**
   *
   * @type {TemporaryUseGym}
   * @memberof TemporaryUse
   */
  gym: TemporaryUseGym;
}
/**
 *
 * @export
 * @interface TemporaryUseGym
 */
export interface TemporaryUseGym {
  /**
   *
   * @type {number}
   * @memberof TemporaryUseGym
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TemporaryUseGym
   */
  name?: string;
}
/**
 *
 * @export
 * @interface Ticket
 */
export interface Ticket {
  /**
   *
   * @type {number}
   * @memberof Ticket
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof Ticket
   */
  type: TicketTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof Ticket
   */
  isSubscribe: boolean;
  /**
   *
   * @type {TicketSubscribe}
   * @memberof Ticket
   */
  subscribe: TicketSubscribe | null;
  /**
   *
   * @type {TicketTemporaryUse}
   * @memberof Ticket
   */
  temporaryUse: TicketTemporaryUse | null;
  /**
   *
   * @type {TicketGym}
   * @memberof Ticket
   */
  gym: TicketGym;
}

/**
 * @export
 * @enum {string}
 */
export enum TicketTypeEnum {
  Subscribe = 'Subscribe',
  TemporaryUse = 'TemporaryUse',
}

/**
 *
 * @export
 * @interface TicketGym
 */
export interface TicketGym {
  /**
   *
   * @type {number}
   * @memberof TicketGym
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TicketGym
   */
  name: string;
  /**
   *
   * @type {TicketGymBrand}
   * @memberof TicketGym
   */
  brand: TicketGymBrand;
}
/**
 *
 * @export
 * @interface TicketGymBrand
 */
export interface TicketGymBrand {
  /**
   *
   * @type {number}
   * @memberof TicketGymBrand
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TicketGymBrand
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TicketGymBrand
   */
  iconUrl: string;
  /**
   *
   * @type {string}
   * @memberof TicketGymBrand
   */
  ticketIconUrl: string;
  /**
   *
   * @type {string}
   * @memberof TicketGymBrand
   */
  pictureIconUrl: string;
}
/**
 *
 * @export
 * @interface TicketSubscribe
 */
export interface TicketSubscribe {
  /**
   *
   * @type {number}
   * @memberof TicketSubscribe
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TicketSubscribe
   */
  startDatetime: string;
  /**
   *
   * @type {string}
   * @memberof TicketSubscribe
   */
  expiredDatetime: string;
  /**
   *
   * @type {boolean}
   * @memberof TicketSubscribe
   */
  canceled: boolean;
  /**
   *
   * @type {TicketSubscribePlan}
   * @memberof TicketSubscribe
   */
  plan: TicketSubscribePlan;
}
/**
 *
 * @export
 * @interface TicketSubscribePlan
 */
export interface TicketSubscribePlan {
  /**
   *
   * @type {number}
   * @memberof TicketSubscribePlan
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TicketSubscribePlan
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TicketSubscribePlan
   */
  subtitle: string;
  /**
   *
   * @type {string}
   * @memberof TicketSubscribePlan
   */
  type: TicketSubscribePlanTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TicketSubscribePlan
   */
  availabilityType: TicketSubscribePlanAvailabilityTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum TicketSubscribePlanTypeEnum {
  Standard = 'Standard',
  Organization = 'Organization',
}
/**
 * @export
 * @enum {string}
 */
export enum TicketSubscribePlanAvailabilityTypeEnum {
  All = 'All',
  Specific = 'Specific',
}

/**
 *
 * @export
 * @interface TicketTemporaryUse
 */
export interface TicketTemporaryUse {
  /**
   *
   * @type {number}
   * @memberof TicketTemporaryUse
   */
  count: number;
}
/**
 *
 * @export
 * @interface UpdateGymAnnouncementRequest
 */
export interface UpdateGymAnnouncementRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateGymAnnouncementRequest
   */
  status: UpdateGymAnnouncementRequestStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateGymAnnouncementRequestStatusEnum {
  Publish = 'Publish',
  Archive = 'Archive',
}

/**
 *
 * @export
 * @interface UpdateGymAnnouncementResponse
 */
export interface UpdateGymAnnouncementResponse {
  /**
   *
   * @type {GymAnnouncement}
   * @memberof UpdateGymAnnouncementResponse
   */
  announcement: GymAnnouncement;
}
/**
 *
 * @export
 * @interface UpdateGymRequest
 */
export interface UpdateGymRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateGymRequest
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof UpdateGymRequest
   */
  partnerId: number;
  /**
   *
   * @type {number}
   * @memberof UpdateGymRequest
   */
  brandId: number;
  /**
   *
   * @type {string}
   * @memberof UpdateGymRequest
   */
  status: UpdateGymRequestStatusEnum;
  /**
   *
   * @type {Array<UpdateGymRequestPhotos>}
   * @memberof UpdateGymRequest
   */
  photos: Array<UpdateGymRequestPhotos>;
  /**
   *
   * @type {string}
   * @memberof UpdateGymRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof UpdateGymRequest
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof UpdateGymRequest
   */
  attention: string;
  /**
   *
   * @type {string}
   * @memberof UpdateGymRequest
   */
  assertionMessage: string;
  /**
   *
   * @type {Array<GymDetailInfoUrls>}
   * @memberof UpdateGymRequest
   */
  urls: Array<GymDetailInfoUrls>;
  /**
   *
   * @type {number}
   * @memberof UpdateGymRequest
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof UpdateGymRequest
   */
  latitude: number;
  /**
   *
   * @type {HQAdminOpenHour}
   * @memberof UpdateGymRequest
   */
  openHour: HQAdminOpenHour;
  /**
   *
   * @type {string}
   * @memberof UpdateGymRequest
   */
  openDate: string;
  /**
   *
   * @type {number}
   * @memberof UpdateGymRequest
   */
  basicPlanId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateGymRequest
   */
  planIds: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateGymRequest
   */
  equipmentIds: Array<number>;
  /**
   *
   * @type {number}
   * @memberof UpdateGymRequest
   */
  temporaryUsePrice: number;
  /**
   *
   * @type {number}
   * @memberof UpdateGymRequest
   */
  customerCapacity: number;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateGymRequestStatusEnum {
  Publish = 'publish',
  Unpublish = 'unpublish',
}

/**
 *
 * @export
 * @interface UpdateGymRequestPhotos
 */
export interface UpdateGymRequestPhotos {
  /**
   *
   * @type {string}
   * @memberof UpdateGymRequestPhotos
   */
  src: string;
  /**
   *
   * @type {string}
   * @memberof UpdateGymRequestPhotos
   */
  caption: string;
  /**
   *
   * @type {number}
   * @memberof UpdateGymRequestPhotos
   */
  priority: number;
}
/**
 *
 * @export
 * @interface UpdateGymResponse
 */
export interface UpdateGymResponse {
  /**
   *
   * @type {HQAdminGym}
   * @memberof UpdateGymResponse
   */
  gym: HQAdminGym;
}
/**
 *
 * @export
 * @interface UpdateNotificationTokenRequest
 */
export interface UpdateNotificationTokenRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateNotificationTokenRequest
   */
  notificationToken: string;
}
/**
 *
 * @export
 * @interface UpdateNotificationTokenResponse
 */
export interface UpdateNotificationTokenResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateNotificationTokenResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface UpdatePlanRequest
 */
export interface UpdatePlanRequest {
  /**
   *
   * @type {string}
   * @memberof UpdatePlanRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePlanRequest
   */
  subtitle: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePlanRequest
   */
  explain?: string;
  /**
   *
   * @type {number}
   * @memberof UpdatePlanRequest
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePlanRequest
   */
  planType: UpdatePlanRequestPlanTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UpdatePlanRequest
   */
  availabilityType: UpdatePlanRequestAvailabilityTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UpdatePlanRequest
   */
  term: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePlanRequest
   */
  termUnit: UpdatePlanRequestTermUnitEnum;
  /**
   *
   * @type {boolean}
   * @memberof UpdatePlanRequest
   */
  useFirstTimePlan: boolean;
  /**
   *
   * @type {number}
   * @memberof UpdatePlanRequest
   */
  firstTimePrice?: number;
  /**
   *
   * @type {number}
   * @memberof UpdatePlanRequest
   */
  firstTimeTerm?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdatePlanRequestPlanTypeEnum {
  Standard = 'Standard',
  Organization = 'Organization',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdatePlanRequestAvailabilityTypeEnum {
  All = 'All',
  Specific = 'Specific',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdatePlanRequestTermUnitEnum {
  Day = 'Day',
  Month = 'Month',
}

/**
 *
 * @export
 * @interface UpdatePlanResponse
 */
export interface UpdatePlanResponse {
  /**
   *
   * @type {SubscribePlan}
   * @memberof UpdatePlanResponse
   */
  subscribePlan: SubscribePlan;
}
/**
 *
 * @export
 * @interface UpdateSmsVerificationRequest
 */
export interface UpdateSmsVerificationRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateSmsVerificationRequest
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSmsVerificationRequest
   */
  verificationCode: string;
}
/**
 *
 * @export
 * @interface UpdateSmsVerificationResponse
 */
export interface UpdateSmsVerificationResponse {
  /**
   *
   * @type {SmsVerification}
   * @memberof UpdateSmsVerificationResponse
   */
  smsVerification: SmsVerification;
}
/**
 *
 * @export
 * @interface UpdateSubscribeRequest
 */
export interface UpdateSubscribeRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateSubscribeRequest
   */
  nextUpdatePlanId: number;
  /**
   *
   * @type {string}
   * @memberof UpdateSubscribeRequest
   */
  nextStartDatetime: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  emailVerified: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phoneNumberVerified: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  birthDay: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  gender: UserGenderEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  postalCode1: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  postalCode2: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  prefecture: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  building: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  banFlag: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  facebookId: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  googleId: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  twitterId: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isPaymentWayUserCreated: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  disable: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  registerDatetime: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  updateDatetime: string;
  /**
   *
   * @type {ChannelTalkConfig}
   * @memberof User
   */
  channelTalkConfig: ChannelTalkConfig;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isDeletable: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum UserGenderEnum {
  Man = 'Man',
  Woman = 'Woman',
  Other = 'Other',
  No = 'No',
}

/**
 *
 * @export
 * @interface UserGrantedReward
 */
export interface UserGrantedReward {
  /**
   *
   * @type {number}
   * @memberof UserGrantedReward
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof UserGrantedReward
   */
  userId: number;
  /**
   *
   * @type {Reward}
   * @memberof UserGrantedReward
   */
  reward: Reward;
}
/**
 *
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
  /**
   *
   * @type {number}
   * @memberof UserInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  gender: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  birthDay: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  postalCode1: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  postalCode2: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  prefecture: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  address: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserInfo
   */
  paymentWays: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  registerDatetime: string;
  /**
   *
   * @type {UserInfoMainStore}
   * @memberof UserInfo
   */
  mainStore: UserInfoMainStore;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  status: UserInfoStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UserInfoStatusEnum {
  Banned = 'banned',
  VoluntaryDeleted = 'voluntaryDeleted',
  Subscribe = 'subscribe',
  SubValidNullTmpUseThisMonth = 'subValidNullTmpUseThisMonth',
  SubValid0TmpUseThisMonth = 'subValid0TmpUseThisMonth',
  SubValidNullTmpUseNotThisMonth = 'subValidNullTmpUseNotThisMonth',
  SubValid0TmpUseNotThisMonth = 'subValid0TmpUseNotThisMonth',
  SubValid0TmpUseNull = 'subValid0TmpUseNull',
  SubValidNullTmpUseNull = 'subValidNullTmpUseNull',
}

/**
 *
 * @export
 * @interface UserInfoMainStore
 */
export interface UserInfoMainStore {
  /**
   *
   * @type {number}
   * @memberof UserInfoMainStore
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserInfoMainStore
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UserPointHistory
 */
export interface UserPointHistory {
  /**
   *
   * @type {number}
   * @memberof UserPointHistory
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof UserPointHistory
   */
  points: number;
  /**
   *
   * @type {UserPointHistoryReason}
   * @memberof UserPointHistory
   */
  reason: UserPointHistoryReason;
  /**
   *
   * @type {string}
   * @memberof UserPointHistory
   */
  createdDatetime: string;
  /**
   *
   * @type {number}
   * @memberof UserPointHistory
   */
  rewardId: number | null;
}
/**
 *
 * @export
 * @interface UserPointHistoryReason
 */
export interface UserPointHistoryReason {
  /**
   *
   * @type {string}
   * @memberof UserPointHistoryReason
   */
  type: UserPointHistoryReasonTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserPointHistoryReason
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof UserPointHistoryReason
   */
  subLabel: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UserPointHistoryReasonTypeEnum {
  CheckIn = 'CheckIn',
  MyScheduleCheckIn = 'MyScheduleCheckIn',
  ExchangeReward = 'ExchangeReward',
  FriendInvitation = 'FriendInvitation',
  Admin = 'Admin',
  Present100StoreOpeningsConfirmed = 'Present100StoreOpeningsConfirmed',
  PresentChristmas2024 = 'PresentChristmas2024',
}

/**
 *
 * @export
 * @interface UserPointSummary
 */
export interface UserPointSummary {
  /**
   *
   * @type {number}
   * @memberof UserPointSummary
   */
  currentPoints: number;
  /**
   *
   * @type {number}
   * @memberof UserPointSummary
   */
  totalPoints: number;
}
/**
 *
 * @export
 * @interface UserSendMessageRequest
 */
export interface UserSendMessageRequest {
  /**
   *
   * @type {string}
   * @memberof UserSendMessageRequest
   */
  messageText: string;
  /**
   *
   * @type {number}
   * @memberof UserSendMessageRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserSendMessageRequest
   */
  type: UserSendMessageRequestTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UserSendMessageRequestTypeEnum {
  Gym = 'gym',
  Admin = 'admin',
}

/**
 *
 * @export
 * @interface UserSendMessageResponse
 */
export interface UserSendMessageResponse {
  /**
   *
   * @type {boolean}
   * @memberof UserSendMessageResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface UserSendStaffMessageRequest
 */
export interface UserSendStaffMessageRequest {
  /**
   *
   * @type {string}
   * @memberof UserSendStaffMessageRequest
   */
  messageText: string;
  /**
   *
   * @type {number}
   * @memberof UserSendStaffMessageRequest
   */
  userId: number;
}
/**
 *
 * @export
 * @interface UserSendStaffMessageResponse
 */
export interface UserSendStaffMessageResponse {
  /**
   *
   * @type {boolean}
   * @memberof UserSendStaffMessageResponse
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface UserSubscribe
 */
export interface UserSubscribe {
  /**
   *
   * @type {number}
   * @memberof UserSubscribe
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserSubscribe
   */
  startDatetime: string;
  /**
   *
   * @type {string}
   * @memberof UserSubscribe
   */
  expiredDatetime: string;
  /**
   *
   * @type {string}
   * @memberof UserSubscribe
   */
  renewalDatetime: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSubscribe
   */
  canceled: boolean;
  /**
   *
   * @type {UserSubscribePlan}
   * @memberof UserSubscribe
   */
  plan: UserSubscribePlan;
  /**
   *
   * @type {NextUserSubscribe}
   * @memberof UserSubscribe
   */
  next: NextUserSubscribe;
  /**
   *
   * @type {Ticket}
   * @memberof UserSubscribe
   * @deprecated
   */
  ticket: Ticket;
  /**
   *
   * @type {TicketGym}
   * @memberof UserSubscribe
   */
  gym: TicketGym;
  /**
   *
   * @type {boolean}
   * @memberof UserSubscribe
   */
  isPhoneCancellationRequired: boolean;
}
/**
 *
 * @export
 * @interface UserSubscribePlan
 */
export interface UserSubscribePlan {
  /**
   *
   * @type {number}
   * @memberof UserSubscribePlan
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserSubscribePlan
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserSubscribePlan
   */
  subtitle: string;
  /**
   *
   * @type {number}
   * @memberof UserSubscribePlan
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof UserSubscribePlan
   */
  nonTaxedPrice: number;
  /**
   *
   * @type {string}
   * @memberof UserSubscribePlan
   */
  explain?: string;
  /**
   *
   * @type {Brand}
   * @memberof UserSubscribePlan
   */
  brand: Brand;
  /**
   *
   * @type {string}
   * @memberof UserSubscribePlan
   */
  planType: UserSubscribePlanPlanTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserSubscribePlan
   */
  availabilityType: UserSubscribePlanAvailabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserSubscribePlan
   */
  termUnit: UserSubscribePlanTermUnitEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UserSubscribePlanPlanTypeEnum {
  Standard = 'Standard',
  Organization = 'Organization',
}
/**
 * @export
 * @enum {string}
 */
export enum UserSubscribePlanAvailabilityTypeEnum {
  All = 'All',
  Specific = 'Specific',
}
/**
 * @export
 * @enum {string}
 */
export enum UserSubscribePlanTermUnitEnum {
  Day = 'Day',
  Month = 'Month',
}

/**
 *
 * @export
 * @interface UserUpdateSubscribe
 */
export interface UserUpdateSubscribe {
  /**
   *
   * @type {number}
   * @memberof UserUpdateSubscribe
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserUpdateSubscribe
   */
  startDatetime: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateSubscribe
   */
  expiredDatetime: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateSubscribe
   */
  renewalDatetime: string;
  /**
   *
   * @type {boolean}
   * @memberof UserUpdateSubscribe
   */
  canceled: boolean;
  /**
   *
   * @type {UserUpdateSubscribePlan}
   * @memberof UserUpdateSubscribe
   */
  plan: UserUpdateSubscribePlan;
  /**
   *
   * @type {NextUserUpdateSubscribe}
   * @memberof UserUpdateSubscribe
   */
  next: NextUserUpdateSubscribe;
  /**
   *
   * @type {TicketGym}
   * @memberof UserUpdateSubscribe
   */
  gym: TicketGym;
  /**
   *
   * @type {boolean}
   * @memberof UserUpdateSubscribe
   */
  isPhoneCancellationRequired: boolean;
}
/**
 *
 * @export
 * @interface UserUpdateSubscribePlan
 */
export interface UserUpdateSubscribePlan {
  /**
   *
   * @type {number}
   * @memberof UserUpdateSubscribePlan
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserUpdateSubscribePlan
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateSubscribePlan
   */
  subtitle: string;
  /**
   *
   * @type {number}
   * @memberof UserUpdateSubscribePlan
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof UserUpdateSubscribePlan
   */
  nonTaxedPrice: number;
  /**
   *
   * @type {string}
   * @memberof UserUpdateSubscribePlan
   */
  explain?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateSubscribePlan
   */
  planType: UserUpdateSubscribePlanPlanTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserUpdateSubscribePlan
   */
  availabilityType: UserUpdateSubscribePlanAvailabilityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserUpdateSubscribePlan
   */
  termUnit: UserUpdateSubscribePlanTermUnitEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UserUpdateSubscribePlanPlanTypeEnum {
  Standard = 'Standard',
  Organization = 'Organization',
}
/**
 * @export
 * @enum {string}
 */
export enum UserUpdateSubscribePlanAvailabilityTypeEnum {
  All = 'All',
  Specific = 'Specific',
}
/**
 * @export
 * @enum {string}
 */
export enum UserUpdateSubscribePlanTermUnitEnum {
  Day = 'Day',
  Month = 'Month',
}

/**
 *
 * @export
 * @interface V2AdminGetUserResponse
 */
export interface V2AdminGetUserResponse {
  /**
   *
   * @type {AdminUser}
   * @memberof V2AdminGetUserResponse
   */
  user: AdminUser;
}
/**
 *
 * @export
 * @interface V2AdminSearchUsersRequest
 */
export interface V2AdminSearchUsersRequest {
  /**
   *
   * @type {number}
   * @memberof V2AdminSearchUsersRequest
   */
  userId: number | null;
  /**
   *
   * @type {string}
   * @memberof V2AdminSearchUsersRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof V2AdminSearchUsersRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof V2AdminSearchUsersRequest
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof V2AdminSearchUsersRequest
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof V2AdminSearchUsersRequest
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof V2AdminSearchUsersRequest
   */
  pageSize: number;
}
/**
 *
 * @export
 * @interface V2AdminSearchUsersResponse
 */
export interface V2AdminSearchUsersResponse {
  /**
   *
   * @type {Array<AdminUserListContent>}
   * @memberof V2AdminSearchUsersResponse
   */
  users: Array<AdminUserListContent>;
  /**
   *
   * @type {number}
   * @memberof V2AdminSearchUsersResponse
   */
  currentPage: number;
  /**
   *
   * @type {number}
   * @memberof V2AdminSearchUsersResponse
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface V2AdminUserAddPointsResponse
 */
export interface V2AdminUserAddPointsResponse {
  /**
   *
   * @type {AdminUserPointSummary}
   * @memberof V2AdminUserAddPointsResponse
   */
  pointSummary: AdminUserPointSummary;
}
/**
 *
 * @export
 * @interface V2AdminUserBanResponse
 */
export interface V2AdminUserBanResponse {
  /**
   *
   * @type {AdminUser}
   * @memberof V2AdminUserBanResponse
   */
  user: AdminUser;
}
/**
 *
 * @export
 * @interface V2AdminUserSubscribeCancelResponse
 */
export interface V2AdminUserSubscribeCancelResponse {
  /**
   *
   * @type {AdminUserCancelSubscribe}
   * @memberof V2AdminUserSubscribeCancelResponse
   */
  subscribe: AdminUserCancelSubscribe;
}
/**
 *
 * @export
 * @interface V2AdminUserUpdateSubscribeResponse
 */
export interface V2AdminUserUpdateSubscribeResponse {
  /**
   *
   * @type {AdminUserSubscribe}
   * @memberof V2AdminUserUpdateSubscribeResponse
   */
  subscribe: AdminUserSubscribe;
}
/**
 *
 * @export
 * @interface V2CancelStopSubscribeRequest
 */
export interface V2CancelStopSubscribeRequest {
  /**
   *
   * @type {number}
   * @memberof V2CancelStopSubscribeRequest
   */
  subscribeId: number;
}
/**
 *
 * @export
 * @interface V2CancelStopSubscribeResponse
 */
export interface V2CancelStopSubscribeResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2CancelStopSubscribeResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2CancelStopSubscribeResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface V2CreateRequest
 */
export interface V2CreateRequest {
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  birthDay: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  gender: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  postalCode1: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  postalCode2: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  prefecture: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  building?: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof V2CreateRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface V2CreateResponse
 */
export interface V2CreateResponse {
  /**
   *
   * @type {string}
   * @memberof V2CreateResponse
   */
  token: string;
  /**
   *
   * @type {CreatedUser}
   * @memberof V2CreateResponse
   */
  user: CreatedUser;
}
/**
 *
 * @export
 * @interface V2CreateSmsVerificationRequest
 */
export interface V2CreateSmsVerificationRequest {
  /**
   *
   * @type {string}
   * @memberof V2CreateSmsVerificationRequest
   */
  phoneNumber: string;
}
/**
 *
 * @export
 * @interface V2CreateSmsVerificationResponse
 */
export interface V2CreateSmsVerificationResponse {
  /**
   *
   * @type {SmsVerification}
   * @memberof V2CreateSmsVerificationResponse
   */
  smsVerification: SmsVerification;
}
/**
 *
 * @export
 * @interface V2ExchangeResponse
 */
export interface V2ExchangeResponse {
  /**
   *
   * @type {UserGrantedReward}
   * @memberof V2ExchangeResponse
   */
  userGrantedReward: UserGrantedReward;
  /**
   *
   * @type {RewardExchangeMessage}
   * @memberof V2ExchangeResponse
   */
  message: RewardExchangeMessage;
}
/**
 *
 * @export
 * @interface V2GetActiveSubscribesResponse
 */
export interface V2GetActiveSubscribesResponse {
  /**
   *
   * @type {UserSubscribe}
   * @memberof V2GetActiveSubscribesResponse
   */
  subscribe: UserSubscribe;
}
/**
 *
 * @export
 * @interface V2GetAllEquipmentsResponse
 */
export interface V2GetAllEquipmentsResponse {
  /**
   *
   * @type {Array<EquipmentInfo>}
   * @memberof V2GetAllEquipmentsResponse
   */
  equipments: Array<EquipmentInfo>;
}
/**
 *
 * @export
 * @interface V2GetAppContentSectionsResponse
 */
export interface V2GetAppContentSectionsResponse {
  /**
   *
   * @type {Array<AppContentSection>}
   * @memberof V2GetAppContentSectionsResponse
   */
  appContentSections: Array<AppContentSection>;
}
/**
 *
 * @export
 * @interface V2GetFriendInvitationResponse
 */
export interface V2GetFriendInvitationResponse {
  /**
   *
   * @type {FriendInvitation}
   * @memberof V2GetFriendInvitationResponse
   */
  friendInvitation: FriendInvitation;
}
/**
 *
 * @export
 * @interface V2GetGymCongestionTimelineResponse
 */
export interface V2GetGymCongestionTimelineResponse {
  /**
   *
   * @type {string}
   * @memberof V2GetGymCongestionTimelineResponse
   */
  dayOfWeek: V2GetGymCongestionTimelineResponseDayOfWeekEnum;
  /**
   *
   * @type {Array<CongestionTimelineByHour>}
   * @memberof V2GetGymCongestionTimelineResponse
   */
  congestionTimeline: Array<CongestionTimelineByHour>;
}

/**
 * @export
 * @enum {string}
 */
export enum V2GetGymCongestionTimelineResponseDayOfWeekEnum {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

/**
 *
 * @export
 * @interface V2GetGymResponse
 */
export interface V2GetGymResponse {
  /**
   *
   * @type {GymDetailInfo}
   * @memberof V2GetGymResponse
   */
  gym: GymDetailInfo;
}
/**
 *
 * @export
 * @interface V2GetHomeAppContentsResponse
 */
export interface V2GetHomeAppContentsResponse {
  /**
   *
   * @type {Array<AppContent>}
   * @memberof V2GetHomeAppContentsResponse
   */
  appContents: Array<AppContent>;
}
/**
 *
 * @export
 * @interface V2GetMySchedulesResponse
 */
export interface V2GetMySchedulesResponse {
  /**
   *
   * @type {Array<MySchedule>}
   * @memberof V2GetMySchedulesResponse
   */
  mySchedules: Array<MySchedule>;
  /**
   *
   * @type {string}
   * @memberof V2GetMySchedulesResponse
   */
  recommendedMessage: string;
}
/**
 *
 * @export
 * @interface V2GetPicturesResponse
 */
export interface V2GetPicturesResponse {
  /**
   *
   * @type {Array<V2GetPicturesResponsePictures>}
   * @memberof V2GetPicturesResponse
   */
  pictures: Array<V2GetPicturesResponsePictures>;
}
/**
 *
 * @export
 * @interface V2GetPicturesResponsePictures
 */
export interface V2GetPicturesResponsePictures {
  /**
   *
   * @type {string}
   * @memberof V2GetPicturesResponsePictures
   */
  sourceUrl: string;
  /**
   *
   * @type {string}
   * @memberof V2GetPicturesResponsePictures
   */
  dstUrl?: string;
}
/**
 *
 * @export
 * @interface V2GetPointHistoriesResponse
 */
export interface V2GetPointHistoriesResponse {
  /**
   *
   * @type {Array<UserPointHistory>}
   * @memberof V2GetPointHistoriesResponse
   */
  pointHistories: Array<UserPointHistory>;
  /**
   *
   * @type {string}
   * @memberof V2GetPointHistoriesResponse
   */
  nextPageToken: string;
}
/**
 *
 * @export
 * @interface V2GetRewardResponse
 */
export interface V2GetRewardResponse {
  /**
   *
   * @type {Reward}
   * @memberof V2GetRewardResponse
   */
  reward: Reward;
}
/**
 *
 * @export
 * @interface V2GetRewardSectionsRequest
 */
export interface V2GetRewardSectionsRequest {
  /**
   *
   * @type {number}
   * @memberof V2GetRewardSectionsRequest
   */
  latitude: number | null;
  /**
   *
   * @type {number}
   * @memberof V2GetRewardSectionsRequest
   */
  longitude: number | null;
}
/**
 *
 * @export
 * @interface V2GetRewardSectionsResponse
 */
export interface V2GetRewardSectionsResponse {
  /**
   *
   * @type {Array<RewardSection>}
   * @memberof V2GetRewardSectionsResponse
   */
  rewardSections: Array<RewardSection>;
}
/**
 *
 * @export
 * @interface V2GetRewardsRequest
 */
export interface V2GetRewardsRequest {
  /**
   *
   * @type {string}
   * @memberof V2GetRewardsRequest
   */
  tagKey: string | null;
  /**
   *
   * @type {number}
   * @memberof V2GetRewardsRequest
   */
  latitude: number | null;
  /**
   *
   * @type {number}
   * @memberof V2GetRewardsRequest
   */
  longitude: number | null;
  /**
   *
   * @type {number}
   * @memberof V2GetRewardsRequest
   */
  pageSize: number | null;
  /**
   *
   * @type {string}
   * @memberof V2GetRewardsRequest
   */
  pageToken: string | null;
}
/**
 *
 * @export
 * @interface V2GetRewardsResponse
 */
export interface V2GetRewardsResponse {
  /**
   *
   * @type {Array<RewardListContent>}
   * @memberof V2GetRewardsResponse
   */
  rewards: Array<RewardListContent>;
  /**
   *
   * @type {string}
   * @memberof V2GetRewardsResponse
   */
  nextPageToken: string;
}
/**
 *
 * @export
 * @interface V2GetShotResponse
 */
export interface V2GetShotResponse {
  /**
   *
   * @type {Array<V2GetShotResponseShots>}
   * @memberof V2GetShotResponse
   */
  shots: Array<V2GetShotResponseShots>;
}
/**
 *
 * @export
 * @interface V2GetShotResponseShots
 */
export interface V2GetShotResponseShots {
  /**
   *
   * @type {number}
   * @memberof V2GetShotResponseShots
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof V2GetShotResponseShots
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof V2GetShotResponseShots
   */
  availableCount: number;
  /**
   *
   * @type {string}
   * @memberof V2GetShotResponseShots
   */
  expiredDatetime: string;
}
/**
 *
 * @export
 * @interface V2GetSubscribePlanAppliedCouponPriceResponse
 */
export interface V2GetSubscribePlanAppliedCouponPriceResponse {
  /**
   *
   * @type {number}
   * @memberof V2GetSubscribePlanAppliedCouponPriceResponse
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof V2GetSubscribePlanAppliedCouponPriceResponse
   */
  nonTaxedPrice: number;
  /**
   *
   * @type {number}
   * @memberof V2GetSubscribePlanAppliedCouponPriceResponse
   */
  priceAfterDiscount: number;
  /**
   *
   * @type {number}
   * @memberof V2GetSubscribePlanAppliedCouponPriceResponse
   */
  discountPrice: number;
  /**
   *
   * @type {AppliedCoupon}
   * @memberof V2GetSubscribePlanAppliedCouponPriceResponse
   */
  coupon: AppliedCoupon;
}
/**
 *
 * @export
 * @interface V2GetTemporaryUseAppliedCouponPriceResponse
 */
export interface V2GetTemporaryUseAppliedCouponPriceResponse {
  /**
   *
   * @type {number}
   * @memberof V2GetTemporaryUseAppliedCouponPriceResponse
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof V2GetTemporaryUseAppliedCouponPriceResponse
   */
  nonTaxedPrice: number;
  /**
   *
   * @type {number}
   * @memberof V2GetTemporaryUseAppliedCouponPriceResponse
   */
  priceAfterDiscount: number;
  /**
   *
   * @type {number}
   * @memberof V2GetTemporaryUseAppliedCouponPriceResponse
   */
  discountPrice: number;
  /**
   *
   * @type {AppliedCoupon}
   * @memberof V2GetTemporaryUseAppliedCouponPriceResponse
   */
  coupon: AppliedCoupon;
}
/**
 *
 * @export
 * @interface V2GetTemporaryUseResponse
 */
export interface V2GetTemporaryUseResponse {
  /**
   *
   * @type {Array<V2GetTemporaryUseResponseTemporaryUses>}
   * @memberof V2GetTemporaryUseResponse
   */
  temporaryUses: Array<V2GetTemporaryUseResponseTemporaryUses>;
}
/**
 *
 * @export
 * @interface V2GetTemporaryUseResponseTemporaryUses
 */
export interface V2GetTemporaryUseResponseTemporaryUses {
  /**
   *
   * @type {number}
   * @memberof V2GetTemporaryUseResponseTemporaryUses
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof V2GetTemporaryUseResponseTemporaryUses
   */
  token: string;
  /**
   *
   * @type {GymCardInfoDeprecated}
   * @memberof V2GetTemporaryUseResponseTemporaryUses
   */
  gym: GymCardInfoDeprecated;
  /**
   *
   * @type {BrandInfo}
   * @memberof V2GetTemporaryUseResponseTemporaryUses
   */
  brand: BrandInfo;
}
/**
 *
 * @export
 * @interface V2GetTicketsResponse
 */
export interface V2GetTicketsResponse {
  /**
   *
   * @type {Array<Ticket>}
   * @memberof V2GetTicketsResponse
   */
  tickets: Array<Ticket>;
}
/**
 *
 * @export
 * @interface V2GetUpdateSubscribeCouponsResponse
 */
export interface V2GetUpdateSubscribeCouponsResponse {
  /**
   *
   * @type {AllCoupons}
   * @memberof V2GetUpdateSubscribeCouponsResponse
   */
  coupons: AllCoupons;
}
/**
 *
 * @export
 * @interface V2GetUserAppContentSectionsResponse
 */
export interface V2GetUserAppContentSectionsResponse {
  /**
   *
   * @type {Array<AppContentSection>}
   * @memberof V2GetUserAppContentSectionsResponse
   */
  appContentSections: Array<AppContentSection>;
}
/**
 *
 * @export
 * @interface V2GetUserEntranceTokenResponse
 */
export interface V2GetUserEntranceTokenResponse {
  /**
   *
   * @type {string}
   * @memberof V2GetUserEntranceTokenResponse
   */
  userEntranceToken: string;
  /**
   *
   * @type {Array<SubscribeInfo>}
   * @memberof V2GetUserEntranceTokenResponse
   */
  subscribes: Array<SubscribeInfo>;
}
/**
 *
 * @export
 * @interface V2GetUserHomeAppContentsResponse
 */
export interface V2GetUserHomeAppContentsResponse {
  /**
   *
   * @type {Array<AppContent>}
   * @memberof V2GetUserHomeAppContentsResponse
   */
  appContents: Array<AppContent>;
}
/**
 *
 * @export
 * @interface V2GetUserPointSummaryResponse
 */
export interface V2GetUserPointSummaryResponse {
  /**
   *
   * @type {UserPointSummary}
   * @memberof V2GetUserPointSummaryResponse
   */
  userPointSummary: UserPointSummary;
}
/**
 *
 * @export
 * @interface V2GetUsualGymsResponse
 */
export interface V2GetUsualGymsResponse {
  /**
   *
   * @type {Array<GymCardInfo>}
   * @memberof V2GetUsualGymsResponse
   */
  gyms: Array<GymCardInfo>;
}
/**
 *
 * @export
 * @interface V2LoginRequest
 */
export interface V2LoginRequest {
  /**
   *
   * @type {string}
   * @memberof V2LoginRequest
   */
  userUniqueId: string;
  /**
   *
   * @type {string}
   * @memberof V2LoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface V2LoginResponse
 */
export interface V2LoginResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2LoginResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2LoginResponse
   */
  errorMsg: string;
  /**
   *
   * @type {string}
   * @memberof V2LoginResponse
   */
  token: string;
}
/**
 *
 * @export
 * @interface V2MakeSubscribeRequest
 */
export interface V2MakeSubscribeRequest {
  /**
   *
   * @type {number}
   * @memberof V2MakeSubscribeRequest
   */
  gymId: number;
  /**
   *
   * @type {number}
   * @memberof V2MakeSubscribeRequest
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof V2MakeSubscribeRequest
   */
  planId: number;
  /**
   *
   * @type {string}
   * @memberof V2MakeSubscribeRequest
   */
  paymentWay?: V2MakeSubscribeRequestPaymentWayEnum;
  /**
   *
   * @type {string}
   * @memberof V2MakeSubscribeRequest
   */
  startDatetime: string;
  /**
   *
   * @type {number}
   * @memberof V2MakeSubscribeRequest
   */
  couponId?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum V2MakeSubscribeRequestPaymentWayEnum {
  Payjp = 'Payjp',
  Paidy = 'Paidy',
}

/**
 *
 * @export
 * @interface V2MakeSubscribeResponse
 */
export interface V2MakeSubscribeResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2MakeSubscribeResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2MakeSubscribeResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface V2MakeTemporaryUseRequest
 */
export interface V2MakeTemporaryUseRequest {
  /**
   *
   * @type {number}
   * @memberof V2MakeTemporaryUseRequest
   */
  gymId: number;
  /**
   *
   * @type {number}
   * @memberof V2MakeTemporaryUseRequest
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof V2MakeTemporaryUseRequest
   */
  ticketCount: number;
  /**
   *
   * @type {string}
   * @memberof V2MakeTemporaryUseRequest
   */
  paymentWay?: V2MakeTemporaryUseRequestPaymentWayEnum;
  /**
   *
   * @type {number}
   * @memberof V2MakeTemporaryUseRequest
   */
  couponId?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum V2MakeTemporaryUseRequestPaymentWayEnum {
  Payjp = 'Payjp',
  Paidy = 'Paidy',
}

/**
 *
 * @export
 * @interface V2MakeTemporaryUseResponse
 */
export interface V2MakeTemporaryUseResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2MakeTemporaryUseResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2MakeTemporaryUseResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface V2NearGymRequest
 */
export interface V2NearGymRequest {
  /**
   *
   * @type {number}
   * @memberof V2NearGymRequest
   */
  longitude?: number;
  /**
   *
   * @type {number}
   * @memberof V2NearGymRequest
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof V2NearGymRequest
   */
  limit?: number;
}
/**
 *
 * @export
 * @interface V2NearGymResponse
 */
export interface V2NearGymResponse {
  /**
   *
   * @type {Array<GymCardInfo>}
   * @memberof V2NearGymResponse
   */
  gyms: Array<GymCardInfo>;
}
/**
 *
 * @export
 * @interface V2PhoneNumberSendCodeRequest
 */
export interface V2PhoneNumberSendCodeRequest {
  /**
   *
   * @type {string}
   * @memberof V2PhoneNumberSendCodeRequest
   */
  globalPhoneNumber: string;
}
/**
 *
 * @export
 * @interface V2PhoneNumberSendCodeResponse
 */
export interface V2PhoneNumberSendCodeResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2PhoneNumberSendCodeResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2PhoneNumberSendCodeResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface V2PhoneNumberVerifyRequest
 */
export interface V2PhoneNumberVerifyRequest {
  /**
   *
   * @type {string}
   * @memberof V2PhoneNumberVerifyRequest
   */
  globalPhoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof V2PhoneNumberVerifyRequest
   */
  code: string;
}
/**
 *
 * @export
 * @interface V2PhoneNumberVerifyResponse
 */
export interface V2PhoneNumberVerifyResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2PhoneNumberVerifyResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2PhoneNumberVerifyResponse
   */
  errorMsg: string;
  /**
   *
   * @type {string}
   * @memberof V2PhoneNumberVerifyResponse
   */
  token: string;
}
/**
 *
 * @export
 * @interface V2SearchGymRequest
 */
export interface V2SearchGymRequest {
  /**
   *
   * @type {string}
   * @memberof V2SearchGymRequest
   */
  searchString: string;
  /**
   *
   * @type {string}
   * @memberof V2SearchGymRequest
   */
  sortType: V2SearchGymRequestSortTypeEnum;
  /**
   *
   * @type {string}
   * @memberof V2SearchGymRequest
   */
  filterTicketType: V2SearchGymRequestFilterTicketTypeEnum;
  /**
   *
   * @type {Array<number>}
   * @memberof V2SearchGymRequest
   */
  filterEquipmentIds: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof V2SearchGymRequest
   */
  onlyDuringOpenHours: boolean;
  /**
   *
   * @type {number}
   * @memberof V2SearchGymRequest
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof V2SearchGymRequest
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof V2SearchGymRequest
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof V2SearchGymRequest
   */
  offset?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum V2SearchGymRequestSortTypeEnum {
  None = 'None',
  Near = 'Near',
  Vacant = 'Vacant',
}
/**
 * @export
 * @enum {string}
 */
export enum V2SearchGymRequestFilterTicketTypeEnum {
  None = 'None',
  Subscribe = 'Subscribe',
  TemporaryUse = 'TemporaryUse',
}

/**
 *
 * @export
 * @interface V2SearchGymResponse
 */
export interface V2SearchGymResponse {
  /**
   *
   * @type {Array<GymCardInfo>}
   * @memberof V2SearchGymResponse
   */
  gyms: Array<GymCardInfo>;
}
/**
 *
 * @export
 * @interface V2SetPasswordRequest
 */
export interface V2SetPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof V2SetPasswordRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface V2SetPasswordResponse
 */
export interface V2SetPasswordResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2SetPasswordResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2SetPasswordResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface V2SetPasswordStrictRequest
 */
export interface V2SetPasswordStrictRequest {
  /**
   *
   * @type {string}
   * @memberof V2SetPasswordStrictRequest
   */
  oldPassword: string;
  /**
   *
   * @type {string}
   * @memberof V2SetPasswordStrictRequest
   */
  newPassword: string;
}
/**
 *
 * @export
 * @interface V2SetPasswordStrictResponse
 */
export interface V2SetPasswordStrictResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2SetPasswordStrictResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2SetPasswordStrictResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface V2StopSubscribeRequest
 */
export interface V2StopSubscribeRequest {
  /**
   *
   * @type {number}
   * @memberof V2StopSubscribeRequest
   */
  subscribeId: number;
}
/**
 *
 * @export
 * @interface V2StopSubscribeResponse
 */
export interface V2StopSubscribeResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2StopSubscribeResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2StopSubscribeResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface V2SubscribeCancelResponse
 */
export interface V2SubscribeCancelResponse {
  /**
   *
   * @type {Subscribe}
   * @memberof V2SubscribeCancelResponse
   */
  subscribe: Subscribe;
}
/**
 *
 * @export
 * @interface V2UpdateMyScheduleRequest
 */
export interface V2UpdateMyScheduleRequest {
  /**
   *
   * @type {number}
   * @memberof V2UpdateMyScheduleRequest
   */
  dayOfWeek: number;
  /**
   *
   * @type {boolean}
   * @memberof V2UpdateMyScheduleRequest
   */
  enabled: boolean;
}
/**
 *
 * @export
 * @interface V2UpdateMySchedulesResponse
 */
export interface V2UpdateMySchedulesResponse {
  /**
   *
   * @type {number}
   * @memberof V2UpdateMySchedulesResponse
   */
  dayOfWeek: number;
  /**
   *
   * @type {string}
   * @memberof V2UpdateMySchedulesResponse
   */
  dayOfWeekLabel: string;
  /**
   *
   * @type {boolean}
   * @memberof V2UpdateMySchedulesResponse
   */
  enabled: boolean;
}
/**
 *
 * @export
 * @interface V2UpdateRequest
 */
export interface V2UpdateRequest {
  /**
   *
   * @type {string}
   * @memberof V2UpdateRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof V2UpdateRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof V2UpdateRequest
   */
  postalCode1: string;
  /**
   *
   * @type {string}
   * @memberof V2UpdateRequest
   */
  postalCode2: string;
  /**
   *
   * @type {string}
   * @memberof V2UpdateRequest
   */
  prefecture: string;
  /**
   *
   * @type {string}
   * @memberof V2UpdateRequest
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof V2UpdateRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof V2UpdateRequest
   */
  building?: string;
  /**
   *
   * @type {string}
   * @memberof V2UpdateRequest
   */
  email?: string;
}
/**
 *
 * @export
 * @interface V2UpdateResponse
 */
export interface V2UpdateResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2UpdateResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2UpdateResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface V2UpdateSmsVerificationRequest
 */
export interface V2UpdateSmsVerificationRequest {
  /**
   *
   * @type {string}
   * @memberof V2UpdateSmsVerificationRequest
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof V2UpdateSmsVerificationRequest
   */
  verificationCode: string;
}
/**
 *
 * @export
 * @interface V2UpdateSmsVerificationResponse
 */
export interface V2UpdateSmsVerificationResponse {
  /**
   *
   * @type {SmsVerification}
   * @memberof V2UpdateSmsVerificationResponse
   */
  smsVerification: SmsVerification;
}
/**
 *
 * @export
 * @interface V2UpdateSubscribeRequest
 */
export interface V2UpdateSubscribeRequest {
  /**
   *
   * @type {number}
   * @memberof V2UpdateSubscribeRequest
   */
  nextUpdateCouponId: number | null;
}
/**
 *
 * @export
 * @interface V2UpdateSubscribeResponse
 */
export interface V2UpdateSubscribeResponse {
  /**
   *
   * @type {UserUpdateSubscribe}
   * @memberof V2UpdateSubscribeResponse
   */
  subscribe: UserUpdateSubscribe;
}
/**
 *
 * @export
 * @interface V2UseShotRequest
 */
export interface V2UseShotRequest {
  /**
   *
   * @type {number}
   * @memberof V2UseShotRequest
   */
  shotId: number;
}
/**
 *
 * @export
 * @interface V2UseShotResponse
 */
export interface V2UseShotResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2UseShotResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2UseShotResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface V2UseTemporaryUseRequest
 */
export interface V2UseTemporaryUseRequest {
  /**
   *
   * @type {number}
   * @memberof V2UseTemporaryUseRequest
   */
  temporaryUseId: number;
}
/**
 *
 * @export
 * @interface V2UseTemporaryUseResponse
 */
export interface V2UseTemporaryUseResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2UseTemporaryUseResponse
   */
  result: boolean;
  /**
   *
   * @type {string}
   * @memberof V2UseTemporaryUseResponse
   */
  errorMsg: string;
}
/**
 *
 * @export
 * @interface V2WithdrawResponse
 */
export interface V2WithdrawResponse {
  /**
   *
   * @type {boolean}
   * @memberof V2WithdrawResponse
   */
  result: boolean;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AdminCreateAppContentRequest} adminCreateAppContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentCreateAppContent: async (
      adminCreateAppContentRequest: AdminCreateAppContentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminCreateAppContentRequest' is not null or undefined
      assertParamExists(
        'adminAppContentCreateAppContent',
        'adminCreateAppContentRequest',
        adminCreateAppContentRequest,
      );
      const localVarPath = `/v2/hq-admin/appContents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminCreateAppContentRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {AdminCreatePresignedUrlForAppContentRequest} adminCreatePresignedUrlForAppContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentCreatePresignedUrl: async (
      id: number,
      adminCreatePresignedUrlForAppContentRequest: AdminCreatePresignedUrlForAppContentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminAppContentCreatePresignedUrl', 'id', id);
      // verify required parameter 'adminCreatePresignedUrlForAppContentRequest' is not null or undefined
      assertParamExists(
        'adminAppContentCreatePresignedUrl',
        'adminCreatePresignedUrlForAppContentRequest',
        adminCreatePresignedUrlForAppContentRequest,
      );
      const localVarPath = `/v2/hq-admin/appContents/{id}/presignedUrl`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminCreatePresignedUrlForAppContentRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentGetAppContent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminAppContentGetAppContent', 'id', id);
      const localVarPath = `/v2/hq-admin/appContents/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentSectionGetAppContentSections: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/hq-admin/appContentSections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentSectionGetAppContentSectionsWithContents: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/hq-admin/appContentSections/contents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {AdminUpdateAppContentSectionContentsPriorityRequest} adminUpdateAppContentSectionContentsPriorityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentSectionUpdateAppContentSectionContentsPriority: async (
      id: number,
      adminUpdateAppContentSectionContentsPriorityRequest: AdminUpdateAppContentSectionContentsPriorityRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminAppContentSectionUpdateAppContentSectionContentsPriority', 'id', id);
      // verify required parameter 'adminUpdateAppContentSectionContentsPriorityRequest' is not null or undefined
      assertParamExists(
        'adminAppContentSectionUpdateAppContentSectionContentsPriority',
        'adminUpdateAppContentSectionContentsPriorityRequest',
        adminUpdateAppContentSectionContentsPriorityRequest,
      );
      const localVarPath = `/v2/hq-admin/appContentSections/{id}/contents/priority`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUpdateAppContentSectionContentsPriorityRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {AdminUpdateAppContentRequest} adminUpdateAppContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentUpdateAppContent: async (
      id: number,
      adminUpdateAppContentRequest: AdminUpdateAppContentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminAppContentUpdateAppContent', 'id', id);
      // verify required parameter 'adminUpdateAppContentRequest' is not null or undefined
      assertParamExists(
        'adminAppContentUpdateAppContent',
        'adminUpdateAppContentRequest',
        adminUpdateAppContentRequest,
      );
      const localVarPath = `/v2/hq-admin/appContents/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUpdateAppContentRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminCreateCampaignCodeRequest} adminCreateCampaignCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCampaignsAdminCreateCampaignCode: async (
      adminCreateCampaignCodeRequest: AdminCreateCampaignCodeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminCreateCampaignCodeRequest' is not null or undefined
      assertParamExists(
        'adminCampaignsAdminCreateCampaignCode',
        'adminCreateCampaignCodeRequest',
        adminCreateCampaignCodeRequest,
      );
      const localVarPath = `/v1/hq-admin/campaigns/create_campaign_code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminCreateCampaignCodeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminGetCampaignsRequest} adminGetCampaignsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCampaignsAdminGetCampaigns: async (
      adminGetCampaignsRequest: AdminGetCampaignsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminGetCampaignsRequest' is not null or undefined
      assertParamExists('adminCampaignsAdminGetCampaigns', 'adminGetCampaignsRequest', adminGetCampaignsRequest);
      const localVarPath = `/v1/hq-admin/campaigns/get_campaigns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminGetCampaignsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminUpdateCampaignRequest} adminUpdateCampaignRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCampaignsAdminUpdateCampaign: async (
      adminUpdateCampaignRequest: AdminUpdateCampaignRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminUpdateCampaignRequest' is not null or undefined
      assertParamExists('adminCampaignsAdminUpdateCampaign', 'adminUpdateCampaignRequest', adminUpdateCampaignRequest);
      const localVarPath = `/v1/hq-admin/campaigns/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUpdateCampaignRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/hq-admin/admins`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGymsGetAllBrands: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/hq-admin/gyms/brands`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGymsGetAllEquipments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/hq-admin/gyms/equipments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGymsGetAllGyms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/hq-admin/gyms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGymsGetAllOptionPlans: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/hq-admin/gyms/optionPlans`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGymsGetAllShotPlans: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/hq-admin/gyms/shotPlans`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLogin: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'loginRequest' is not null or undefined
      assertParamExists('adminLogin', 'loginRequest', loginRequest);
      const localVarPath = `/v2/hq-admin/admins/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/hq-admin/admins/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreatePartnerRequest} createPartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPartnersCreateNewPartner: async (
      createPartnerRequest: CreatePartnerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPartnerRequest' is not null or undefined
      assertParamExists('adminPartnersCreateNewPartner', 'createPartnerRequest', createPartnerRequest);
      const localVarPath = `/v1/hq-admin/partners/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPartnerRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeletePartnerRequest} deletePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPartnersDeletePartner: async (
      deletePartnerRequest: DeletePartnerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deletePartnerRequest' is not null or undefined
      assertParamExists('adminPartnersDeletePartner', 'deletePartnerRequest', deletePartnerRequest);
      const localVarPath = `/v1/hq-admin/partners/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(deletePartnerRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditPartnerRequest} editPartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPartnersEditPartner: async (
      editPartnerRequest: EditPartnerRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'editPartnerRequest' is not null or undefined
      assertParamExists('adminPartnersEditPartner', 'editPartnerRequest', editPartnerRequest);
      const localVarPath = `/v1/hq-admin/partners/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(editPartnerRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPartnersGetAllPartners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/hq-admin/partners`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPartnersGetPartnerInfo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminPartnersGetPartnerInfo', 'id', id);
      const localVarPath = `/v1/hq-admin/partners/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRefreshToken: async (
      refreshTokenRequest: RefreshTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'refreshTokenRequest' is not null or undefined
      assertParamExists('adminRefreshToken', 'refreshTokenRequest', refreshTokenRequest);
      const localVarPath = `/v2/hq-admin/admins/tokens/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {AdminCreatePresignedUrlForRewardRequest} adminCreatePresignedUrlForRewardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardCreatePresignedUrl: async (
      id: number,
      adminCreatePresignedUrlForRewardRequest: AdminCreatePresignedUrlForRewardRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminRewardCreatePresignedUrl', 'id', id);
      // verify required parameter 'adminCreatePresignedUrlForRewardRequest' is not null or undefined
      assertParamExists(
        'adminRewardCreatePresignedUrl',
        'adminCreatePresignedUrlForRewardRequest',
        adminCreatePresignedUrlForRewardRequest,
      );
      const localVarPath = `/v2/hq-admin/rewards/{id}/presigned-url`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminCreatePresignedUrlForRewardRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminCreateRewardRequest} adminCreateRewardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardCreateReward: async (
      adminCreateRewardRequest: AdminCreateRewardRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminCreateRewardRequest' is not null or undefined
      assertParamExists('adminRewardCreateReward', 'adminCreateRewardRequest', adminCreateRewardRequest);
      const localVarPath = `/v2/hq-admin/rewards`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminCreateRewardRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardGetReward: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminRewardGetReward', 'id', id);
      const localVarPath = `/v2/hq-admin/rewards/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} pageSize
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardGetRewards: async (
      pageSize: number,
      page: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('adminRewardGetRewards', 'pageSize', pageSize);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('adminRewardGetRewards', 'page', page);
      const localVarPath = `/v2/hq-admin/rewards`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardTagGetRewardTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/hq-admin/rewardTags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {AdminUpdateRewardRequest} adminUpdateRewardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardUpdateReward: async (
      id: number,
      adminUpdateRewardRequest: AdminUpdateRewardRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminRewardUpdateReward', 'id', id);
      // verify required parameter 'adminUpdateRewardRequest' is not null or undefined
      assertParamExists('adminRewardUpdateReward', 'adminUpdateRewardRequest', adminUpdateRewardRequest);
      const localVarPath = `/v2/hq-admin/rewards/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUpdateRewardRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreatePlanRequest} createPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSubscribePlansCreatePlan: async (
      createPlanRequest: CreatePlanRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPlanRequest' is not null or undefined
      assertParamExists('adminSubscribePlansCreatePlan', 'createPlanRequest', createPlanRequest);
      const localVarPath = `/v2/hq-admin/subscribePlans`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPlanRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} [isCampaignApplicable]
     * @param {boolean} [hasMinimumSubscriptionRenewalCount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSubscribePlansGetAllSubscribePlans: async (
      isCampaignApplicable?: boolean,
      hasMinimumSubscriptionRenewalCount?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/hq-admin/subscribePlans`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isCampaignApplicable !== undefined) {
        localVarQueryParameter['isCampaignApplicable'] = isCampaignApplicable;
      }

      if (hasMinimumSubscriptionRenewalCount !== undefined) {
        localVarQueryParameter['hasMinimumSubscriptionRenewalCount'] = hasMinimumSubscriptionRenewalCount;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSubscribePlansGetSubscribePlan: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminSubscribePlansGetSubscribePlan', 'id', id);
      const localVarPath = `/v2/hq-admin/subscribePlans/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {UpdatePlanRequest} updatePlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSubscribePlansUpdatePlan: async (
      id: number,
      updatePlanRequest: UpdatePlanRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminSubscribePlansUpdatePlan', 'id', id);
      // verify required parameter 'updatePlanRequest' is not null or undefined
      assertParamExists('adminSubscribePlansUpdatePlan', 'updatePlanRequest', updatePlanRequest);
      const localVarPath = `/v2/hq-admin/subscribePlans/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updatePlanRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {AddPointsRequest} addPointsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAddPoints: async (
      userId: number,
      addPointsRequest: AddPointsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('adminUserAddPoints', 'userId', userId);
      // verify required parameter 'addPointsRequest' is not null or undefined
      assertParamExists('adminUserAddPoints', 'addPointsRequest', addPointsRequest);
      const localVarPath = `/v2/hq-admin/users/{userId}/pointSummaries/addPoints`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(addPointsRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminGetSubscribeRequest} adminGetSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAdminGetSubscribe: async (
      adminGetSubscribeRequest: AdminGetSubscribeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminGetSubscribeRequest' is not null or undefined
      assertParamExists('adminUserAdminGetSubscribe', 'adminGetSubscribeRequest', adminGetSubscribeRequest);
      const localVarPath = `/v1/hq-admin/users/subscribe/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminGetSubscribeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminGetTempUseTicketRequest} adminGetTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAdminGetTempUseTickets: async (
      adminGetTempUseTicketRequest: AdminGetTempUseTicketRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminGetTempUseTicketRequest' is not null or undefined
      assertParamExists(
        'adminUserAdminGetTempUseTickets',
        'adminGetTempUseTicketRequest',
        adminGetTempUseTicketRequest,
      );
      const localVarPath = `/v1/hq-admin/users/temp_use/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminGetTempUseTicketRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminUserAddTempUseTicketRequest} adminUserAddTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAdminUserAddTempUseTicket: async (
      adminUserAddTempUseTicketRequest: AdminUserAddTempUseTicketRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminUserAddTempUseTicketRequest' is not null or undefined
      assertParamExists(
        'adminUserAdminUserAddTempUseTicket',
        'adminUserAddTempUseTicketRequest',
        adminUserAddTempUseTicketRequest,
      );
      const localVarPath = `/v1/hq-admin/users/temp_use/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUserAddTempUseTicketRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminUserDeleteTempUseTicketRequest} adminUserDeleteTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAdminUserDeleteTempUseTicket: async (
      adminUserDeleteTempUseTicketRequest: AdminUserDeleteTempUseTicketRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminUserDeleteTempUseTicketRequest' is not null or undefined
      assertParamExists(
        'adminUserAdminUserDeleteTempUseTicket',
        'adminUserDeleteTempUseTicketRequest',
        adminUserDeleteTempUseTicketRequest,
      );
      const localVarPath = `/v1/hq-admin/users/temp_use/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUserDeleteTempUseTicketRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {AdminUserUpdatePhoneNumberRequest} adminUserUpdatePhoneNumberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAdminUserUpdatePhoneNumber: async (
      id: string,
      adminUserUpdatePhoneNumberRequest: AdminUserUpdatePhoneNumberRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminUserAdminUserUpdatePhoneNumber', 'id', id);
      // verify required parameter 'adminUserUpdatePhoneNumberRequest' is not null or undefined
      assertParamExists(
        'adminUserAdminUserUpdatePhoneNumber',
        'adminUserUpdatePhoneNumberRequest',
        adminUserUpdatePhoneNumberRequest,
      );
      const localVarPath = `/v1/hq-admin/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUserUpdatePhoneNumberRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserBan: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminUserBan', 'id', id);
      const localVarPath = `/v2/hq-admin/users/{id}/ban`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {number} subscribeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserGetUpdatableSubscribePlans: async (
      userId: number,
      subscribeId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('adminUserGetUpdatableSubscribePlans', 'userId', userId);
      // verify required parameter 'subscribeId' is not null or undefined
      assertParamExists('adminUserGetUpdatableSubscribePlans', 'subscribeId', subscribeId);
      const localVarPath = `/v2/hq-admin/users/{userId}/subscribes/{subscribeId}/updatableSubscribePlans`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'subscribeId'}}`, encodeURIComponent(String(subscribeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserGetUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminUserGetUser', 'id', id);
      const localVarPath = `/v2/hq-admin/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2AdminSearchUsersRequest} v2AdminSearchUsersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserSearchUser: async (
      v2AdminSearchUsersRequest: V2AdminSearchUsersRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2AdminSearchUsersRequest' is not null or undefined
      assertParamExists('adminUserSearchUser', 'v2AdminSearchUsersRequest', v2AdminSearchUsersRequest);
      const localVarPath = `/v2/hq-admin/users/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2AdminSearchUsersRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {number} subscribeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserUnsubscribe: async (
      userId: number,
      subscribeId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('adminUserUnsubscribe', 'userId', userId);
      // verify required parameter 'subscribeId' is not null or undefined
      assertParamExists('adminUserUnsubscribe', 'subscribeId', subscribeId);
      const localVarPath = `/v2/hq-admin/users/{userId}/subscribes/{subscribeId}/cancel`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'subscribeId'}}`, encodeURIComponent(String(subscribeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {number} subscribeId
     * @param {UpdateSubscribeRequest} updateSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserUpdateSubscribe: async (
      userId: number,
      subscribeId: number,
      updateSubscribeRequest: UpdateSubscribeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('adminUserUpdateSubscribe', 'userId', userId);
      // verify required parameter 'subscribeId' is not null or undefined
      assertParamExists('adminUserUpdateSubscribe', 'subscribeId', subscribeId);
      // verify required parameter 'updateSubscribeRequest' is not null or undefined
      assertParamExists('adminUserUpdateSubscribe', 'updateSubscribeRequest', updateSubscribeRequest);
      const localVarPath = `/v2/hq-admin/users/{userId}/subscribes/{subscribeId}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'subscribeId'}}`, encodeURIComponent(String(subscribeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSubscribeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminCreateCampaignRequest} adminCreateCampaignRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminV2CampaignsAdminCreateCampaign: async (
      adminCreateCampaignRequest: AdminCreateCampaignRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminCreateCampaignRequest' is not null or undefined
      assertParamExists(
        'adminV2CampaignsAdminCreateCampaign',
        'adminCreateCampaignRequest',
        adminCreateCampaignRequest,
      );
      const localVarPath = `/v2/hq-admin/campaigns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminCreateCampaignRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminV2CampaignsAdminGetCampaign: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminV2CampaignsAdminGetCampaign', 'id', id);
      const localVarPath = `/v2/hq-admin/campaigns/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appContentGetHomeAppContents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/appContents/home`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appContentSectionGetAppContentSections: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/appContentSections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ChannelTalkBatchRequest} channelTalkBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchChannelTalk: async (
      channelTalkBatchRequest: ChannelTalkBatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'channelTalkBatchRequest' is not null or undefined
      assertParamExists('configExecBatchChannelTalk', 'channelTalkBatchRequest', channelTalkBatchRequest);
      const localVarPath = `/v1/hq-admin/configs/batch/channel-talk`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        channelTalkBatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchNotifyCouponExpirationDayBefore: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/hq-admin/configs/batch/notify-coupon-expiration-day-before`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchNotifyCouponExpirationFewDaysBefore: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/hq-admin/configs/batch/notify-coupon-expiration-few-days-before`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PresentPointBatchRequest} presentPointBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchPresentPoint: async (
      presentPointBatchRequest: PresentPointBatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'presentPointBatchRequest' is not null or undefined
      assertParamExists('configExecBatchPresentPoint', 'presentPointBatchRequest', presentPointBatchRequest);
      const localVarPath = `/v1/hq-admin/configs/batch/present-point`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        presentPointBatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchSale: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/hq-admin/configs/batch/sale`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SalesSummaryBatchRequest} salesSummaryBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchSalesSummary: async (
      salesSummaryBatchRequest: SalesSummaryBatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'salesSummaryBatchRequest' is not null or undefined
      assertParamExists('configExecBatchSalesSummary', 'salesSummaryBatchRequest', salesSummaryBatchRequest);
      const localVarPath = `/v1/hq-admin/configs/batch/sales-summary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        salesSummaryBatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StartSubscribeRequest} startSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchStartSubscribe: async (
      startSubscribeRequest: StartSubscribeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'startSubscribeRequest' is not null or undefined
      assertParamExists('configExecBatchStartSubscribe', 'startSubscribeRequest', startSubscribeRequest);
      const localVarPath = `/v1/hq-admin/configs/batch/start-subscribe`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(startSubscribeRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SubscribeBatchRequest} subscribeBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchSubscribe: async (
      subscribeBatchRequest: SubscribeBatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscribeBatchRequest' is not null or undefined
      assertParamExists('configExecBatchSubscribe', 'subscribeBatchRequest', subscribeBatchRequest);
      const localVarPath = `/v1/hq-admin/configs/batch/subscribe`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(subscribeBatchRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StoreCreateStaffRequest} storeCreateStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreCreateStaffs: async (
      storeCreateStaffRequest: StoreCreateStaffRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeCreateStaffRequest' is not null or undefined
      assertParamExists('configStoreCreateStaffs', 'storeCreateStaffRequest', storeCreateStaffRequest);
      const localVarPath = `/v1/store-admin/configs/staffs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeCreateStaffRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StoreDeleteStaffRequest} storeDeleteStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreDeleteStaff: async (
      storeDeleteStaffRequest: StoreDeleteStaffRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeDeleteStaffRequest' is not null or undefined
      assertParamExists('configStoreDeleteStaff', 'storeDeleteStaffRequest', storeDeleteStaffRequest);
      const localVarPath = `/v1/store-admin/configs/staffs/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeDeleteStaffRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StoreEditStaffRequest} storeEditStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreEditStaff: async (
      storeEditStaffRequest: StoreEditStaffRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeEditStaffRequest' is not null or undefined
      assertParamExists('configStoreEditStaff', 'storeEditStaffRequest', storeEditStaffRequest);
      const localVarPath = `/v1/store-admin/configs/staffs/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(storeEditStaffRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreGetGyms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/store-admin/configs/gyms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreGetRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/store-admin/configs/roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreGetStaffs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/store-admin/configs/staffs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {'subscribe' | 'temporaryUse'} purchaseType
     * @param {number} gymId
     * @param {number} [subscribePlanId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponV2GetActiveCouponsForPurchase: async (
      purchaseType: 'subscribe' | 'temporaryUse',
      gymId: number,
      subscribePlanId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'purchaseType' is not null or undefined
      assertParamExists('couponV2GetActiveCouponsForPurchase', 'purchaseType', purchaseType);
      // verify required parameter 'gymId' is not null or undefined
      assertParamExists('couponV2GetActiveCouponsForPurchase', 'gymId', gymId);
      const localVarPath = `/v2/native/coupons/getActiveCouponsForPurchase`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (purchaseType !== undefined) {
        localVarQueryParameter['purchaseType'] = purchaseType;
      }

      if (gymId !== undefined) {
        localVarQueryParameter['gymId'] = gymId;
      }

      if (subscribePlanId !== undefined) {
        localVarQueryParameter['subscribePlanId'] = subscribePlanId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetCouponByCodeRequest} getCouponByCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponV2GetCouponByCode: async (
      getCouponByCodeRequest: GetCouponByCodeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCouponByCodeRequest' is not null or undefined
      assertParamExists('couponV2GetCouponByCode', 'getCouponByCodeRequest', getCouponByCodeRequest);
      const localVarPath = `/v2/native/coupons/getCouponByCode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCouponByCodeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponV2GetCoupons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/coupons/getCoupons`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateGymRequest} createGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymCreateGym: async (
      createGymRequest: CreateGymRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createGymRequest' is not null or undefined
      assertParamExists('gymCreateGym', 'createGymRequest', createGymRequest);
      const localVarPath = `/v2/hq-admin/gyms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createGymRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} gymId
     * @param {CreateGymAnnouncementRequest} createGymAnnouncementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymCreateGymAnnouncement: async (
      gymId: number,
      createGymAnnouncementRequest: CreateGymAnnouncementRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gymId' is not null or undefined
      assertParamExists('gymCreateGymAnnouncement', 'gymId', gymId);
      // verify required parameter 'createGymAnnouncementRequest' is not null or undefined
      assertParamExists('gymCreateGymAnnouncement', 'createGymAnnouncementRequest', createGymAnnouncementRequest);
      const localVarPath = `/v2/hq-admin/gyms/{gymId}/announcements`.replace(
        `{${'gymId'}}`,
        encodeURIComponent(String(gymId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createGymAnnouncementRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CreatePresignedUrlRequest} createPresignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymCreatePresignedUrl: async (
      id: number,
      createPresignedUrlRequest: CreatePresignedUrlRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('gymCreatePresignedUrl', 'id', id);
      // verify required parameter 'createPresignedUrlRequest' is not null or undefined
      assertParamExists('gymCreatePresignedUrl', 'createPresignedUrlRequest', createPresignedUrlRequest);
      const localVarPath = `/v2/hq-admin/gyms/{id}/presigned-url`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPresignedUrlRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymGetGym: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('gymGetGym', 'id', id);
      const localVarPath = `/v2/hq-admin/gyms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} gymId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymGetGymAnnouncements: async (gymId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'gymId' is not null or undefined
      assertParamExists('gymGetGymAnnouncements', 'gymId', gymId);
      const localVarPath = `/v2/hq-admin/gyms/{gymId}/announcements`.replace(
        `{${'gymId'}}`,
        encodeURIComponent(String(gymId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {NearGymRequest} nearGymRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    gymNearGym: async (nearGymRequest: NearGymRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'nearGymRequest' is not null or undefined
      assertParamExists('gymNearGym', 'nearGymRequest', nearGymRequest);
      const localVarPath = `/v1/native/gyms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(nearGymRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SearchGymRequest} searchGymRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    gymSearchGym: async (
      searchGymRequest: SearchGymRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'searchGymRequest' is not null or undefined
      assertParamExists('gymSearchGym', 'searchGymRequest', searchGymRequest);
      const localVarPath = `/v1/native/gyms/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(searchGymRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {UpdateGymRequest} updateGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymUpdateGym: async (
      id: number,
      updateGymRequest: UpdateGymRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('gymUpdateGym', 'id', id);
      // verify required parameter 'updateGymRequest' is not null or undefined
      assertParamExists('gymUpdateGym', 'updateGymRequest', updateGymRequest);
      const localVarPath = `/v2/hq-admin/gyms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateGymRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} gymId
     * @param {number} announcementId
     * @param {UpdateGymAnnouncementRequest} updateGymAnnouncementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymUpdateGymAnnouncement: async (
      gymId: number,
      announcementId: number,
      updateGymAnnouncementRequest: UpdateGymAnnouncementRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gymId' is not null or undefined
      assertParamExists('gymUpdateGymAnnouncement', 'gymId', gymId);
      // verify required parameter 'announcementId' is not null or undefined
      assertParamExists('gymUpdateGymAnnouncement', 'announcementId', announcementId);
      // verify required parameter 'updateGymAnnouncementRequest' is not null or undefined
      assertParamExists('gymUpdateGymAnnouncement', 'updateGymAnnouncementRequest', updateGymAnnouncementRequest);
      const localVarPath = `/v2/hq-admin/gyms/{gymId}/announcements/{announcementId}`
        .replace(`{${'gymId'}}`, encodeURIComponent(String(gymId)))
        .replace(`{${'announcementId'}}`, encodeURIComponent(String(announcementId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateGymAnnouncementRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2GetAllEquipments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/gyms/equipments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {DayOfWeek} dayOfWeek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2GetCongestionTimeline: async (
      id: number,
      dayOfWeek: DayOfWeek,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('gymV2GetCongestionTimeline', 'id', id);
      // verify required parameter 'dayOfWeek' is not null or undefined
      assertParamExists('gymV2GetCongestionTimeline', 'dayOfWeek', dayOfWeek);
      const localVarPath = `/v2/native/gyms/{id}/congestionTimeline/{dayOfWeek}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'dayOfWeek'}}`, encodeURIComponent(String(dayOfWeek)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2GetGymDetail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('gymV2GetGymDetail', 'id', id);
      const localVarPath = `/v2/native/gyms/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} planId
     * @param {number} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2GetSubscribePlanAppliedCouponPrice: async (
      id: number,
      planId: number,
      couponId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('gymV2GetSubscribePlanAppliedCouponPrice', 'id', id);
      // verify required parameter 'planId' is not null or undefined
      assertParamExists('gymV2GetSubscribePlanAppliedCouponPrice', 'planId', planId);
      // verify required parameter 'couponId' is not null or undefined
      assertParamExists('gymV2GetSubscribePlanAppliedCouponPrice', 'couponId', couponId);
      const localVarPath = `/v2/native/gyms/{id}/subscribePlans/{planId}/price`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'planId'}}`, encodeURIComponent(String(planId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (couponId !== undefined) {
        localVarQueryParameter['couponId'] = couponId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2GetTemporaryUseAppliedCouponPrice: async (
      id: number,
      couponId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('gymV2GetTemporaryUseAppliedCouponPrice', 'id', id);
      // verify required parameter 'couponId' is not null or undefined
      assertParamExists('gymV2GetTemporaryUseAppliedCouponPrice', 'couponId', couponId);
      const localVarPath = `/v2/native/gyms/{id}/temporaryUse/price`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (couponId !== undefined) {
        localVarQueryParameter['couponId'] = couponId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2NearGymRequest} v2NearGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2NearGym: async (
      v2NearGymRequest: V2NearGymRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2NearGymRequest' is not null or undefined
      assertParamExists('gymV2NearGym', 'v2NearGymRequest', v2NearGymRequest);
      const localVarPath = `/v2/native/gyms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(v2NearGymRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2SearchGymRequest} v2SearchGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2SearchGym: async (
      v2SearchGymRequest: V2SearchGymRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2SearchGymRequest' is not null or undefined
      assertParamExists('gymV2SearchGym', 'v2SearchGymRequest', v2SearchGymRequest);
      const localVarPath = `/v2/native/gyms/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(v2SearchGymRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/healthz`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageGetAdminMessages: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('messageGetAdminMessages', 'id', id);
      const localVarPath = `/v1/hq-admin/messages/user/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminMessagesUsersRequest} adminMessagesUsersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageGetAdminMessagesUsers: async (
      adminMessagesUsersRequest: AdminMessagesUsersRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminMessagesUsersRequest' is not null or undefined
      assertParamExists('messageGetAdminMessagesUsers', 'adminMessagesUsersRequest', adminMessagesUsersRequest);
      const localVarPath = `/v1/hq-admin/messages/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminMessagesUsersRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    messageGetMessages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/native/messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    messageGetUserMessageOfficerList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/native/messages/officers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReadMessagesRequest} readMessagesRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    messageReadMessages: async (
      readMessagesRequest: ReadMessagesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'readMessagesRequest' is not null or undefined
      assertParamExists('messageReadMessages', 'readMessagesRequest', readMessagesRequest);
      const localVarPath = `/v1/native/messages/read`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(readMessagesRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AdminSendMessageRequest} adminSendMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageUserSendAdminMessage: async (
      adminSendMessageRequest: AdminSendMessageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminSendMessageRequest' is not null or undefined
      assertParamExists('messageUserSendAdminMessage', 'adminSendMessageRequest', adminSendMessageRequest);
      const localVarPath = `/v1/hq-admin/messages/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminSendMessageRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserSendMessageRequest} userSendMessageRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    messageUserSendMessage: async (
      userSendMessageRequest: UserSendMessageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userSendMessageRequest' is not null or undefined
      assertParamExists('messageUserSendMessage', 'userSendMessageRequest', userSendMessageRequest);
      const localVarPath = `/v1/native/messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userSendMessageRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FindNewsRequest} findNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageV2FindNews: async (
      findNewsRequest: FindNewsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'findNewsRequest' is not null or undefined
      assertParamExists('messageV2FindNews', 'findNewsRequest', findNewsRequest);
      const localVarPath = `/v2/native/messages/news/id`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(findNewsRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {NewsGetRequest} newsGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageV2GetNews: async (
      newsGetRequest: NewsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'newsGetRequest' is not null or undefined
      assertParamExists('messageV2GetNews', 'newsGetRequest', newsGetRequest);
      const localVarPath = `/v2/native/messages/news`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(newsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ReadNewsRequest} readNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageV2ReadNews: async (
      readNewsRequest: ReadNewsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'readNewsRequest' is not null or undefined
      assertParamExists('messageV2ReadNews', 'readNewsRequest', readNewsRequest);
      const localVarPath = `/v2/native/messages/news/read`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(readNewsRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {NativeChargeLogGetRequest} nativeChargeLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nativeLogNativeGetChargeLogs: async (
      nativeChargeLogGetRequest: NativeChargeLogGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nativeChargeLogGetRequest' is not null or undefined
      assertParamExists('nativeLogNativeGetChargeLogs', 'nativeChargeLogGetRequest', nativeChargeLogGetRequest);
      const localVarPath = `/v1/native/logs/charge`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nativeChargeLogGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    nativeLogNativeGetGateLogStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/native/logs/gate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {NativeGateLogGetRequest} nativeGateLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nativeLogNativeGetGateLogs: async (
      nativeGateLogGetRequest: NativeGateLogGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nativeGateLogGetRequest' is not null or undefined
      assertParamExists('nativeLogNativeGetGateLogs', 'nativeGateLogGetRequest', nativeGateLogGetRequest);
      const localVarPath = `/v1/native/logs/gate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nativeGateLogGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SearchNewsRequest} searchNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsAdminGetUserByFilter: async (
      searchNewsRequest: SearchNewsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'searchNewsRequest' is not null or undefined
      assertParamExists('newsAdminGetUserByFilter', 'searchNewsRequest', searchNewsRequest);
      const localVarPath = `/v1/hq-admin/news/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(searchNewsRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateNewsRequest} createNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsCreateNews: async (
      createNewsRequest: CreateNewsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createNewsRequest' is not null or undefined
      assertParamExists('newsCreateNews', 'createNewsRequest', createNewsRequest);
      const localVarPath = `/v1/hq-admin/news/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createNewsRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {NewsGetRequest} newsGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsGetNews: async (newsGetRequest: NewsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'newsGetRequest' is not null or undefined
      assertParamExists('newsGetNews', 'newsGetRequest', newsGetRequest);
      const localVarPath = `/v1/hq-admin/news/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(newsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreatePaidyRequest} createPaidyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseCreatePaidy: async (
      createPaidyRequest: CreatePaidyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPaidyRequest' is not null or undefined
      assertParamExists('purchaseCreatePaidy', 'createPaidyRequest', createPaidyRequest);
      const localVarPath = `/v1/native/purchases/paidy`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPaidyRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreatePayjpRequest} createPayjpRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseCreatePayjp: async (
      createPayjpRequest: CreatePayjpRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPayjpRequest' is not null or undefined
      assertParamExists('purchaseCreatePayjp', 'createPayjpRequest', createPayjpRequest);
      const localVarPath = `/v1/native/purchases/payjp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPayjpRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseDeletePaidy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/native/purchases/paidy/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseDeletePayjp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/native/purchases/payjp/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseGetPaymentWays: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/native/purchases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchasePaidyHTML: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/native/purchases/paidy`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseV2GetPayjpStaticPage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/purchases/payjp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2MakeSubscribeRequest} v2MakeSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseV2MakeSubscribe: async (
      v2MakeSubscribeRequest: V2MakeSubscribeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2MakeSubscribeRequest' is not null or undefined
      assertParamExists('purchaseV2MakeSubscribe', 'v2MakeSubscribeRequest', v2MakeSubscribeRequest);
      const localVarPath = `/v2/native/purchases/subscribe`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2MakeSubscribeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2MakeTemporaryUseRequest} v2MakeTemporaryUseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseV2MakeTemporaryUse: async (
      v2MakeTemporaryUseRequest: V2MakeTemporaryUseRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2MakeTemporaryUseRequest' is not null or undefined
      assertParamExists('purchaseV2MakeTemporaryUse', 'v2MakeTemporaryUseRequest', v2MakeTemporaryUseRequest);
      const localVarPath = `/v2/native/purchases/temporary_use`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2MakeTemporaryUseRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {FromEntranceRequest} fromEntranceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qRFromEntrance: async (
      fromEntranceRequest: FromEntranceRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fromEntranceRequest' is not null or undefined
      assertParamExists('qRFromEntrance', 'fromEntranceRequest', fromEntranceRequest);
      const localVarPath = `/v1/entrance/qrs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fromEntranceRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rewardExchangeReward: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('rewardExchangeReward', 'id', id);
      const localVarPath = `/v2/native/rewards/{id}/exchange`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rewardGetReward: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('rewardGetReward', 'id', id);
      const localVarPath = `/v2/native/rewards/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2GetRewardsRequest} v2GetRewardsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rewardGetRewards: async (
      v2GetRewardsRequest: V2GetRewardsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2GetRewardsRequest' is not null or undefined
      assertParamExists('rewardGetRewards', 'v2GetRewardsRequest', v2GetRewardsRequest);
      const localVarPath = `/v2/native/rewards/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(v2GetRewardsRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2GetRewardSectionsRequest} v2GetRewardSectionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rewardSectionGetRewardSections: async (
      v2GetRewardSectionsRequest: V2GetRewardSectionsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2GetRewardSectionsRequest' is not null or undefined
      assertParamExists('rewardSectionGetRewardSections', 'v2GetRewardSectionsRequest', v2GetRewardSectionsRequest);
      const localVarPath = `/v2/native/rewardSections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2GetRewardSectionsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} gymId
     * @param {number} limit
     * @param {number} offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesSummaryGetSalesSummaries: async (
      gymId: number,
      limit: number,
      offset: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gymId' is not null or undefined
      assertParamExists('salesSummaryGetSalesSummaries', 'gymId', gymId);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('salesSummaryGetSalesSummaries', 'limit', limit);
      // verify required parameter 'offset' is not null or undefined
      assertParamExists('salesSummaryGetSalesSummaries', 'offset', offset);
      const localVarPath = `/v2/store-admin/salesSummaries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (gymId !== undefined) {
        localVarQueryParameter['gymId'] = gymId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateSmsVerificationRequest} createSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsVerificationCreateSmsVerification: async (
      createSmsVerificationRequest: CreateSmsVerificationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSmsVerificationRequest' is not null or undefined
      assertParamExists(
        'smsVerificationCreateSmsVerification',
        'createSmsVerificationRequest',
        createSmsVerificationRequest,
      );
      const localVarPath = `/v2/native/smsVerifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSmsVerificationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateSmsVerificationRequest} updateSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsVerificationUpdateSmsVerification: async (
      updateSmsVerificationRequest: UpdateSmsVerificationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateSmsVerificationRequest' is not null or undefined
      assertParamExists(
        'smsVerificationUpdateSmsVerification',
        'updateSmsVerificationRequest',
        updateSmsVerificationRequest,
      );
      const localVarPath = `/v2/native/smsVerifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSmsVerificationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffGetAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/store-admin/staffs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StaffGymListRequest} staffGymListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffGetStaffGymList: async (
      staffGymListRequest: StaffGymListRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffGymListRequest' is not null or undefined
      assertParamExists('staffGetStaffGymList', 'staffGymListRequest', staffGymListRequest);
      const localVarPath = `/v1/store-admin/staffs/gyms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(staffGymListRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffLogin: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'loginRequest' is not null or undefined
      assertParamExists('staffLogin', 'loginRequest', loginRequest);
      const localVarPath = `/v2/store-admin/staffs/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/store-admin/staffs/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffRefreshToken: async (
      refreshTokenRequest: RefreshTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'refreshTokenRequest' is not null or undefined
      assertParamExists('staffRefreshToken', 'refreshTokenRequest', refreshTokenRequest);
      const localVarPath = `/v2/store-admin/staffs/tokens/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeGymStoreGetGymInfos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/store-admin/gyms/info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeGymStoreGetGymStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/store-admin/gyms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StoreGateLogGetRequest} storeGateLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeLogStoreGetGateLogs: async (
      storeGateLogGetRequest: StoreGateLogGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeGateLogGetRequest' is not null or undefined
      assertParamExists('storeLogStoreGetGateLogs', 'storeGateLogGetRequest', storeGateLogGetRequest);
      const localVarPath = `/v1/store-admin/logs/gate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeGateLogGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StorePaymentLogGetRequest} storePaymentLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeLogStoreGetPurchaseLogs: async (
      storePaymentLogGetRequest: StorePaymentLogGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'storePaymentLogGetRequest' is not null or undefined
      assertParamExists('storeLogStoreGetPurchaseLogs', 'storePaymentLogGetRequest', storePaymentLogGetRequest);
      const localVarPath = `/v1/store-admin/logs/payment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storePaymentLogGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} gymId
     * @param {StaffUserMessagesByGymRequest} staffUserMessagesByGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeMessageGetStaffMessageByGym: async (
      gymId: string,
      staffUserMessagesByGymRequest: StaffUserMessagesByGymRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gymId' is not null or undefined
      assertParamExists('storeMessageGetStaffMessageByGym', 'gymId', gymId);
      // verify required parameter 'staffUserMessagesByGymRequest' is not null or undefined
      assertParamExists(
        'storeMessageGetStaffMessageByGym',
        'staffUserMessagesByGymRequest',
        staffUserMessagesByGymRequest,
      );
      const localVarPath = `/v1/store-admin/messages/users/{gymId}`.replace(
        `{${'gymId'}}`,
        encodeURIComponent(String(gymId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        staffUserMessagesByGymRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StaffGetMessagesRequest} staffGetMessagesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeMessageGetStaffMessages: async (
      staffGetMessagesRequest: StaffGetMessagesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffGetMessagesRequest' is not null or undefined
      assertParamExists('storeMessageGetStaffMessages', 'staffGetMessagesRequest', staffGetMessagesRequest);
      const localVarPath = `/v1/store-admin/messages/user/gym/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        staffGetMessagesRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StaffReadMessageRequest} staffReadMessageRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    storeMessageReadMessage: async (
      staffReadMessageRequest: StaffReadMessageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffReadMessageRequest' is not null or undefined
      assertParamExists('storeMessageReadMessage', 'staffReadMessageRequest', staffReadMessageRequest);
      const localVarPath = `/v1/store-admin/messages/user/gym/readMessage`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        staffReadMessageRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StaffChangeMessageReadRequest} staffChangeMessageReadRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    storeMessageStaffChangeMessageRead: async (
      staffChangeMessageReadRequest: StaffChangeMessageReadRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffChangeMessageReadRequest' is not null or undefined
      assertParamExists(
        'storeMessageStaffChangeMessageRead',
        'staffChangeMessageReadRequest',
        staffChangeMessageReadRequest,
      );
      const localVarPath = `/v1/store-admin/messages/user/gym/changeRead`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        staffChangeMessageReadRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} gymId
     * @param {UserSendStaffMessageRequest} userSendStaffMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeMessageUserSendStaffMessage: async (
      gymId: string,
      userSendStaffMessageRequest: UserSendStaffMessageRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'gymId' is not null or undefined
      assertParamExists('storeMessageUserSendStaffMessage', 'gymId', gymId);
      // verify required parameter 'userSendStaffMessageRequest' is not null or undefined
      assertParamExists('storeMessageUserSendStaffMessage', 'userSendStaffMessageRequest', userSendStaffMessageRequest);
      const localVarPath = `/v1/store-admin/messages/user/{gymId}`.replace(
        `{${'gymId'}}`,
        encodeURIComponent(String(gymId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userSendStaffMessageRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StaffGetSubscribeRequest} staffGetSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeUserStoreGetSubscribe: async (
      staffGetSubscribeRequest: StaffGetSubscribeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffGetSubscribeRequest' is not null or undefined
      assertParamExists('storeUserStoreGetSubscribe', 'staffGetSubscribeRequest', staffGetSubscribeRequest);
      const localVarPath = `/v1/store-admin/users/subscribe/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        staffGetSubscribeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StaffGetTempUseTicketRequest} staffGetTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeUserStoreGetTempUseTickets: async (
      staffGetTempUseTicketRequest: StaffGetTempUseTicketRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffGetTempUseTicketRequest' is not null or undefined
      assertParamExists(
        'storeUserStoreGetTempUseTickets',
        'staffGetTempUseTicketRequest',
        staffGetTempUseTicketRequest,
      );
      const localVarPath = `/v1/store-admin/users/temp_use/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        staffGetTempUseTicketRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StoreGetUserFilterRequest} storeGetUserFilterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeUserStoreGetUserByFilter: async (
      storeGetUserFilterRequest: StoreGetUserFilterRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'storeGetUserFilterRequest' is not null or undefined
      assertParamExists('storeUserStoreGetUserByFilter', 'storeGetUserFilterRequest', storeGetUserFilterRequest);
      const localVarPath = `/v1/store-admin/users/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        storeGetUserFilterRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StaffUserAddTempUseTicketRequest} staffUserAddTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeUserStoreUserAddTempUseTicket: async (
      staffUserAddTempUseTicketRequest: StaffUserAddTempUseTicketRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'staffUserAddTempUseTicketRequest' is not null or undefined
      assertParamExists(
        'storeUserStoreUserAddTempUseTicket',
        'staffUserAddTempUseTicketRequest',
        staffUserAddTempUseTicketRequest,
      );
      const localVarPath = `/v1/store-admin/users/temp_use/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        staffUserAddTempUseTicketRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscribeV2CancelUnsubscribe: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('subscribeV2CancelUnsubscribe', 'id', id);
      const localVarPath = `/v2/native/subscribes/{id}/cancel`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscribeV2Unsubscribe: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('subscribeV2Unsubscribe', 'id', id);
      const localVarPath = `/v2/native/subscribes/{id}/cancel`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2CancelStopSubscribeRequest} v2CancelStopSubscribeRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2CancelStopSubscribe: async (
      v2CancelStopSubscribeRequest: V2CancelStopSubscribeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2CancelStopSubscribeRequest' is not null or undefined
      assertParamExists('supplyV2CancelStopSubscribe', 'v2CancelStopSubscribeRequest', v2CancelStopSubscribeRequest);
      const localVarPath = `/v2/native/supplys/subscribe/stop/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2CancelStopSubscribeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2GetPictures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/supplys/pictures`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2GetShots: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/supplys/shot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2GetTemporaryUses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/supplys/temporary_use`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2GetUserEntranceToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/supplys/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2StopSubscribeRequest} v2StopSubscribeRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2StopSubscribe: async (
      v2StopSubscribeRequest: V2StopSubscribeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2StopSubscribeRequest' is not null or undefined
      assertParamExists('supplyV2StopSubscribe', 'v2StopSubscribeRequest', v2StopSubscribeRequest);
      const localVarPath = `/v2/native/supplys/subscribe/stop`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2StopSubscribeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2UseShotRequest} v2UseShotRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2UseShot: async (
      v2UseShotRequest: V2UseShotRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2UseShotRequest' is not null or undefined
      assertParamExists('supplyV2UseShot', 'v2UseShotRequest', v2UseShotRequest);
      const localVarPath = `/v2/native/supplys/shot/use`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(v2UseShotRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2UseTemporaryUseRequest} v2UseTemporaryUseRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2UseTemporaryUse: async (
      v2UseTemporaryUseRequest: V2UseTemporaryUseRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2UseTemporaryUseRequest' is not null or undefined
      assertParamExists('supplyV2UseTemporaryUse', 'v2UseTemporaryUseRequest', v2UseTemporaryUseRequest);
      const localVarPath = `/v2/native/supplys/temporary_use/use`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2UseTemporaryUseRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} surveyId
     * @param {CreateSurveyQuestionResponsesRequest} createSurveyQuestionResponsesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    surveyCreateSurveyQuestionResponses: async (
      surveyId: string,
      createSurveyQuestionResponsesRequest: CreateSurveyQuestionResponsesRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'surveyId' is not null or undefined
      assertParamExists('surveyCreateSurveyQuestionResponses', 'surveyId', surveyId);
      // verify required parameter 'createSurveyQuestionResponsesRequest' is not null or undefined
      assertParamExists(
        'surveyCreateSurveyQuestionResponses',
        'createSurveyQuestionResponsesRequest',
        createSurveyQuestionResponsesRequest,
      );
      const localVarPath = `/v2/native/surveys/{surveyId}/surveyQuestionResponses`.replace(
        `{${'surveyId'}}`,
        encodeURIComponent(String(surveyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSurveyQuestionResponsesRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} surveyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    surveyGetSurvey: async (surveyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'surveyId' is not null or undefined
      assertParamExists('surveyGetSurvey', 'surveyId', surveyId);
      const localVarPath = `/v2/native/surveys/{surveyId}`.replace(
        `{${'surveyId'}}`,
        encodeURIComponent(String(surveyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetAddressByPostalCodeRequest} getAddressByPostalCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetAddressByPostalCode: async (
      getAddressByPostalCodeRequest: GetAddressByPostalCodeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getAddressByPostalCodeRequest' is not null or undefined
      assertParamExists('userGetAddressByPostalCode', 'getAddressByPostalCodeRequest', getAddressByPostalCodeRequest);
      const localVarPath = `/v1/native/users/address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getAddressByPostalCodeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/native/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateNotificationTokenRequest} updateNotificationTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUpdateNotificationToken: async (
      updateNotificationTokenRequest: UpdateNotificationTokenRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateNotificationTokenRequest' is not null or undefined
      assertParamExists(
        'userUpdateNotificationToken',
        'updateNotificationTokenRequest',
        updateNotificationTokenRequest,
      );
      const localVarPath = `/v1/native/users/notification_token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNotificationTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2CreateSmsVerificationRequest} v2CreateSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2CreateSmsVerification: async (
      v2CreateSmsVerificationRequest: V2CreateSmsVerificationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2CreateSmsVerificationRequest' is not null or undefined
      assertParamExists(
        'userV2CreateSmsVerification',
        'v2CreateSmsVerificationRequest',
        v2CreateSmsVerificationRequest,
      );
      const localVarPath = `/v2/native/users/me/smsVerifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2CreateSmsVerificationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetActiveSubscribe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/users/me/subscribes/active`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetAppContentSections: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/users/me/appContentSections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetFriendInvitation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/users/me/friendInvitations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetHomeAppContents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/users/me/appContents/home`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetMySchedules: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/users/me/mySchedules`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} pageSize
     * @param {string} pageToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetPointHistories: async (
      pageSize: number,
      pageToken: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('userV2GetPointHistories', 'pageSize', pageSize);
      // verify required parameter 'pageToken' is not null or undefined
      assertParamExists('userV2GetPointHistories', 'pageToken', pageToken);
      const localVarPath = `/v2/native/users/me/pointHistories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetPointSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/users/me/pointSummary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetTickets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/users/me/tickets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetUpdateSubscribeCoupons: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userV2GetUpdateSubscribeCoupons', 'id', id);
      const localVarPath = `/v2/native/users/me/subscribes/{id}/coupons`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetUsualGyms: async (limit: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('userV2GetUsualGyms', 'limit', limit);
      const localVarPath = `/v2/native/users/me/usualGyms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2LoginRequest} v2LoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2Login: async (v2LoginRequest: V2LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'v2LoginRequest' is not null or undefined
      assertParamExists('userV2Login', 'v2LoginRequest', v2LoginRequest);
      const localVarPath = `/v2/native/users/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(v2LoginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2PhoneNumberSendCodeRequest} v2PhoneNumberSendCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2PhoneNumberSendCode: async (
      v2PhoneNumberSendCodeRequest: V2PhoneNumberSendCodeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2PhoneNumberSendCodeRequest' is not null or undefined
      assertParamExists('userV2PhoneNumberSendCode', 'v2PhoneNumberSendCodeRequest', v2PhoneNumberSendCodeRequest);
      const localVarPath = `/v2/native/users/phone_number/send_code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2PhoneNumberSendCodeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2PhoneNumberVerifyRequest} v2PhoneNumberVerifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2PhoneNumberVerify: async (
      v2PhoneNumberVerifyRequest: V2PhoneNumberVerifyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2PhoneNumberVerifyRequest' is not null or undefined
      assertParamExists('userV2PhoneNumberVerify', 'v2PhoneNumberVerifyRequest', v2PhoneNumberVerifyRequest);
      const localVarPath = `/v2/native/users/phone_number/verify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2PhoneNumberVerifyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2SetPasswordRequest} v2SetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2SetPassword: async (
      v2SetPasswordRequest: V2SetPasswordRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2SetPasswordRequest' is not null or undefined
      assertParamExists('userV2SetPassword', 'v2SetPasswordRequest', v2SetPasswordRequest);
      const localVarPath = `/v2/native/users/password/set`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(v2SetPasswordRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2SetPasswordStrictRequest} v2SetPasswordStrictRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2SetPasswordStrict: async (
      v2SetPasswordStrictRequest: V2SetPasswordStrictRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2SetPasswordStrictRequest' is not null or undefined
      assertParamExists('userV2SetPasswordStrict', 'v2SetPasswordStrictRequest', v2SetPasswordStrictRequest);
      const localVarPath = `/v2/native/users/new_password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2SetPasswordStrictRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2CreateRequest} v2CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2SignUp: async (v2CreateRequest: V2CreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'v2CreateRequest' is not null or undefined
      assertParamExists('userV2SignUp', 'v2CreateRequest', v2CreateRequest);
      const localVarPath = `/v2/native/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(v2CreateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2UpdateRequest} v2UpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2Update: async (v2UpdateRequest: V2UpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'v2UpdateRequest' is not null or undefined
      assertParamExists('userV2Update', 'v2UpdateRequest', v2UpdateRequest);
      const localVarPath = `/v2/native/users/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(v2UpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2UpdateMyScheduleRequest} v2UpdateMyScheduleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2UpdateMySchedule: async (
      v2UpdateMyScheduleRequest: V2UpdateMyScheduleRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2UpdateMyScheduleRequest' is not null or undefined
      assertParamExists('userV2UpdateMySchedule', 'v2UpdateMyScheduleRequest', v2UpdateMyScheduleRequest);
      const localVarPath = `/v2/native/users/me/mySchedules`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2UpdateMyScheduleRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {V2UpdateSmsVerificationRequest} v2UpdateSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2UpdateSmsVerification: async (
      v2UpdateSmsVerificationRequest: V2UpdateSmsVerificationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'v2UpdateSmsVerificationRequest' is not null or undefined
      assertParamExists(
        'userV2UpdateSmsVerification',
        'v2UpdateSmsVerificationRequest',
        v2UpdateSmsVerificationRequest,
      );
      const localVarPath = `/v2/native/users/me/smsVerifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2UpdateSmsVerificationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {V2UpdateSubscribeRequest} v2UpdateSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2UpdateUserSubscribes: async (
      id: number,
      v2UpdateSubscribeRequest: V2UpdateSubscribeRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userV2UpdateUserSubscribes', 'id', id);
      // verify required parameter 'v2UpdateSubscribeRequest' is not null or undefined
      assertParamExists('userV2UpdateUserSubscribes', 'v2UpdateSubscribeRequest', v2UpdateSubscribeRequest);
      const localVarPath = `/v2/native/users/me/subscribes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        v2UpdateSubscribeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2Withdraw: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2/native/users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AdminCreateAppContentRequest} adminCreateAppContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAppContentCreateAppContent(
      adminCreateAppContentRequest: AdminCreateAppContentRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateAppContentResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminAppContentCreateAppContent(
        adminCreateAppContentRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {AdminCreatePresignedUrlForAppContentRequest} adminCreatePresignedUrlForAppContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAppContentCreatePresignedUrl(
      id: number,
      adminCreatePresignedUrlForAppContentRequest: AdminCreatePresignedUrlForAppContentRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreatePresignedUrlForAppContentResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminAppContentCreatePresignedUrl(
        id,
        adminCreatePresignedUrlForAppContentRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAppContentGetAppContent(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAppContentResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminAppContentGetAppContent(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAppContentSectionGetAppContentSections(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAppContentSectionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminAppContentSectionGetAppContentSections(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAppContentSectionGetAppContentSectionsWithContents(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAppContentSectionsWithContentsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminAppContentSectionGetAppContentSectionsWithContents(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {AdminUpdateAppContentSectionContentsPriorityRequest} adminUpdateAppContentSectionContentsPriorityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAppContentSectionUpdateAppContentSectionContentsPriority(
      id: number,
      adminUpdateAppContentSectionContentsPriorityRequest: AdminUpdateAppContentSectionContentsPriorityRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpdateAppContentSectionContentsPriorityResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminAppContentSectionUpdateAppContentSectionContentsPriority(
          id,
          adminUpdateAppContentSectionContentsPriorityRequest,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {AdminUpdateAppContentRequest} adminUpdateAppContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminAppContentUpdateAppContent(
      id: number,
      adminUpdateAppContentRequest: AdminUpdateAppContentRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpdateAppContentResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminAppContentUpdateAppContent(
        id,
        adminUpdateAppContentRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminCreateCampaignCodeRequest} adminCreateCampaignCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCampaignsAdminCreateCampaignCode(
      adminCreateCampaignCodeRequest: AdminCreateCampaignCodeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateCampaignCodeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsAdminCreateCampaignCode(
        adminCreateCampaignCodeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminGetCampaignsRequest} adminGetCampaignsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCampaignsAdminGetCampaigns(
      adminGetCampaignsRequest: AdminGetCampaignsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCampaignsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsAdminGetCampaigns(
        adminGetCampaignsRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminUpdateCampaignRequest} adminUpdateCampaignRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCampaignsAdminUpdateCampaign(
      adminUpdateCampaignRequest: AdminUpdateCampaignRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpdateCampaignResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminCampaignsAdminUpdateCampaign(
        adminUpdateCampaignRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGetAdmin(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAdmin(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGymsGetAllBrands(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllBrandsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminGymsGetAllBrands(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGymsGetAllEquipments(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllEquipmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminGymsGetAllEquipments(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGymsGetAllGyms(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllGymsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminGymsGetAllGyms(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGymsGetAllOptionPlans(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOptionPlansResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminGymsGetAllOptionPlans(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGymsGetAllShotPlans(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetShotPlansResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminGymsGetAllShotPlans(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminLogin(
      loginRequest: LoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminLogin(loginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminLogout(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminLogout(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreatePartnerRequest} createPartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPartnersCreateNewPartner(
      createPartnerRequest: CreatePartnerRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePartnerResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminPartnersCreateNewPartner(
        createPartnerRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {DeletePartnerRequest} deletePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPartnersDeletePartner(
      deletePartnerRequest: DeletePartnerRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletePartnerResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminPartnersDeletePartner(
        deletePartnerRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EditPartnerRequest} editPartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPartnersEditPartner(
      editPartnerRequest: EditPartnerRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditPartnerResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminPartnersEditPartner(editPartnerRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPartnersGetAllPartners(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllPartnersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminPartnersGetAllPartners(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPartnersGetPartnerInfo(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPartnerInfoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminPartnersGetPartnerInfo(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminRefreshToken(
      refreshTokenRequest: RefreshTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminRefreshToken(refreshTokenRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {AdminCreatePresignedUrlForRewardRequest} adminCreatePresignedUrlForRewardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminRewardCreatePresignedUrl(
      id: number,
      adminCreatePresignedUrlForRewardRequest: AdminCreatePresignedUrlForRewardRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreatePresignedUrlForRewardResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminRewardCreatePresignedUrl(
        id,
        adminCreatePresignedUrlForRewardRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminCreateRewardRequest} adminCreateRewardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminRewardCreateReward(
      adminCreateRewardRequest: AdminCreateRewardRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateRewardResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminRewardCreateReward(
        adminCreateRewardRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminRewardGetReward(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRewardResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminRewardGetReward(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} pageSize
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminRewardGetRewards(
      pageSize: number,
      page: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRewardsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminRewardGetRewards(pageSize, page, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminRewardTagGetRewardTags(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetRewardTagsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminRewardTagGetRewardTags(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {AdminUpdateRewardRequest} adminUpdateRewardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminRewardUpdateReward(
      id: number,
      adminUpdateRewardRequest: AdminUpdateRewardRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUpdateRewardResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminRewardUpdateReward(
        id,
        adminUpdateRewardRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreatePlanRequest} createPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSubscribePlansCreatePlan(
      createPlanRequest: CreatePlanRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePlanResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminSubscribePlansCreatePlan(
        createPlanRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {boolean} [isCampaignApplicable]
     * @param {boolean} [hasMinimumSubscriptionRenewalCount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSubscribePlansGetAllSubscribePlans(
      isCampaignApplicable?: boolean,
      hasMinimumSubscriptionRenewalCount?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllSubscribePlansResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminSubscribePlansGetAllSubscribePlans(
        isCampaignApplicable,
        hasMinimumSubscriptionRenewalCount,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSubscribePlansGetSubscribePlan(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubscribePlanResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminSubscribePlansGetSubscribePlan(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {UpdatePlanRequest} updatePlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSubscribePlansUpdatePlan(
      id: number,
      updatePlanRequest: UpdatePlanRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePlanResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminSubscribePlansUpdatePlan(
        id,
        updatePlanRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {AddPointsRequest} addPointsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserAddPoints(
      userId: number,
      addPointsRequest: AddPointsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AdminUserAddPointsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserAddPoints(userId, addPointsRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminGetSubscribeRequest} adminGetSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserAdminGetSubscribe(
      adminGetSubscribeRequest: AdminGetSubscribeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetSubscribeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserAdminGetSubscribe(
        adminGetSubscribeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminGetTempUseTicketRequest} adminGetTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserAdminGetTempUseTickets(
      adminGetTempUseTicketRequest: AdminGetTempUseTicketRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetTempUseTicketResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserAdminGetTempUseTickets(
        adminGetTempUseTicketRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminUserAddTempUseTicketRequest} adminUserAddTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserAdminUserAddTempUseTicket(
      adminUserAddTempUseTicketRequest: AdminUserAddTempUseTicketRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserAddTempUseTicketResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserAdminUserAddTempUseTicket(
        adminUserAddTempUseTicketRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminUserDeleteTempUseTicketRequest} adminUserDeleteTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserAdminUserDeleteTempUseTicket(
      adminUserDeleteTempUseTicketRequest: AdminUserDeleteTempUseTicketRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDeleteTempUseTicketResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserAdminUserDeleteTempUseTicket(
        adminUserDeleteTempUseTicketRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {AdminUserUpdatePhoneNumberRequest} adminUserUpdatePhoneNumberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserAdminUserUpdatePhoneNumber(
      id: string,
      adminUserUpdatePhoneNumberRequest: AdminUserUpdatePhoneNumberRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserUpdatePhoneNumberResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserAdminUserUpdatePhoneNumber(
        id,
        adminUserUpdatePhoneNumberRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserBan(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AdminUserBanResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserBan(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {number} subscribeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserGetUpdatableSubscribePlans(
      userId: number,
      subscribeId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserUpdatableSubscribePlansResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserGetUpdatableSubscribePlans(
        userId,
        subscribeId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserGetUser(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AdminGetUserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserGetUser(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2AdminSearchUsersRequest} v2AdminSearchUsersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserSearchUser(
      v2AdminSearchUsersRequest: V2AdminSearchUsersRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AdminSearchUsersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserSearchUser(v2AdminSearchUsersRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {number} subscribeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserUnsubscribe(
      userId: number,
      subscribeId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AdminUserSubscribeCancelResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserUnsubscribe(userId, subscribeId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {number} subscribeId
     * @param {UpdateSubscribeRequest} updateSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUserUpdateSubscribe(
      userId: number,
      subscribeId: number,
      updateSubscribeRequest: UpdateSubscribeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AdminUserUpdateSubscribeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserUpdateSubscribe(
        userId,
        subscribeId,
        updateSubscribeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminCreateCampaignRequest} adminCreateCampaignRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminV2CampaignsAdminCreateCampaign(
      adminCreateCampaignRequest: AdminCreateCampaignRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCreateCampaignResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminV2CampaignsAdminCreateCampaign(
        adminCreateCampaignRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminV2CampaignsAdminGetCampaign(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetCampaignResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminV2CampaignsAdminGetCampaign(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appContentGetHomeAppContents(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetHomeAppContentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appContentGetHomeAppContents(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appContentSectionGetAppContentSections(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetAppContentSectionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appContentSectionGetAppContentSections(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ChannelTalkBatchRequest} channelTalkBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configExecBatchChannelTalk(
      channelTalkBatchRequest: ChannelTalkBatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChannelTalkBatchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configExecBatchChannelTalk(
        channelTalkBatchRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configExecBatchNotifyCouponExpirationDayBefore(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponBatchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configExecBatchNotifyCouponExpirationDayBefore(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configExecBatchNotifyCouponExpirationFewDaysBefore(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponBatchResponse>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.configExecBatchNotifyCouponExpirationFewDaysBefore(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PresentPointBatchRequest} presentPointBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configExecBatchPresentPoint(
      presentPointBatchRequest: PresentPointBatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresentPointBatchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configExecBatchPresentPoint(
        presentPointBatchRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configExecBatchSale(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleBatchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configExecBatchSale(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SalesSummaryBatchRequest} salesSummaryBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configExecBatchSalesSummary(
      salesSummaryBatchRequest: SalesSummaryBatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesSummaryBatchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configExecBatchSalesSummary(
        salesSummaryBatchRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StartSubscribeRequest} startSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configExecBatchStartSubscribe(
      startSubscribeRequest: StartSubscribeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartSubscribeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configExecBatchStartSubscribe(
        startSubscribeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SubscribeBatchRequest} subscribeBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configExecBatchSubscribe(
      subscribeBatchRequest: SubscribeBatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscribeBatchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configExecBatchSubscribe(
        subscribeBatchRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StoreCreateStaffRequest} storeCreateStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configStoreCreateStaffs(
      storeCreateStaffRequest: StoreCreateStaffRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreCreateStaffResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configStoreCreateStaffs(
        storeCreateStaffRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StoreDeleteStaffRequest} storeDeleteStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configStoreDeleteStaff(
      storeDeleteStaffRequest: StoreDeleteStaffRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreDeleteStaffResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configStoreDeleteStaff(
        storeDeleteStaffRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StoreEditStaffRequest} storeEditStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configStoreEditStaff(
      storeEditStaffRequest: StoreEditStaffRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreEditStaffResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configStoreEditStaff(storeEditStaffRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configStoreGetGyms(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreGetGymsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configStoreGetGyms(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configStoreGetRoles(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreGetRolesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configStoreGetRoles(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async configStoreGetStaffs(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreGetStaffsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.configStoreGetStaffs(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {'subscribe' | 'temporaryUse'} purchaseType
     * @param {number} gymId
     * @param {number} [subscribePlanId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async couponV2GetActiveCouponsForPurchase(
      purchaseType: 'subscribe' | 'temporaryUse',
      gymId: number,
      subscribePlanId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetActiveCouponsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.couponV2GetActiveCouponsForPurchase(
        purchaseType,
        gymId,
        subscribePlanId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GetCouponByCodeRequest} getCouponByCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async couponV2GetCouponByCode(
      getCouponByCodeRequest: GetCouponByCodeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCouponByCodeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.couponV2GetCouponByCode(
        getCouponByCodeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async couponV2GetCoupons(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCouponsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.couponV2GetCoupons(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateGymRequest} createGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymCreateGym(
      createGymRequest: CreateGymRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateGymResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymCreateGym(createGymRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} gymId
     * @param {CreateGymAnnouncementRequest} createGymAnnouncementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymCreateGymAnnouncement(
      gymId: number,
      createGymAnnouncementRequest: CreateGymAnnouncementRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateGymAnnouncementResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymCreateGymAnnouncement(
        gymId,
        createGymAnnouncementRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {CreatePresignedUrlRequest} createPresignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymCreatePresignedUrl(
      id: number,
      createPresignedUrlRequest: CreatePresignedUrlRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePresignedUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymCreatePresignedUrl(
        id,
        createPresignedUrlRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymGetGym(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGymResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymGetGym(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} gymId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymGetGymAnnouncements(
      gymId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGymAnnouncementsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymGetGymAnnouncements(gymId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {NearGymRequest} nearGymRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async gymNearGym(
      nearGymRequest: NearGymRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NearGymResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymNearGym(nearGymRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SearchGymRequest} searchGymRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async gymSearchGym(
      searchGymRequest: SearchGymRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchGymResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymSearchGym(searchGymRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {UpdateGymRequest} updateGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymUpdateGym(
      id: number,
      updateGymRequest: UpdateGymRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGymResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymUpdateGym(id, updateGymRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} gymId
     * @param {number} announcementId
     * @param {UpdateGymAnnouncementRequest} updateGymAnnouncementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymUpdateGymAnnouncement(
      gymId: number,
      announcementId: number,
      updateGymAnnouncementRequest: UpdateGymAnnouncementRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateGymAnnouncementResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymUpdateGymAnnouncement(
        gymId,
        announcementId,
        updateGymAnnouncementRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymV2GetAllEquipments(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetAllEquipmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymV2GetAllEquipments(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {DayOfWeek} dayOfWeek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymV2GetCongestionTimeline(
      id: number,
      dayOfWeek: DayOfWeek,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetGymCongestionTimelineResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymV2GetCongestionTimeline(id, dayOfWeek, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymV2GetGymDetail(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetGymResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymV2GetGymDetail(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {number} planId
     * @param {number} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymV2GetSubscribePlanAppliedCouponPrice(
      id: number,
      planId: number,
      couponId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetSubscribePlanAppliedCouponPriceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymV2GetSubscribePlanAppliedCouponPrice(
        id,
        planId,
        couponId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {number} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymV2GetTemporaryUseAppliedCouponPrice(
      id: number,
      couponId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetTemporaryUseAppliedCouponPriceResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymV2GetTemporaryUseAppliedCouponPrice(
        id,
        couponId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2NearGymRequest} v2NearGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymV2NearGym(
      v2NearGymRequest: V2NearGymRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2NearGymResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymV2NearGym(v2NearGymRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2SearchGymRequest} v2SearchGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gymV2SearchGym(
      v2SearchGymRequest: V2SearchGymRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2SearchGymResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gymV2SearchGym(v2SearchGymRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthHealth(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthHealth(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messageGetAdminMessages(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetMessagesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageGetAdminMessages(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminMessagesUsersRequest} adminMessagesUsersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messageGetAdminMessagesUsers(
      adminMessagesUsersRequest: AdminMessagesUsersRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminMessagesUsersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageGetAdminMessagesUsers(
        adminMessagesUsersRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async messageGetMessages(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMessagesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageGetMessages(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async messageGetUserMessageOfficerList(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserMessageOfficerListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageGetUserMessageOfficerList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReadMessagesRequest} readMessagesRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async messageReadMessages(
      readMessagesRequest: ReadMessagesRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadMessagesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageReadMessages(readMessagesRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AdminSendMessageRequest} adminSendMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messageUserSendAdminMessage(
      adminSendMessageRequest: AdminSendMessageRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSendMessageResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageUserSendAdminMessage(
        adminSendMessageRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UserSendMessageRequest} userSendMessageRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async messageUserSendMessage(
      userSendMessageRequest: UserSendMessageRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSendMessageResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageUserSendMessage(userSendMessageRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FindNewsRequest} findNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messageV2FindNews(
      findNewsRequest: FindNewsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindNewsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageV2FindNews(findNewsRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {NewsGetRequest} newsGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messageV2GetNews(
      newsGetRequest: NewsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsGetResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageV2GetNews(newsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ReadNewsRequest} readNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messageV2ReadNews(
      readNewsRequest: ReadNewsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadNewsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageV2ReadNews(readNewsRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {NativeChargeLogGetRequest} nativeChargeLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nativeLogNativeGetChargeLogs(
      nativeChargeLogGetRequest: NativeChargeLogGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NativeChargeLogGetResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nativeLogNativeGetChargeLogs(
        nativeChargeLogGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async nativeLogNativeGetGateLogStatus(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NativeGateLogStatusGetResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nativeLogNativeGetGateLogStatus(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {NativeGateLogGetRequest} nativeGateLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nativeLogNativeGetGateLogs(
      nativeGateLogGetRequest: NativeGateLogGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NativeGateLogGetResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.nativeLogNativeGetGateLogs(
        nativeGateLogGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SearchNewsRequest} searchNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsAdminGetUserByFilter(
      searchNewsRequest: SearchNewsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchNewsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.newsAdminGetUserByFilter(searchNewsRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateNewsRequest} createNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsCreateNews(
      createNewsRequest: CreateNewsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNewsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.newsCreateNews(createNewsRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {NewsGetRequest} newsGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsGetNews(
      newsGetRequest: NewsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsGetResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.newsGetNews(newsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreatePaidyRequest} createPaidyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purchaseCreatePaidy(
      createPaidyRequest: CreatePaidyRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePaidyResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseCreatePaidy(createPaidyRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreatePayjpRequest} createPayjpRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purchaseCreatePayjp(
      createPayjpRequest: CreatePayjpRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePayjpResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseCreatePayjp(createPayjpRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purchaseDeletePaidy(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletePaidyResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseDeletePaidy(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purchaseDeletePayjp(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletePayjpResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseDeletePayjp(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purchaseGetPaymentWays(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentWaysResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseGetPaymentWays(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purchasePaidyHTML(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.purchasePaidyHTML(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purchaseV2GetPayjpStaticPage(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseV2GetPayjpStaticPage(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2MakeSubscribeRequest} v2MakeSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purchaseV2MakeSubscribe(
      v2MakeSubscribeRequest: V2MakeSubscribeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2MakeSubscribeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseV2MakeSubscribe(
        v2MakeSubscribeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2MakeTemporaryUseRequest} v2MakeTemporaryUseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async purchaseV2MakeTemporaryUse(
      v2MakeTemporaryUseRequest: V2MakeTemporaryUseRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2MakeTemporaryUseResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseV2MakeTemporaryUse(
        v2MakeTemporaryUseRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {FromEntranceRequest} fromEntranceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async qRFromEntrance(
      fromEntranceRequest: FromEntranceRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FromEntranceResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.qRFromEntrance(fromEntranceRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rewardExchangeReward(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ExchangeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rewardExchangeReward(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rewardGetReward(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetRewardResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rewardGetReward(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2GetRewardsRequest} v2GetRewardsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rewardGetRewards(
      v2GetRewardsRequest: V2GetRewardsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetRewardsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rewardGetRewards(v2GetRewardsRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2GetRewardSectionsRequest} v2GetRewardSectionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rewardSectionGetRewardSections(
      v2GetRewardSectionsRequest: V2GetRewardSectionsRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetRewardSectionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rewardSectionGetRewardSections(
        v2GetRewardSectionsRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} gymId
     * @param {number} limit
     * @param {number} offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesSummaryGetSalesSummaries(
      gymId: number,
      limit: number,
      offset: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSalesSummariesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesSummaryGetSalesSummaries(
        gymId,
        limit,
        offset,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateSmsVerificationRequest} createSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async smsVerificationCreateSmsVerification(
      createSmsVerificationRequest: CreateSmsVerificationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSmsVerificationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.smsVerificationCreateSmsVerification(
        createSmsVerificationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UpdateSmsVerificationRequest} updateSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async smsVerificationUpdateSmsVerification(
      updateSmsVerificationRequest: UpdateSmsVerificationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSmsVerificationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.smsVerificationUpdateSmsVerification(
        updateSmsVerificationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffGetAdmin(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStaffResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffGetAdmin(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StaffGymListRequest} staffGymListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffGetStaffGymList(
      staffGymListRequest: StaffGymListRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffGymListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffGetStaffGymList(staffGymListRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffLogin(
      loginRequest: LoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffLogin(loginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffLogout(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffLogout(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffRefreshToken(
      refreshTokenRequest: RefreshTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffRefreshToken(refreshTokenRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeGymStoreGetGymInfos(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreGetAllStaffGymsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeGymStoreGetGymInfos(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeGymStoreGetGymStatuses(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreGymGetResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeGymStoreGetGymStatuses(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StoreGateLogGetRequest} storeGateLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeLogStoreGetGateLogs(
      storeGateLogGetRequest: StoreGateLogGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreGateLogGetResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeLogStoreGetGateLogs(
        storeGateLogGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StorePaymentLogGetRequest} storePaymentLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeLogStoreGetPurchaseLogs(
      storePaymentLogGetRequest: StorePaymentLogGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorePaymentLogGetResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeLogStoreGetPurchaseLogs(
        storePaymentLogGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} gymId
     * @param {StaffUserMessagesByGymRequest} staffUserMessagesByGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeMessageGetStaffMessageByGym(
      gymId: string,
      staffUserMessagesByGymRequest: StaffUserMessagesByGymRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffUserMessagesByGymResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeMessageGetStaffMessageByGym(
        gymId,
        staffUserMessagesByGymRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StaffGetMessagesRequest} staffGetMessagesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeMessageGetStaffMessages(
      staffGetMessagesRequest: StaffGetMessagesRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffGetMessagesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeMessageGetStaffMessages(
        staffGetMessagesRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StaffReadMessageRequest} staffReadMessageRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async storeMessageReadMessage(
      staffReadMessageRequest: StaffReadMessageRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeMessageReadMessage(
        staffReadMessageRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StaffChangeMessageReadRequest} staffChangeMessageReadRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async storeMessageStaffChangeMessageRead(
      staffChangeMessageReadRequest: StaffChangeMessageReadRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeMessageStaffChangeMessageRead(
        staffChangeMessageReadRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} gymId
     * @param {UserSendStaffMessageRequest} userSendStaffMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeMessageUserSendStaffMessage(
      gymId: string,
      userSendStaffMessageRequest: UserSendStaffMessageRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSendStaffMessageResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeMessageUserSendStaffMessage(
        gymId,
        userSendStaffMessageRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StaffGetSubscribeRequest} staffGetSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeUserStoreGetSubscribe(
      staffGetSubscribeRequest: StaffGetSubscribeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffGetSubscribeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeUserStoreGetSubscribe(
        staffGetSubscribeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StaffGetTempUseTicketRequest} staffGetTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeUserStoreGetTempUseTickets(
      staffGetTempUseTicketRequest: StaffGetTempUseTicketRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffGetTempUseTicketResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeUserStoreGetTempUseTickets(
        staffGetTempUseTicketRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StoreGetUserFilterRequest} storeGetUserFilterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeUserStoreGetUserByFilter(
      storeGetUserFilterRequest: StoreGetUserFilterRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreGetUserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeUserStoreGetUserByFilter(
        storeGetUserFilterRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {StaffUserAddTempUseTicketRequest} staffUserAddTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async storeUserStoreUserAddTempUseTicket(
      staffUserAddTempUseTicketRequest: StaffUserAddTempUseTicketRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffUserAddTempUseTicketResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.storeUserStoreUserAddTempUseTicket(
        staffUserAddTempUseTicketRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscribeV2CancelUnsubscribe(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.subscribeV2CancelUnsubscribe(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscribeV2Unsubscribe(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2SubscribeCancelResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.subscribeV2Unsubscribe(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2CancelStopSubscribeRequest} v2CancelStopSubscribeRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async supplyV2CancelStopSubscribe(
      v2CancelStopSubscribeRequest: V2CancelStopSubscribeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2CancelStopSubscribeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.supplyV2CancelStopSubscribe(
        v2CancelStopSubscribeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async supplyV2GetPictures(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetPicturesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.supplyV2GetPictures(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async supplyV2GetShots(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetShotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.supplyV2GetShots(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async supplyV2GetTemporaryUses(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetTemporaryUseResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.supplyV2GetTemporaryUses(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async supplyV2GetUserEntranceToken(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetUserEntranceTokenResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.supplyV2GetUserEntranceToken(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2StopSubscribeRequest} v2StopSubscribeRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async supplyV2StopSubscribe(
      v2StopSubscribeRequest: V2StopSubscribeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2StopSubscribeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.supplyV2StopSubscribe(v2StopSubscribeRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2UseShotRequest} v2UseShotRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async supplyV2UseShot(
      v2UseShotRequest: V2UseShotRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2UseShotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.supplyV2UseShot(v2UseShotRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2UseTemporaryUseRequest} v2UseTemporaryUseRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async supplyV2UseTemporaryUse(
      v2UseTemporaryUseRequest: V2UseTemporaryUseRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2UseTemporaryUseResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.supplyV2UseTemporaryUse(
        v2UseTemporaryUseRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} surveyId
     * @param {CreateSurveyQuestionResponsesRequest} createSurveyQuestionResponsesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async surveyCreateSurveyQuestionResponses(
      surveyId: string,
      createSurveyQuestionResponsesRequest: CreateSurveyQuestionResponsesRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSurveyQuestionResponsesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.surveyCreateSurveyQuestionResponses(
        surveyId,
        createSurveyQuestionResponsesRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} surveyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async surveyGetSurvey(
      surveyId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSurveyResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.surveyGetSurvey(surveyId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GetAddressByPostalCodeRequest} getAddressByPostalCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userGetAddressByPostalCode(
      getAddressByPostalCodeRequest: GetAddressByPostalCodeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAddressByPostalCodeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userGetAddressByPostalCode(
        getAddressByPostalCodeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userGetUser(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUser(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UpdateNotificationTokenRequest} updateNotificationTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUpdateNotificationToken(
      updateNotificationTokenRequest: UpdateNotificationTokenRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateNotificationTokenResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateNotificationToken(
        updateNotificationTokenRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2CreateSmsVerificationRequest} v2CreateSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2CreateSmsVerification(
      v2CreateSmsVerificationRequest: V2CreateSmsVerificationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2CreateSmsVerificationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2CreateSmsVerification(
        v2CreateSmsVerificationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2GetActiveSubscribe(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetActiveSubscribesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2GetActiveSubscribe(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2GetAppContentSections(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetUserAppContentSectionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2GetAppContentSections(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2GetFriendInvitation(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetFriendInvitationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2GetFriendInvitation(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2GetHomeAppContents(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetUserHomeAppContentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2GetHomeAppContents(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2GetMySchedules(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetMySchedulesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2GetMySchedules(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} pageSize
     * @param {string} pageToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2GetPointHistories(
      pageSize: number,
      pageToken: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetPointHistoriesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2GetPointHistories(pageSize, pageToken, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2GetPointSummary(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetUserPointSummaryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2GetPointSummary(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2GetTickets(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetTicketsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2GetTickets(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2GetUpdateSubscribeCoupons(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetUpdateSubscribeCouponsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2GetUpdateSubscribeCoupons(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2GetUsualGyms(
      limit: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetUsualGymsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2GetUsualGyms(limit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2LoginRequest} v2LoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2Login(
      v2LoginRequest: V2LoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2LoginResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2Login(v2LoginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2PhoneNumberSendCodeRequest} v2PhoneNumberSendCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2PhoneNumberSendCode(
      v2PhoneNumberSendCodeRequest: V2PhoneNumberSendCodeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2PhoneNumberSendCodeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2PhoneNumberSendCode(
        v2PhoneNumberSendCodeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2PhoneNumberVerifyRequest} v2PhoneNumberVerifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2PhoneNumberVerify(
      v2PhoneNumberVerifyRequest: V2PhoneNumberVerifyRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2PhoneNumberVerifyResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2PhoneNumberVerify(
        v2PhoneNumberVerifyRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2SetPasswordRequest} v2SetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2SetPassword(
      v2SetPasswordRequest: V2SetPasswordRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2SetPasswordResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2SetPassword(v2SetPasswordRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2SetPasswordStrictRequest} v2SetPasswordStrictRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2SetPasswordStrict(
      v2SetPasswordStrictRequest: V2SetPasswordStrictRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2SetPasswordStrictResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2SetPasswordStrict(
        v2SetPasswordStrictRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2CreateRequest} v2CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2SignUp(
      v2CreateRequest: V2CreateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2CreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2SignUp(v2CreateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2UpdateRequest} v2UpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2Update(
      v2UpdateRequest: V2UpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2UpdateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2Update(v2UpdateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2UpdateMyScheduleRequest} v2UpdateMyScheduleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2UpdateMySchedule(
      v2UpdateMyScheduleRequest: V2UpdateMyScheduleRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2UpdateMySchedulesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2UpdateMySchedule(
        v2UpdateMyScheduleRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {V2UpdateSmsVerificationRequest} v2UpdateSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2UpdateSmsVerification(
      v2UpdateSmsVerificationRequest: V2UpdateSmsVerificationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2UpdateSmsVerificationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2UpdateSmsVerification(
        v2UpdateSmsVerificationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {V2UpdateSubscribeRequest} v2UpdateSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2UpdateUserSubscribes(
      id: number,
      v2UpdateSubscribeRequest: V2UpdateSubscribeRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2UpdateSubscribeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2UpdateUserSubscribes(
        id,
        v2UpdateSubscribeRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userV2Withdraw(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2WithdrawResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userV2Withdraw(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {AdminCreateAppContentRequest} adminCreateAppContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentCreateAppContent(
      adminCreateAppContentRequest: AdminCreateAppContentRequest,
      options?: any,
    ): AxiosPromise<AdminCreateAppContentResponse> {
      return localVarFp
        .adminAppContentCreateAppContent(adminCreateAppContentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {AdminCreatePresignedUrlForAppContentRequest} adminCreatePresignedUrlForAppContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentCreatePresignedUrl(
      id: number,
      adminCreatePresignedUrlForAppContentRequest: AdminCreatePresignedUrlForAppContentRequest,
      options?: any,
    ): AxiosPromise<AdminCreatePresignedUrlForAppContentResponse> {
      return localVarFp
        .adminAppContentCreatePresignedUrl(id, adminCreatePresignedUrlForAppContentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentGetAppContent(id: number, options?: any): AxiosPromise<AdminGetAppContentResponse> {
      return localVarFp.adminAppContentGetAppContent(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentSectionGetAppContentSections(options?: any): AxiosPromise<AdminGetAppContentSectionsResponse> {
      return localVarFp
        .adminAppContentSectionGetAppContentSections(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentSectionGetAppContentSectionsWithContents(
      options?: any,
    ): AxiosPromise<AdminGetAppContentSectionsWithContentsResponse> {
      return localVarFp
        .adminAppContentSectionGetAppContentSectionsWithContents(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {AdminUpdateAppContentSectionContentsPriorityRequest} adminUpdateAppContentSectionContentsPriorityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentSectionUpdateAppContentSectionContentsPriority(
      id: number,
      adminUpdateAppContentSectionContentsPriorityRequest: AdminUpdateAppContentSectionContentsPriorityRequest,
      options?: any,
    ): AxiosPromise<AdminUpdateAppContentSectionContentsPriorityResponse> {
      return localVarFp
        .adminAppContentSectionUpdateAppContentSectionContentsPriority(
          id,
          adminUpdateAppContentSectionContentsPriorityRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {AdminUpdateAppContentRequest} adminUpdateAppContentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminAppContentUpdateAppContent(
      id: number,
      adminUpdateAppContentRequest: AdminUpdateAppContentRequest,
      options?: any,
    ): AxiosPromise<AdminUpdateAppContentResponse> {
      return localVarFp
        .adminAppContentUpdateAppContent(id, adminUpdateAppContentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminCreateCampaignCodeRequest} adminCreateCampaignCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCampaignsAdminCreateCampaignCode(
      adminCreateCampaignCodeRequest: AdminCreateCampaignCodeRequest,
      options?: any,
    ): AxiosPromise<AdminCreateCampaignCodeResponse> {
      return localVarFp
        .adminCampaignsAdminCreateCampaignCode(adminCreateCampaignCodeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminGetCampaignsRequest} adminGetCampaignsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCampaignsAdminGetCampaigns(
      adminGetCampaignsRequest: AdminGetCampaignsRequest,
      options?: any,
    ): AxiosPromise<AdminGetCampaignsResponse> {
      return localVarFp
        .adminCampaignsAdminGetCampaigns(adminGetCampaignsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminUpdateCampaignRequest} adminUpdateCampaignRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCampaignsAdminUpdateCampaign(
      adminUpdateCampaignRequest: AdminUpdateCampaignRequest,
      options?: any,
    ): AxiosPromise<AdminUpdateCampaignResponse> {
      return localVarFp
        .adminCampaignsAdminUpdateCampaign(adminUpdateCampaignRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetAdmin(options?: any): AxiosPromise<GetAdminResponse> {
      return localVarFp.adminGetAdmin(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGymsGetAllBrands(options?: any): AxiosPromise<GetAllBrandsResponse> {
      return localVarFp.adminGymsGetAllBrands(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGymsGetAllEquipments(options?: any): AxiosPromise<GetAllEquipmentsResponse> {
      return localVarFp.adminGymsGetAllEquipments(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGymsGetAllGyms(options?: any): AxiosPromise<GetAllGymsResponse> {
      return localVarFp.adminGymsGetAllGyms(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGymsGetAllOptionPlans(options?: any): AxiosPromise<GetOptionPlansResponse> {
      return localVarFp.adminGymsGetAllOptionPlans(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGymsGetAllShotPlans(options?: any): AxiosPromise<GetShotPlansResponse> {
      return localVarFp.adminGymsGetAllShotPlans(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLogin(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
      return localVarFp.adminLogin(loginRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLogout(options?: any): AxiosPromise<void> {
      return localVarFp.adminLogout(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreatePartnerRequest} createPartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPartnersCreateNewPartner(
      createPartnerRequest: CreatePartnerRequest,
      options?: any,
    ): AxiosPromise<CreatePartnerResponse> {
      return localVarFp
        .adminPartnersCreateNewPartner(createPartnerRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeletePartnerRequest} deletePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPartnersDeletePartner(
      deletePartnerRequest: DeletePartnerRequest,
      options?: any,
    ): AxiosPromise<DeletePartnerResponse> {
      return localVarFp
        .adminPartnersDeletePartner(deletePartnerRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditPartnerRequest} editPartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPartnersEditPartner(editPartnerRequest: EditPartnerRequest, options?: any): AxiosPromise<EditPartnerResponse> {
      return localVarFp
        .adminPartnersEditPartner(editPartnerRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPartnersGetAllPartners(options?: any): AxiosPromise<GetAllPartnersResponse> {
      return localVarFp.adminPartnersGetAllPartners(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPartnersGetPartnerInfo(id: string, options?: any): AxiosPromise<GetPartnerInfoResponse> {
      return localVarFp.adminPartnersGetPartnerInfo(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRefreshToken(refreshTokenRequest: RefreshTokenRequest, options?: any): AxiosPromise<RefreshTokenResponse> {
      return localVarFp.adminRefreshToken(refreshTokenRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {AdminCreatePresignedUrlForRewardRequest} adminCreatePresignedUrlForRewardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardCreatePresignedUrl(
      id: number,
      adminCreatePresignedUrlForRewardRequest: AdminCreatePresignedUrlForRewardRequest,
      options?: any,
    ): AxiosPromise<AdminCreatePresignedUrlForRewardResponse> {
      return localVarFp
        .adminRewardCreatePresignedUrl(id, adminCreatePresignedUrlForRewardRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminCreateRewardRequest} adminCreateRewardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardCreateReward(
      adminCreateRewardRequest: AdminCreateRewardRequest,
      options?: any,
    ): AxiosPromise<AdminCreateRewardResponse> {
      return localVarFp
        .adminRewardCreateReward(adminCreateRewardRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardGetReward(id: number, options?: any): AxiosPromise<AdminGetRewardResponse> {
      return localVarFp.adminRewardGetReward(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} pageSize
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardGetRewards(pageSize: number, page: number, options?: any): AxiosPromise<AdminGetRewardsResponse> {
      return localVarFp.adminRewardGetRewards(pageSize, page, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardTagGetRewardTags(options?: any): AxiosPromise<AdminGetRewardTagsResponse> {
      return localVarFp.adminRewardTagGetRewardTags(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {AdminUpdateRewardRequest} adminUpdateRewardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminRewardUpdateReward(
      id: number,
      adminUpdateRewardRequest: AdminUpdateRewardRequest,
      options?: any,
    ): AxiosPromise<AdminUpdateRewardResponse> {
      return localVarFp
        .adminRewardUpdateReward(id, adminUpdateRewardRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreatePlanRequest} createPlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSubscribePlansCreatePlan(
      createPlanRequest: CreatePlanRequest,
      options?: any,
    ): AxiosPromise<CreatePlanResponse> {
      return localVarFp
        .adminSubscribePlansCreatePlan(createPlanRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} [isCampaignApplicable]
     * @param {boolean} [hasMinimumSubscriptionRenewalCount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSubscribePlansGetAllSubscribePlans(
      isCampaignApplicable?: boolean,
      hasMinimumSubscriptionRenewalCount?: boolean,
      options?: any,
    ): AxiosPromise<GetAllSubscribePlansResponse> {
      return localVarFp
        .adminSubscribePlansGetAllSubscribePlans(isCampaignApplicable, hasMinimumSubscriptionRenewalCount, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSubscribePlansGetSubscribePlan(id: number, options?: any): AxiosPromise<GetSubscribePlanResponse> {
      return localVarFp.adminSubscribePlansGetSubscribePlan(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {UpdatePlanRequest} updatePlanRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSubscribePlansUpdatePlan(
      id: number,
      updatePlanRequest: UpdatePlanRequest,
      options?: any,
    ): AxiosPromise<UpdatePlanResponse> {
      return localVarFp
        .adminSubscribePlansUpdatePlan(id, updatePlanRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {AddPointsRequest} addPointsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAddPoints(
      userId: number,
      addPointsRequest: AddPointsRequest,
      options?: any,
    ): AxiosPromise<V2AdminUserAddPointsResponse> {
      return localVarFp
        .adminUserAddPoints(userId, addPointsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminGetSubscribeRequest} adminGetSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAdminGetSubscribe(
      adminGetSubscribeRequest: AdminGetSubscribeRequest,
      options?: any,
    ): AxiosPromise<AdminGetSubscribeResponse> {
      return localVarFp
        .adminUserAdminGetSubscribe(adminGetSubscribeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminGetTempUseTicketRequest} adminGetTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAdminGetTempUseTickets(
      adminGetTempUseTicketRequest: AdminGetTempUseTicketRequest,
      options?: any,
    ): AxiosPromise<AdminGetTempUseTicketResponse> {
      return localVarFp
        .adminUserAdminGetTempUseTickets(adminGetTempUseTicketRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminUserAddTempUseTicketRequest} adminUserAddTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAdminUserAddTempUseTicket(
      adminUserAddTempUseTicketRequest: AdminUserAddTempUseTicketRequest,
      options?: any,
    ): AxiosPromise<AdminUserAddTempUseTicketResponse> {
      return localVarFp
        .adminUserAdminUserAddTempUseTicket(adminUserAddTempUseTicketRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminUserDeleteTempUseTicketRequest} adminUserDeleteTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAdminUserDeleteTempUseTicket(
      adminUserDeleteTempUseTicketRequest: AdminUserDeleteTempUseTicketRequest,
      options?: any,
    ): AxiosPromise<AdminUserDeleteTempUseTicketResponse> {
      return localVarFp
        .adminUserAdminUserDeleteTempUseTicket(adminUserDeleteTempUseTicketRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {AdminUserUpdatePhoneNumberRequest} adminUserUpdatePhoneNumberRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserAdminUserUpdatePhoneNumber(
      id: string,
      adminUserUpdatePhoneNumberRequest: AdminUserUpdatePhoneNumberRequest,
      options?: any,
    ): AxiosPromise<AdminUserUpdatePhoneNumberResponse> {
      return localVarFp
        .adminUserAdminUserUpdatePhoneNumber(id, adminUserUpdatePhoneNumberRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserBan(id: number, options?: any): AxiosPromise<V2AdminUserBanResponse> {
      return localVarFp.adminUserBan(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {number} subscribeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserGetUpdatableSubscribePlans(
      userId: number,
      subscribeId: number,
      options?: any,
    ): AxiosPromise<GetUserUpdatableSubscribePlansResponse> {
      return localVarFp
        .adminUserGetUpdatableSubscribePlans(userId, subscribeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserGetUser(id: number, options?: any): AxiosPromise<V2AdminGetUserResponse> {
      return localVarFp.adminUserGetUser(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2AdminSearchUsersRequest} v2AdminSearchUsersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserSearchUser(
      v2AdminSearchUsersRequest: V2AdminSearchUsersRequest,
      options?: any,
    ): AxiosPromise<V2AdminSearchUsersResponse> {
      return localVarFp
        .adminUserSearchUser(v2AdminSearchUsersRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {number} subscribeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserUnsubscribe(
      userId: number,
      subscribeId: number,
      options?: any,
    ): AxiosPromise<V2AdminUserSubscribeCancelResponse> {
      return localVarFp.adminUserUnsubscribe(userId, subscribeId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {number} subscribeId
     * @param {UpdateSubscribeRequest} updateSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUserUpdateSubscribe(
      userId: number,
      subscribeId: number,
      updateSubscribeRequest: UpdateSubscribeRequest,
      options?: any,
    ): AxiosPromise<V2AdminUserUpdateSubscribeResponse> {
      return localVarFp
        .adminUserUpdateSubscribe(userId, subscribeId, updateSubscribeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminCreateCampaignRequest} adminCreateCampaignRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminV2CampaignsAdminCreateCampaign(
      adminCreateCampaignRequest: AdminCreateCampaignRequest,
      options?: any,
    ): AxiosPromise<AdminCreateCampaignResponse> {
      return localVarFp
        .adminV2CampaignsAdminCreateCampaign(adminCreateCampaignRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminV2CampaignsAdminGetCampaign(id: number, options?: any): AxiosPromise<AdminGetCampaignResponse> {
      return localVarFp.adminV2CampaignsAdminGetCampaign(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appContentGetHomeAppContents(options?: any): AxiosPromise<V2GetHomeAppContentsResponse> {
      return localVarFp.appContentGetHomeAppContents(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appContentSectionGetAppContentSections(options?: any): AxiosPromise<V2GetAppContentSectionsResponse> {
      return localVarFp.appContentSectionGetAppContentSections(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ChannelTalkBatchRequest} channelTalkBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchChannelTalk(
      channelTalkBatchRequest: ChannelTalkBatchRequest,
      options?: any,
    ): AxiosPromise<ChannelTalkBatchResponse> {
      return localVarFp
        .configExecBatchChannelTalk(channelTalkBatchRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchNotifyCouponExpirationDayBefore(options?: any): AxiosPromise<CouponBatchResponse> {
      return localVarFp
        .configExecBatchNotifyCouponExpirationDayBefore(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchNotifyCouponExpirationFewDaysBefore(options?: any): AxiosPromise<CouponBatchResponse> {
      return localVarFp
        .configExecBatchNotifyCouponExpirationFewDaysBefore(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PresentPointBatchRequest} presentPointBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchPresentPoint(
      presentPointBatchRequest: PresentPointBatchRequest,
      options?: any,
    ): AxiosPromise<PresentPointBatchResponse> {
      return localVarFp
        .configExecBatchPresentPoint(presentPointBatchRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchSale(options?: any): AxiosPromise<SaleBatchResponse> {
      return localVarFp.configExecBatchSale(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SalesSummaryBatchRequest} salesSummaryBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchSalesSummary(
      salesSummaryBatchRequest: SalesSummaryBatchRequest,
      options?: any,
    ): AxiosPromise<SalesSummaryBatchResponse> {
      return localVarFp
        .configExecBatchSalesSummary(salesSummaryBatchRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StartSubscribeRequest} startSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchStartSubscribe(
      startSubscribeRequest: StartSubscribeRequest,
      options?: any,
    ): AxiosPromise<StartSubscribeResponse> {
      return localVarFp
        .configExecBatchStartSubscribe(startSubscribeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SubscribeBatchRequest} subscribeBatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configExecBatchSubscribe(
      subscribeBatchRequest: SubscribeBatchRequest,
      options?: any,
    ): AxiosPromise<SubscribeBatchResponse> {
      return localVarFp
        .configExecBatchSubscribe(subscribeBatchRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StoreCreateStaffRequest} storeCreateStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreCreateStaffs(
      storeCreateStaffRequest: StoreCreateStaffRequest,
      options?: any,
    ): AxiosPromise<StoreCreateStaffResponse> {
      return localVarFp
        .configStoreCreateStaffs(storeCreateStaffRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StoreDeleteStaffRequest} storeDeleteStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreDeleteStaff(
      storeDeleteStaffRequest: StoreDeleteStaffRequest,
      options?: any,
    ): AxiosPromise<StoreDeleteStaffResponse> {
      return localVarFp
        .configStoreDeleteStaff(storeDeleteStaffRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StoreEditStaffRequest} storeEditStaffRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreEditStaff(
      storeEditStaffRequest: StoreEditStaffRequest,
      options?: any,
    ): AxiosPromise<StoreEditStaffResponse> {
      return localVarFp
        .configStoreEditStaff(storeEditStaffRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreGetGyms(options?: any): AxiosPromise<StoreGetGymsResponse> {
      return localVarFp.configStoreGetGyms(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreGetRoles(options?: any): AxiosPromise<StoreGetRolesResponse> {
      return localVarFp.configStoreGetRoles(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    configStoreGetStaffs(options?: any): AxiosPromise<StoreGetStaffsResponse> {
      return localVarFp.configStoreGetStaffs(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {'subscribe' | 'temporaryUse'} purchaseType
     * @param {number} gymId
     * @param {number} [subscribePlanId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponV2GetActiveCouponsForPurchase(
      purchaseType: 'subscribe' | 'temporaryUse',
      gymId: number,
      subscribePlanId?: number,
      options?: any,
    ): AxiosPromise<GetActiveCouponsResponse> {
      return localVarFp
        .couponV2GetActiveCouponsForPurchase(purchaseType, gymId, subscribePlanId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetCouponByCodeRequest} getCouponByCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponV2GetCouponByCode(
      getCouponByCodeRequest: GetCouponByCodeRequest,
      options?: any,
    ): AxiosPromise<GetCouponByCodeResponse> {
      return localVarFp
        .couponV2GetCouponByCode(getCouponByCodeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    couponV2GetCoupons(options?: any): AxiosPromise<GetCouponsResponse> {
      return localVarFp.couponV2GetCoupons(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateGymRequest} createGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymCreateGym(createGymRequest: CreateGymRequest, options?: any): AxiosPromise<CreateGymResponse> {
      return localVarFp.gymCreateGym(createGymRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} gymId
     * @param {CreateGymAnnouncementRequest} createGymAnnouncementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymCreateGymAnnouncement(
      gymId: number,
      createGymAnnouncementRequest: CreateGymAnnouncementRequest,
      options?: any,
    ): AxiosPromise<CreateGymAnnouncementResponse> {
      return localVarFp
        .gymCreateGymAnnouncement(gymId, createGymAnnouncementRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CreatePresignedUrlRequest} createPresignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymCreatePresignedUrl(
      id: number,
      createPresignedUrlRequest: CreatePresignedUrlRequest,
      options?: any,
    ): AxiosPromise<CreatePresignedUrlResponse> {
      return localVarFp
        .gymCreatePresignedUrl(id, createPresignedUrlRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymGetGym(id: number, options?: any): AxiosPromise<GetGymResponse> {
      return localVarFp.gymGetGym(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} gymId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymGetGymAnnouncements(gymId: number, options?: any): AxiosPromise<GetGymAnnouncementsResponse> {
      return localVarFp.gymGetGymAnnouncements(gymId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {NearGymRequest} nearGymRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    gymNearGym(nearGymRequest: NearGymRequest, options?: any): AxiosPromise<NearGymResponse> {
      return localVarFp.gymNearGym(nearGymRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SearchGymRequest} searchGymRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    gymSearchGym(searchGymRequest: SearchGymRequest, options?: any): AxiosPromise<SearchGymResponse> {
      return localVarFp.gymSearchGym(searchGymRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {UpdateGymRequest} updateGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymUpdateGym(id: number, updateGymRequest: UpdateGymRequest, options?: any): AxiosPromise<UpdateGymResponse> {
      return localVarFp.gymUpdateGym(id, updateGymRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} gymId
     * @param {number} announcementId
     * @param {UpdateGymAnnouncementRequest} updateGymAnnouncementRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymUpdateGymAnnouncement(
      gymId: number,
      announcementId: number,
      updateGymAnnouncementRequest: UpdateGymAnnouncementRequest,
      options?: any,
    ): AxiosPromise<UpdateGymAnnouncementResponse> {
      return localVarFp
        .gymUpdateGymAnnouncement(gymId, announcementId, updateGymAnnouncementRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2GetAllEquipments(options?: any): AxiosPromise<V2GetAllEquipmentsResponse> {
      return localVarFp.gymV2GetAllEquipments(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {DayOfWeek} dayOfWeek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2GetCongestionTimeline(
      id: number,
      dayOfWeek: DayOfWeek,
      options?: any,
    ): AxiosPromise<V2GetGymCongestionTimelineResponse> {
      return localVarFp.gymV2GetCongestionTimeline(id, dayOfWeek, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2GetGymDetail(id: number, options?: any): AxiosPromise<V2GetGymResponse> {
      return localVarFp.gymV2GetGymDetail(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} planId
     * @param {number} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2GetSubscribePlanAppliedCouponPrice(
      id: number,
      planId: number,
      couponId: number,
      options?: any,
    ): AxiosPromise<V2GetSubscribePlanAppliedCouponPriceResponse> {
      return localVarFp
        .gymV2GetSubscribePlanAppliedCouponPrice(id, planId, couponId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} couponId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2GetTemporaryUseAppliedCouponPrice(
      id: number,
      couponId: number,
      options?: any,
    ): AxiosPromise<V2GetTemporaryUseAppliedCouponPriceResponse> {
      return localVarFp
        .gymV2GetTemporaryUseAppliedCouponPrice(id, couponId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2NearGymRequest} v2NearGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2NearGym(v2NearGymRequest: V2NearGymRequest, options?: any): AxiosPromise<V2NearGymResponse> {
      return localVarFp.gymV2NearGym(v2NearGymRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2SearchGymRequest} v2SearchGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gymV2SearchGym(v2SearchGymRequest: V2SearchGymRequest, options?: any): AxiosPromise<V2SearchGymResponse> {
      return localVarFp.gymV2SearchGym(v2SearchGymRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthHealth(options?: any): AxiosPromise<void> {
      return localVarFp.healthHealth(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageGetAdminMessages(id: string, options?: any): AxiosPromise<AdminGetMessagesResponse> {
      return localVarFp.messageGetAdminMessages(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminMessagesUsersRequest} adminMessagesUsersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageGetAdminMessagesUsers(
      adminMessagesUsersRequest: AdminMessagesUsersRequest,
      options?: any,
    ): AxiosPromise<AdminMessagesUsersResponse> {
      return localVarFp
        .messageGetAdminMessagesUsers(adminMessagesUsersRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    messageGetMessages(options?: any): AxiosPromise<GetMessagesResponse> {
      return localVarFp.messageGetMessages(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    messageGetUserMessageOfficerList(options?: any): AxiosPromise<GetUserMessageOfficerListResponse> {
      return localVarFp.messageGetUserMessageOfficerList(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ReadMessagesRequest} readMessagesRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    messageReadMessages(readMessagesRequest: ReadMessagesRequest, options?: any): AxiosPromise<ReadMessagesResponse> {
      return localVarFp.messageReadMessages(readMessagesRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AdminSendMessageRequest} adminSendMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageUserSendAdminMessage(
      adminSendMessageRequest: AdminSendMessageRequest,
      options?: any,
    ): AxiosPromise<AdminSendMessageResponse> {
      return localVarFp
        .messageUserSendAdminMessage(adminSendMessageRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserSendMessageRequest} userSendMessageRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    messageUserSendMessage(
      userSendMessageRequest: UserSendMessageRequest,
      options?: any,
    ): AxiosPromise<UserSendMessageResponse> {
      return localVarFp
        .messageUserSendMessage(userSendMessageRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {FindNewsRequest} findNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageV2FindNews(findNewsRequest: FindNewsRequest, options?: any): AxiosPromise<FindNewsResponse> {
      return localVarFp.messageV2FindNews(findNewsRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {NewsGetRequest} newsGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageV2GetNews(newsGetRequest: NewsGetRequest, options?: any): AxiosPromise<NewsGetResponse> {
      return localVarFp.messageV2GetNews(newsGetRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ReadNewsRequest} readNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageV2ReadNews(readNewsRequest: ReadNewsRequest, options?: any): AxiosPromise<ReadNewsResponse> {
      return localVarFp.messageV2ReadNews(readNewsRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {NativeChargeLogGetRequest} nativeChargeLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nativeLogNativeGetChargeLogs(
      nativeChargeLogGetRequest: NativeChargeLogGetRequest,
      options?: any,
    ): AxiosPromise<NativeChargeLogGetResponse> {
      return localVarFp
        .nativeLogNativeGetChargeLogs(nativeChargeLogGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    nativeLogNativeGetGateLogStatus(options?: any): AxiosPromise<NativeGateLogStatusGetResponse> {
      return localVarFp.nativeLogNativeGetGateLogStatus(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {NativeGateLogGetRequest} nativeGateLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nativeLogNativeGetGateLogs(
      nativeGateLogGetRequest: NativeGateLogGetRequest,
      options?: any,
    ): AxiosPromise<NativeGateLogGetResponse> {
      return localVarFp
        .nativeLogNativeGetGateLogs(nativeGateLogGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SearchNewsRequest} searchNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsAdminGetUserByFilter(searchNewsRequest: SearchNewsRequest, options?: any): AxiosPromise<SearchNewsResponse> {
      return localVarFp
        .newsAdminGetUserByFilter(searchNewsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateNewsRequest} createNewsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsCreateNews(createNewsRequest: CreateNewsRequest, options?: any): AxiosPromise<CreateNewsResponse> {
      return localVarFp.newsCreateNews(createNewsRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {NewsGetRequest} newsGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsGetNews(newsGetRequest: NewsGetRequest, options?: any): AxiosPromise<NewsGetResponse> {
      return localVarFp.newsGetNews(newsGetRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreatePaidyRequest} createPaidyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseCreatePaidy(createPaidyRequest: CreatePaidyRequest, options?: any): AxiosPromise<CreatePaidyResponse> {
      return localVarFp.purchaseCreatePaidy(createPaidyRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreatePayjpRequest} createPayjpRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseCreatePayjp(createPayjpRequest: CreatePayjpRequest, options?: any): AxiosPromise<CreatePayjpResponse> {
      return localVarFp.purchaseCreatePayjp(createPayjpRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseDeletePaidy(options?: any): AxiosPromise<DeletePaidyResponse> {
      return localVarFp.purchaseDeletePaidy(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseDeletePayjp(options?: any): AxiosPromise<DeletePayjpResponse> {
      return localVarFp.purchaseDeletePayjp(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseGetPaymentWays(options?: any): AxiosPromise<GetPaymentWaysResponse> {
      return localVarFp.purchaseGetPaymentWays(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchasePaidyHTML(options?: any): AxiosPromise<string> {
      return localVarFp.purchasePaidyHTML(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseV2GetPayjpStaticPage(options?: any): AxiosPromise<string> {
      return localVarFp.purchaseV2GetPayjpStaticPage(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2MakeSubscribeRequest} v2MakeSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseV2MakeSubscribe(
      v2MakeSubscribeRequest: V2MakeSubscribeRequest,
      options?: any,
    ): AxiosPromise<V2MakeSubscribeResponse> {
      return localVarFp
        .purchaseV2MakeSubscribe(v2MakeSubscribeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2MakeTemporaryUseRequest} v2MakeTemporaryUseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    purchaseV2MakeTemporaryUse(
      v2MakeTemporaryUseRequest: V2MakeTemporaryUseRequest,
      options?: any,
    ): AxiosPromise<V2MakeTemporaryUseResponse> {
      return localVarFp
        .purchaseV2MakeTemporaryUse(v2MakeTemporaryUseRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {FromEntranceRequest} fromEntranceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    qRFromEntrance(fromEntranceRequest: FromEntranceRequest, options?: any): AxiosPromise<FromEntranceResponse> {
      return localVarFp.qRFromEntrance(fromEntranceRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rewardExchangeReward(id: number, options?: any): AxiosPromise<V2ExchangeResponse> {
      return localVarFp.rewardExchangeReward(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rewardGetReward(id: number, options?: any): AxiosPromise<V2GetRewardResponse> {
      return localVarFp.rewardGetReward(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2GetRewardsRequest} v2GetRewardsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rewardGetRewards(v2GetRewardsRequest: V2GetRewardsRequest, options?: any): AxiosPromise<V2GetRewardsResponse> {
      return localVarFp.rewardGetRewards(v2GetRewardsRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2GetRewardSectionsRequest} v2GetRewardSectionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rewardSectionGetRewardSections(
      v2GetRewardSectionsRequest: V2GetRewardSectionsRequest,
      options?: any,
    ): AxiosPromise<V2GetRewardSectionsResponse> {
      return localVarFp
        .rewardSectionGetRewardSections(v2GetRewardSectionsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} gymId
     * @param {number} limit
     * @param {number} offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesSummaryGetSalesSummaries(
      gymId: number,
      limit: number,
      offset: number,
      options?: any,
    ): AxiosPromise<GetSalesSummariesResponse> {
      return localVarFp
        .salesSummaryGetSalesSummaries(gymId, limit, offset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateSmsVerificationRequest} createSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsVerificationCreateSmsVerification(
      createSmsVerificationRequest: CreateSmsVerificationRequest,
      options?: any,
    ): AxiosPromise<CreateSmsVerificationResponse> {
      return localVarFp
        .smsVerificationCreateSmsVerification(createSmsVerificationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateSmsVerificationRequest} updateSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    smsVerificationUpdateSmsVerification(
      updateSmsVerificationRequest: UpdateSmsVerificationRequest,
      options?: any,
    ): AxiosPromise<UpdateSmsVerificationResponse> {
      return localVarFp
        .smsVerificationUpdateSmsVerification(updateSmsVerificationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffGetAdmin(options?: any): AxiosPromise<GetStaffResponse> {
      return localVarFp.staffGetAdmin(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StaffGymListRequest} staffGymListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffGetStaffGymList(staffGymListRequest: StaffGymListRequest, options?: any): AxiosPromise<StaffGymListResponse> {
      return localVarFp.staffGetStaffGymList(staffGymListRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffLogin(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
      return localVarFp.staffLogin(loginRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffLogout(options?: any): AxiosPromise<void> {
      return localVarFp.staffLogout(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RefreshTokenRequest} refreshTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffRefreshToken(refreshTokenRequest: RefreshTokenRequest, options?: any): AxiosPromise<RefreshTokenResponse> {
      return localVarFp.staffRefreshToken(refreshTokenRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeGymStoreGetGymInfos(options?: any): AxiosPromise<StoreGetAllStaffGymsResponse> {
      return localVarFp.storeGymStoreGetGymInfos(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeGymStoreGetGymStatuses(options?: any): AxiosPromise<StoreGymGetResponse> {
      return localVarFp.storeGymStoreGetGymStatuses(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StoreGateLogGetRequest} storeGateLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeLogStoreGetGateLogs(
      storeGateLogGetRequest: StoreGateLogGetRequest,
      options?: any,
    ): AxiosPromise<StoreGateLogGetResponse> {
      return localVarFp
        .storeLogStoreGetGateLogs(storeGateLogGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StorePaymentLogGetRequest} storePaymentLogGetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeLogStoreGetPurchaseLogs(
      storePaymentLogGetRequest: StorePaymentLogGetRequest,
      options?: any,
    ): AxiosPromise<StorePaymentLogGetResponse> {
      return localVarFp
        .storeLogStoreGetPurchaseLogs(storePaymentLogGetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} gymId
     * @param {StaffUserMessagesByGymRequest} staffUserMessagesByGymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeMessageGetStaffMessageByGym(
      gymId: string,
      staffUserMessagesByGymRequest: StaffUserMessagesByGymRequest,
      options?: any,
    ): AxiosPromise<StaffUserMessagesByGymResponse> {
      return localVarFp
        .storeMessageGetStaffMessageByGym(gymId, staffUserMessagesByGymRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StaffGetMessagesRequest} staffGetMessagesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeMessageGetStaffMessages(
      staffGetMessagesRequest: StaffGetMessagesRequest,
      options?: any,
    ): AxiosPromise<StaffGetMessagesResponse> {
      return localVarFp
        .storeMessageGetStaffMessages(staffGetMessagesRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StaffReadMessageRequest} staffReadMessageRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    storeMessageReadMessage(staffReadMessageRequest: StaffReadMessageRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .storeMessageReadMessage(staffReadMessageRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StaffChangeMessageReadRequest} staffChangeMessageReadRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    storeMessageStaffChangeMessageRead(
      staffChangeMessageReadRequest: StaffChangeMessageReadRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .storeMessageStaffChangeMessageRead(staffChangeMessageReadRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} gymId
     * @param {UserSendStaffMessageRequest} userSendStaffMessageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeMessageUserSendStaffMessage(
      gymId: string,
      userSendStaffMessageRequest: UserSendStaffMessageRequest,
      options?: any,
    ): AxiosPromise<UserSendStaffMessageResponse> {
      return localVarFp
        .storeMessageUserSendStaffMessage(gymId, userSendStaffMessageRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StaffGetSubscribeRequest} staffGetSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeUserStoreGetSubscribe(
      staffGetSubscribeRequest: StaffGetSubscribeRequest,
      options?: any,
    ): AxiosPromise<StaffGetSubscribeResponse> {
      return localVarFp
        .storeUserStoreGetSubscribe(staffGetSubscribeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StaffGetTempUseTicketRequest} staffGetTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeUserStoreGetTempUseTickets(
      staffGetTempUseTicketRequest: StaffGetTempUseTicketRequest,
      options?: any,
    ): AxiosPromise<StaffGetTempUseTicketResponse> {
      return localVarFp
        .storeUserStoreGetTempUseTickets(staffGetTempUseTicketRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StoreGetUserFilterRequest} storeGetUserFilterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeUserStoreGetUserByFilter(
      storeGetUserFilterRequest: StoreGetUserFilterRequest,
      options?: any,
    ): AxiosPromise<StoreGetUserResponse> {
      return localVarFp
        .storeUserStoreGetUserByFilter(storeGetUserFilterRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StaffUserAddTempUseTicketRequest} staffUserAddTempUseTicketRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    storeUserStoreUserAddTempUseTicket(
      staffUserAddTempUseTicketRequest: StaffUserAddTempUseTicketRequest,
      options?: any,
    ): AxiosPromise<StaffUserAddTempUseTicketResponse> {
      return localVarFp
        .storeUserStoreUserAddTempUseTicket(staffUserAddTempUseTicketRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscribeV2CancelUnsubscribe(id: number, options?: any): AxiosPromise<object> {
      return localVarFp.subscribeV2CancelUnsubscribe(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscribeV2Unsubscribe(id: number, options?: any): AxiosPromise<V2SubscribeCancelResponse> {
      return localVarFp.subscribeV2Unsubscribe(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2CancelStopSubscribeRequest} v2CancelStopSubscribeRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2CancelStopSubscribe(
      v2CancelStopSubscribeRequest: V2CancelStopSubscribeRequest,
      options?: any,
    ): AxiosPromise<V2CancelStopSubscribeResponse> {
      return localVarFp
        .supplyV2CancelStopSubscribe(v2CancelStopSubscribeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2GetPictures(options?: any): AxiosPromise<V2GetPicturesResponse> {
      return localVarFp.supplyV2GetPictures(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2GetShots(options?: any): AxiosPromise<V2GetShotResponse> {
      return localVarFp.supplyV2GetShots(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2GetTemporaryUses(options?: any): AxiosPromise<V2GetTemporaryUseResponse> {
      return localVarFp.supplyV2GetTemporaryUses(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2GetUserEntranceToken(options?: any): AxiosPromise<V2GetUserEntranceTokenResponse> {
      return localVarFp.supplyV2GetUserEntranceToken(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2StopSubscribeRequest} v2StopSubscribeRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2StopSubscribe(
      v2StopSubscribeRequest: V2StopSubscribeRequest,
      options?: any,
    ): AxiosPromise<V2StopSubscribeResponse> {
      return localVarFp
        .supplyV2StopSubscribe(v2StopSubscribeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2UseShotRequest} v2UseShotRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2UseShot(v2UseShotRequest: V2UseShotRequest, options?: any): AxiosPromise<V2UseShotResponse> {
      return localVarFp.supplyV2UseShot(v2UseShotRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2UseTemporaryUseRequest} v2UseTemporaryUseRequest
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    supplyV2UseTemporaryUse(
      v2UseTemporaryUseRequest: V2UseTemporaryUseRequest,
      options?: any,
    ): AxiosPromise<V2UseTemporaryUseResponse> {
      return localVarFp
        .supplyV2UseTemporaryUse(v2UseTemporaryUseRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} surveyId
     * @param {CreateSurveyQuestionResponsesRequest} createSurveyQuestionResponsesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    surveyCreateSurveyQuestionResponses(
      surveyId: string,
      createSurveyQuestionResponsesRequest: CreateSurveyQuestionResponsesRequest,
      options?: any,
    ): AxiosPromise<CreateSurveyQuestionResponsesResponse> {
      return localVarFp
        .surveyCreateSurveyQuestionResponses(surveyId, createSurveyQuestionResponsesRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} surveyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    surveyGetSurvey(surveyId: string, options?: any): AxiosPromise<GetSurveyResponse> {
      return localVarFp.surveyGetSurvey(surveyId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {GetAddressByPostalCodeRequest} getAddressByPostalCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetAddressByPostalCode(
      getAddressByPostalCodeRequest: GetAddressByPostalCodeRequest,
      options?: any,
    ): AxiosPromise<GetAddressByPostalCodeResponse> {
      return localVarFp
        .userGetAddressByPostalCode(getAddressByPostalCodeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGetUser(options?: any): AxiosPromise<GetUserResponse> {
      return localVarFp.userGetUser(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateNotificationTokenRequest} updateNotificationTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUpdateNotificationToken(
      updateNotificationTokenRequest: UpdateNotificationTokenRequest,
      options?: any,
    ): AxiosPromise<UpdateNotificationTokenResponse> {
      return localVarFp
        .userUpdateNotificationToken(updateNotificationTokenRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2CreateSmsVerificationRequest} v2CreateSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2CreateSmsVerification(
      v2CreateSmsVerificationRequest: V2CreateSmsVerificationRequest,
      options?: any,
    ): AxiosPromise<V2CreateSmsVerificationResponse> {
      return localVarFp
        .userV2CreateSmsVerification(v2CreateSmsVerificationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetActiveSubscribe(options?: any): AxiosPromise<V2GetActiveSubscribesResponse> {
      return localVarFp.userV2GetActiveSubscribe(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetAppContentSections(options?: any): AxiosPromise<V2GetUserAppContentSectionsResponse> {
      return localVarFp.userV2GetAppContentSections(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetFriendInvitation(options?: any): AxiosPromise<V2GetFriendInvitationResponse> {
      return localVarFp.userV2GetFriendInvitation(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetHomeAppContents(options?: any): AxiosPromise<V2GetUserHomeAppContentsResponse> {
      return localVarFp.userV2GetHomeAppContents(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetMySchedules(options?: any): AxiosPromise<V2GetMySchedulesResponse> {
      return localVarFp.userV2GetMySchedules(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} pageSize
     * @param {string} pageToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetPointHistories(
      pageSize: number,
      pageToken: string,
      options?: any,
    ): AxiosPromise<V2GetPointHistoriesResponse> {
      return localVarFp
        .userV2GetPointHistories(pageSize, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetPointSummary(options?: any): AxiosPromise<V2GetUserPointSummaryResponse> {
      return localVarFp.userV2GetPointSummary(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetTickets(options?: any): AxiosPromise<V2GetTicketsResponse> {
      return localVarFp.userV2GetTickets(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetUpdateSubscribeCoupons(id: number, options?: any): AxiosPromise<V2GetUpdateSubscribeCouponsResponse> {
      return localVarFp.userV2GetUpdateSubscribeCoupons(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2GetUsualGyms(limit: string, options?: any): AxiosPromise<V2GetUsualGymsResponse> {
      return localVarFp.userV2GetUsualGyms(limit, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2LoginRequest} v2LoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2Login(v2LoginRequest: V2LoginRequest, options?: any): AxiosPromise<V2LoginResponse> {
      return localVarFp.userV2Login(v2LoginRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2PhoneNumberSendCodeRequest} v2PhoneNumberSendCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2PhoneNumberSendCode(
      v2PhoneNumberSendCodeRequest: V2PhoneNumberSendCodeRequest,
      options?: any,
    ): AxiosPromise<V2PhoneNumberSendCodeResponse> {
      return localVarFp
        .userV2PhoneNumberSendCode(v2PhoneNumberSendCodeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2PhoneNumberVerifyRequest} v2PhoneNumberVerifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2PhoneNumberVerify(
      v2PhoneNumberVerifyRequest: V2PhoneNumberVerifyRequest,
      options?: any,
    ): AxiosPromise<V2PhoneNumberVerifyResponse> {
      return localVarFp
        .userV2PhoneNumberVerify(v2PhoneNumberVerifyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2SetPasswordRequest} v2SetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2SetPassword(v2SetPasswordRequest: V2SetPasswordRequest, options?: any): AxiosPromise<V2SetPasswordResponse> {
      return localVarFp.userV2SetPassword(v2SetPasswordRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2SetPasswordStrictRequest} v2SetPasswordStrictRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2SetPasswordStrict(
      v2SetPasswordStrictRequest: V2SetPasswordStrictRequest,
      options?: any,
    ): AxiosPromise<V2SetPasswordStrictResponse> {
      return localVarFp
        .userV2SetPasswordStrict(v2SetPasswordStrictRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2CreateRequest} v2CreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2SignUp(v2CreateRequest: V2CreateRequest, options?: any): AxiosPromise<V2CreateResponse> {
      return localVarFp.userV2SignUp(v2CreateRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2UpdateRequest} v2UpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2Update(v2UpdateRequest: V2UpdateRequest, options?: any): AxiosPromise<V2UpdateResponse> {
      return localVarFp.userV2Update(v2UpdateRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2UpdateMyScheduleRequest} v2UpdateMyScheduleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2UpdateMySchedule(
      v2UpdateMyScheduleRequest: V2UpdateMyScheduleRequest,
      options?: any,
    ): AxiosPromise<V2UpdateMySchedulesResponse> {
      return localVarFp
        .userV2UpdateMySchedule(v2UpdateMyScheduleRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {V2UpdateSmsVerificationRequest} v2UpdateSmsVerificationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2UpdateSmsVerification(
      v2UpdateSmsVerificationRequest: V2UpdateSmsVerificationRequest,
      options?: any,
    ): AxiosPromise<V2UpdateSmsVerificationResponse> {
      return localVarFp
        .userV2UpdateSmsVerification(v2UpdateSmsVerificationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {V2UpdateSubscribeRequest} v2UpdateSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2UpdateUserSubscribes(
      id: number,
      v2UpdateSubscribeRequest: V2UpdateSubscribeRequest,
      options?: any,
    ): AxiosPromise<V2UpdateSubscribeResponse> {
      return localVarFp
        .userV2UpdateUserSubscribes(id, v2UpdateSubscribeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userV2Withdraw(options?: any): AxiosPromise<V2WithdrawResponse> {
      return localVarFp.userV2Withdraw(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {AdminCreateAppContentRequest} adminCreateAppContentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminAppContentCreateAppContent(
    adminCreateAppContentRequest: AdminCreateAppContentRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminAppContentCreateAppContent(adminCreateAppContentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {AdminCreatePresignedUrlForAppContentRequest} adminCreatePresignedUrlForAppContentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminAppContentCreatePresignedUrl(
    id: number,
    adminCreatePresignedUrlForAppContentRequest: AdminCreatePresignedUrlForAppContentRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminAppContentCreatePresignedUrl(id, adminCreatePresignedUrlForAppContentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminAppContentGetAppContent(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminAppContentGetAppContent(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminAppContentSectionGetAppContentSections(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminAppContentSectionGetAppContentSections(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminAppContentSectionGetAppContentSectionsWithContents(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminAppContentSectionGetAppContentSectionsWithContents(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {AdminUpdateAppContentSectionContentsPriorityRequest} adminUpdateAppContentSectionContentsPriorityRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminAppContentSectionUpdateAppContentSectionContentsPriority(
    id: number,
    adminUpdateAppContentSectionContentsPriorityRequest: AdminUpdateAppContentSectionContentsPriorityRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminAppContentSectionUpdateAppContentSectionContentsPriority(
        id,
        adminUpdateAppContentSectionContentsPriorityRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {AdminUpdateAppContentRequest} adminUpdateAppContentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminAppContentUpdateAppContent(
    id: number,
    adminUpdateAppContentRequest: AdminUpdateAppContentRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminAppContentUpdateAppContent(id, adminUpdateAppContentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminCreateCampaignCodeRequest} adminCreateCampaignCodeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminCampaignsAdminCreateCampaignCode(
    adminCreateCampaignCodeRequest: AdminCreateCampaignCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminCampaignsAdminCreateCampaignCode(adminCreateCampaignCodeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminGetCampaignsRequest} adminGetCampaignsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminCampaignsAdminGetCampaigns(
    adminGetCampaignsRequest: AdminGetCampaignsRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminCampaignsAdminGetCampaigns(adminGetCampaignsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminUpdateCampaignRequest} adminUpdateCampaignRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminCampaignsAdminUpdateCampaign(
    adminUpdateCampaignRequest: AdminUpdateCampaignRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminCampaignsAdminUpdateCampaign(adminUpdateCampaignRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminGetAdmin(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminGetAdmin(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminGymsGetAllBrands(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminGymsGetAllBrands(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminGymsGetAllEquipments(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminGymsGetAllEquipments(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminGymsGetAllGyms(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminGymsGetAllGyms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminGymsGetAllOptionPlans(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminGymsGetAllOptionPlans(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminGymsGetAllShotPlans(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminGymsGetAllShotPlans(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LoginRequest} loginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminLogin(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminLogin(loginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminLogout(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminLogout(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreatePartnerRequest} createPartnerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminPartnersCreateNewPartner(createPartnerRequest: CreatePartnerRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminPartnersCreateNewPartner(createPartnerRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeletePartnerRequest} deletePartnerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminPartnersDeletePartner(deletePartnerRequest: DeletePartnerRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminPartnersDeletePartner(deletePartnerRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditPartnerRequest} editPartnerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminPartnersEditPartner(editPartnerRequest: EditPartnerRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminPartnersEditPartner(editPartnerRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminPartnersGetAllPartners(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminPartnersGetAllPartners(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminPartnersGetPartnerInfo(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminPartnersGetPartnerInfo(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RefreshTokenRequest} refreshTokenRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminRefreshToken(refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminRefreshToken(refreshTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {AdminCreatePresignedUrlForRewardRequest} adminCreatePresignedUrlForRewardRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminRewardCreatePresignedUrl(
    id: number,
    adminCreatePresignedUrlForRewardRequest: AdminCreatePresignedUrlForRewardRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminRewardCreatePresignedUrl(id, adminCreatePresignedUrlForRewardRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminCreateRewardRequest} adminCreateRewardRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminRewardCreateReward(adminCreateRewardRequest: AdminCreateRewardRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminRewardCreateReward(adminCreateRewardRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminRewardGetReward(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminRewardGetReward(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} pageSize
   * @param {number} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminRewardGetRewards(pageSize: number, page: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminRewardGetRewards(pageSize, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminRewardTagGetRewardTags(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminRewardTagGetRewardTags(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {AdminUpdateRewardRequest} adminUpdateRewardRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminRewardUpdateReward(
    id: number,
    adminUpdateRewardRequest: AdminUpdateRewardRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminRewardUpdateReward(id, adminUpdateRewardRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreatePlanRequest} createPlanRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminSubscribePlansCreatePlan(createPlanRequest: CreatePlanRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminSubscribePlansCreatePlan(createPlanRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {boolean} [isCampaignApplicable]
   * @param {boolean} [hasMinimumSubscriptionRenewalCount]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminSubscribePlansGetAllSubscribePlans(
    isCampaignApplicable?: boolean,
    hasMinimumSubscriptionRenewalCount?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminSubscribePlansGetAllSubscribePlans(isCampaignApplicable, hasMinimumSubscriptionRenewalCount, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminSubscribePlansGetSubscribePlan(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminSubscribePlansGetSubscribePlan(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {UpdatePlanRequest} updatePlanRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminSubscribePlansUpdatePlan(id: number, updatePlanRequest: UpdatePlanRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminSubscribePlansUpdatePlan(id, updatePlanRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {AddPointsRequest} addPointsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserAddPoints(userId: number, addPointsRequest: AddPointsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminUserAddPoints(userId, addPointsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminGetSubscribeRequest} adminGetSubscribeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserAdminGetSubscribe(adminGetSubscribeRequest: AdminGetSubscribeRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminUserAdminGetSubscribe(adminGetSubscribeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminGetTempUseTicketRequest} adminGetTempUseTicketRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserAdminGetTempUseTickets(
    adminGetTempUseTicketRequest: AdminGetTempUseTicketRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminUserAdminGetTempUseTickets(adminGetTempUseTicketRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminUserAddTempUseTicketRequest} adminUserAddTempUseTicketRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserAdminUserAddTempUseTicket(
    adminUserAddTempUseTicketRequest: AdminUserAddTempUseTicketRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminUserAdminUserAddTempUseTicket(adminUserAddTempUseTicketRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminUserDeleteTempUseTicketRequest} adminUserDeleteTempUseTicketRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserAdminUserDeleteTempUseTicket(
    adminUserDeleteTempUseTicketRequest: AdminUserDeleteTempUseTicketRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminUserAdminUserDeleteTempUseTicket(adminUserDeleteTempUseTicketRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {AdminUserUpdatePhoneNumberRequest} adminUserUpdatePhoneNumberRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserAdminUserUpdatePhoneNumber(
    id: string,
    adminUserUpdatePhoneNumberRequest: AdminUserUpdatePhoneNumberRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminUserAdminUserUpdatePhoneNumber(id, adminUserUpdatePhoneNumberRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserBan(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminUserBan(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {number} subscribeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserGetUpdatableSubscribePlans(userId: number, subscribeId: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminUserGetUpdatableSubscribePlans(userId, subscribeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserGetUser(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminUserGetUser(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2AdminSearchUsersRequest} v2AdminSearchUsersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserSearchUser(v2AdminSearchUsersRequest: V2AdminSearchUsersRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminUserSearchUser(v2AdminSearchUsersRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {number} subscribeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserUnsubscribe(userId: number, subscribeId: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminUserUnsubscribe(userId, subscribeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {number} subscribeId
   * @param {UpdateSubscribeRequest} updateSubscribeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminUserUpdateSubscribe(
    userId: number,
    subscribeId: number,
    updateSubscribeRequest: UpdateSubscribeRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminUserUpdateSubscribe(userId, subscribeId, updateSubscribeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminCreateCampaignRequest} adminCreateCampaignRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminV2CampaignsAdminCreateCampaign(
    adminCreateCampaignRequest: AdminCreateCampaignRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .adminV2CampaignsAdminCreateCampaign(adminCreateCampaignRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminV2CampaignsAdminGetCampaign(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .adminV2CampaignsAdminGetCampaign(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appContentGetHomeAppContents(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .appContentGetHomeAppContents(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appContentSectionGetAppContentSections(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .appContentSectionGetAppContentSections(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ChannelTalkBatchRequest} channelTalkBatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configExecBatchChannelTalk(channelTalkBatchRequest: ChannelTalkBatchRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configExecBatchChannelTalk(channelTalkBatchRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configExecBatchNotifyCouponExpirationDayBefore(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configExecBatchNotifyCouponExpirationDayBefore(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configExecBatchNotifyCouponExpirationFewDaysBefore(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configExecBatchNotifyCouponExpirationFewDaysBefore(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PresentPointBatchRequest} presentPointBatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configExecBatchPresentPoint(presentPointBatchRequest: PresentPointBatchRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configExecBatchPresentPoint(presentPointBatchRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configExecBatchSale(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configExecBatchSale(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SalesSummaryBatchRequest} salesSummaryBatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configExecBatchSalesSummary(salesSummaryBatchRequest: SalesSummaryBatchRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configExecBatchSalesSummary(salesSummaryBatchRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StartSubscribeRequest} startSubscribeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configExecBatchStartSubscribe(startSubscribeRequest: StartSubscribeRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configExecBatchStartSubscribe(startSubscribeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SubscribeBatchRequest} subscribeBatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configExecBatchSubscribe(subscribeBatchRequest: SubscribeBatchRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configExecBatchSubscribe(subscribeBatchRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StoreCreateStaffRequest} storeCreateStaffRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configStoreCreateStaffs(storeCreateStaffRequest: StoreCreateStaffRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configStoreCreateStaffs(storeCreateStaffRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StoreDeleteStaffRequest} storeDeleteStaffRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configStoreDeleteStaff(storeDeleteStaffRequest: StoreDeleteStaffRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configStoreDeleteStaff(storeDeleteStaffRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StoreEditStaffRequest} storeEditStaffRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configStoreEditStaff(storeEditStaffRequest: StoreEditStaffRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configStoreEditStaff(storeEditStaffRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configStoreGetGyms(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configStoreGetGyms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configStoreGetRoles(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configStoreGetRoles(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public configStoreGetStaffs(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .configStoreGetStaffs(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {'subscribe' | 'temporaryUse'} purchaseType
   * @param {number} gymId
   * @param {number} [subscribePlanId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public couponV2GetActiveCouponsForPurchase(
    purchaseType: 'subscribe' | 'temporaryUse',
    gymId: number,
    subscribePlanId?: number,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .couponV2GetActiveCouponsForPurchase(purchaseType, gymId, subscribePlanId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetCouponByCodeRequest} getCouponByCodeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public couponV2GetCouponByCode(getCouponByCodeRequest: GetCouponByCodeRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .couponV2GetCouponByCode(getCouponByCodeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public couponV2GetCoupons(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .couponV2GetCoupons(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateGymRequest} createGymRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymCreateGym(createGymRequest: CreateGymRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymCreateGym(createGymRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} gymId
   * @param {CreateGymAnnouncementRequest} createGymAnnouncementRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymCreateGymAnnouncement(
    gymId: number,
    createGymAnnouncementRequest: CreateGymAnnouncementRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gymCreateGymAnnouncement(gymId, createGymAnnouncementRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {CreatePresignedUrlRequest} createPresignedUrlRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymCreatePresignedUrl(
    id: number,
    createPresignedUrlRequest: CreatePresignedUrlRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gymCreatePresignedUrl(id, createPresignedUrlRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymGetGym(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymGetGym(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} gymId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymGetGymAnnouncements(gymId: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymGetGymAnnouncements(gymId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NearGymRequest} nearGymRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymNearGym(nearGymRequest: NearGymRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymNearGym(nearGymRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SearchGymRequest} searchGymRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymSearchGym(searchGymRequest: SearchGymRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymSearchGym(searchGymRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {UpdateGymRequest} updateGymRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymUpdateGym(id: number, updateGymRequest: UpdateGymRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymUpdateGym(id, updateGymRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} gymId
   * @param {number} announcementId
   * @param {UpdateGymAnnouncementRequest} updateGymAnnouncementRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymUpdateGymAnnouncement(
    gymId: number,
    announcementId: number,
    updateGymAnnouncementRequest: UpdateGymAnnouncementRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gymUpdateGymAnnouncement(gymId, announcementId, updateGymAnnouncementRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymV2GetAllEquipments(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymV2GetAllEquipments(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {DayOfWeek} dayOfWeek
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymV2GetCongestionTimeline(id: number, dayOfWeek: DayOfWeek, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymV2GetCongestionTimeline(id, dayOfWeek, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymV2GetGymDetail(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymV2GetGymDetail(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {number} planId
   * @param {number} couponId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymV2GetSubscribePlanAppliedCouponPrice(
    id: number,
    planId: number,
    couponId: number,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gymV2GetSubscribePlanAppliedCouponPrice(id, planId, couponId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {number} couponId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymV2GetTemporaryUseAppliedCouponPrice(id: number, couponId: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymV2GetTemporaryUseAppliedCouponPrice(id, couponId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2NearGymRequest} v2NearGymRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymV2NearGym(v2NearGymRequest: V2NearGymRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymV2NearGym(v2NearGymRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2SearchGymRequest} v2SearchGymRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gymV2SearchGym(v2SearchGymRequest: V2SearchGymRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gymV2SearchGym(v2SearchGymRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public healthHealth(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .healthHealth(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messageGetAdminMessages(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messageGetAdminMessages(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminMessagesUsersRequest} adminMessagesUsersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messageGetAdminMessagesUsers(
    adminMessagesUsersRequest: AdminMessagesUsersRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .messageGetAdminMessagesUsers(adminMessagesUsersRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messageGetMessages(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messageGetMessages(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messageGetUserMessageOfficerList(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messageGetUserMessageOfficerList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReadMessagesRequest} readMessagesRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messageReadMessages(readMessagesRequest: ReadMessagesRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messageReadMessages(readMessagesRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AdminSendMessageRequest} adminSendMessageRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messageUserSendAdminMessage(adminSendMessageRequest: AdminSendMessageRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messageUserSendAdminMessage(adminSendMessageRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserSendMessageRequest} userSendMessageRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messageUserSendMessage(userSendMessageRequest: UserSendMessageRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messageUserSendMessage(userSendMessageRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FindNewsRequest} findNewsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messageV2FindNews(findNewsRequest: FindNewsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messageV2FindNews(findNewsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NewsGetRequest} newsGetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messageV2GetNews(newsGetRequest: NewsGetRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messageV2GetNews(newsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ReadNewsRequest} readNewsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public messageV2ReadNews(readNewsRequest: ReadNewsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .messageV2ReadNews(readNewsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NativeChargeLogGetRequest} nativeChargeLogGetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nativeLogNativeGetChargeLogs(
    nativeChargeLogGetRequest: NativeChargeLogGetRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .nativeLogNativeGetChargeLogs(nativeChargeLogGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nativeLogNativeGetGateLogStatus(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .nativeLogNativeGetGateLogStatus(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NativeGateLogGetRequest} nativeGateLogGetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public nativeLogNativeGetGateLogs(nativeGateLogGetRequest: NativeGateLogGetRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .nativeLogNativeGetGateLogs(nativeGateLogGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SearchNewsRequest} searchNewsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public newsAdminGetUserByFilter(searchNewsRequest: SearchNewsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .newsAdminGetUserByFilter(searchNewsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateNewsRequest} createNewsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public newsCreateNews(createNewsRequest: CreateNewsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .newsCreateNews(createNewsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NewsGetRequest} newsGetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public newsGetNews(newsGetRequest: NewsGetRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .newsGetNews(newsGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreatePaidyRequest} createPaidyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public purchaseCreatePaidy(createPaidyRequest: CreatePaidyRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .purchaseCreatePaidy(createPaidyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreatePayjpRequest} createPayjpRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public purchaseCreatePayjp(createPayjpRequest: CreatePayjpRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .purchaseCreatePayjp(createPayjpRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public purchaseDeletePaidy(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .purchaseDeletePaidy(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public purchaseDeletePayjp(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .purchaseDeletePayjp(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public purchaseGetPaymentWays(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .purchaseGetPaymentWays(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public purchasePaidyHTML(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .purchasePaidyHTML(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public purchaseV2GetPayjpStaticPage(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .purchaseV2GetPayjpStaticPage(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2MakeSubscribeRequest} v2MakeSubscribeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public purchaseV2MakeSubscribe(v2MakeSubscribeRequest: V2MakeSubscribeRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .purchaseV2MakeSubscribe(v2MakeSubscribeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2MakeTemporaryUseRequest} v2MakeTemporaryUseRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public purchaseV2MakeTemporaryUse(
    v2MakeTemporaryUseRequest: V2MakeTemporaryUseRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .purchaseV2MakeTemporaryUse(v2MakeTemporaryUseRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FromEntranceRequest} fromEntranceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public qRFromEntrance(fromEntranceRequest: FromEntranceRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .qRFromEntrance(fromEntranceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public rewardExchangeReward(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .rewardExchangeReward(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public rewardGetReward(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .rewardGetReward(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2GetRewardsRequest} v2GetRewardsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public rewardGetRewards(v2GetRewardsRequest: V2GetRewardsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .rewardGetRewards(v2GetRewardsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2GetRewardSectionsRequest} v2GetRewardSectionsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public rewardSectionGetRewardSections(
    v2GetRewardSectionsRequest: V2GetRewardSectionsRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .rewardSectionGetRewardSections(v2GetRewardSectionsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} gymId
   * @param {number} limit
   * @param {number} offset
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public salesSummaryGetSalesSummaries(gymId: number, limit: number, offset: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .salesSummaryGetSalesSummaries(gymId, limit, offset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateSmsVerificationRequest} createSmsVerificationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public smsVerificationCreateSmsVerification(
    createSmsVerificationRequest: CreateSmsVerificationRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .smsVerificationCreateSmsVerification(createSmsVerificationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateSmsVerificationRequest} updateSmsVerificationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public smsVerificationUpdateSmsVerification(
    updateSmsVerificationRequest: UpdateSmsVerificationRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .smsVerificationUpdateSmsVerification(updateSmsVerificationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffGetAdmin(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .staffGetAdmin(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StaffGymListRequest} staffGymListRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffGetStaffGymList(staffGymListRequest: StaffGymListRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .staffGetStaffGymList(staffGymListRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LoginRequest} loginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffLogin(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .staffLogin(loginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffLogout(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .staffLogout(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RefreshTokenRequest} refreshTokenRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffRefreshToken(refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .staffRefreshToken(refreshTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeGymStoreGetGymInfos(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .storeGymStoreGetGymInfos(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeGymStoreGetGymStatuses(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .storeGymStoreGetGymStatuses(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StoreGateLogGetRequest} storeGateLogGetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeLogStoreGetGateLogs(storeGateLogGetRequest: StoreGateLogGetRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .storeLogStoreGetGateLogs(storeGateLogGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StorePaymentLogGetRequest} storePaymentLogGetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeLogStoreGetPurchaseLogs(
    storePaymentLogGetRequest: StorePaymentLogGetRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .storeLogStoreGetPurchaseLogs(storePaymentLogGetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} gymId
   * @param {StaffUserMessagesByGymRequest} staffUserMessagesByGymRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeMessageGetStaffMessageByGym(
    gymId: string,
    staffUserMessagesByGymRequest: StaffUserMessagesByGymRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .storeMessageGetStaffMessageByGym(gymId, staffUserMessagesByGymRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StaffGetMessagesRequest} staffGetMessagesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeMessageGetStaffMessages(staffGetMessagesRequest: StaffGetMessagesRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .storeMessageGetStaffMessages(staffGetMessagesRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StaffReadMessageRequest} staffReadMessageRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeMessageReadMessage(staffReadMessageRequest: StaffReadMessageRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .storeMessageReadMessage(staffReadMessageRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StaffChangeMessageReadRequest} staffChangeMessageReadRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeMessageStaffChangeMessageRead(
    staffChangeMessageReadRequest: StaffChangeMessageReadRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .storeMessageStaffChangeMessageRead(staffChangeMessageReadRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} gymId
   * @param {UserSendStaffMessageRequest} userSendStaffMessageRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeMessageUserSendStaffMessage(
    gymId: string,
    userSendStaffMessageRequest: UserSendStaffMessageRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .storeMessageUserSendStaffMessage(gymId, userSendStaffMessageRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StaffGetSubscribeRequest} staffGetSubscribeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeUserStoreGetSubscribe(staffGetSubscribeRequest: StaffGetSubscribeRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .storeUserStoreGetSubscribe(staffGetSubscribeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StaffGetTempUseTicketRequest} staffGetTempUseTicketRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeUserStoreGetTempUseTickets(
    staffGetTempUseTicketRequest: StaffGetTempUseTicketRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .storeUserStoreGetTempUseTickets(staffGetTempUseTicketRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StoreGetUserFilterRequest} storeGetUserFilterRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeUserStoreGetUserByFilter(
    storeGetUserFilterRequest: StoreGetUserFilterRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .storeUserStoreGetUserByFilter(storeGetUserFilterRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StaffUserAddTempUseTicketRequest} staffUserAddTempUseTicketRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public storeUserStoreUserAddTempUseTicket(
    staffUserAddTempUseTicketRequest: StaffUserAddTempUseTicketRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .storeUserStoreUserAddTempUseTicket(staffUserAddTempUseTicketRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public subscribeV2CancelUnsubscribe(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .subscribeV2CancelUnsubscribe(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public subscribeV2Unsubscribe(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .subscribeV2Unsubscribe(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2CancelStopSubscribeRequest} v2CancelStopSubscribeRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public supplyV2CancelStopSubscribe(
    v2CancelStopSubscribeRequest: V2CancelStopSubscribeRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .supplyV2CancelStopSubscribe(v2CancelStopSubscribeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public supplyV2GetPictures(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .supplyV2GetPictures(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public supplyV2GetShots(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .supplyV2GetShots(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public supplyV2GetTemporaryUses(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .supplyV2GetTemporaryUses(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public supplyV2GetUserEntranceToken(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .supplyV2GetUserEntranceToken(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2StopSubscribeRequest} v2StopSubscribeRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public supplyV2StopSubscribe(v2StopSubscribeRequest: V2StopSubscribeRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .supplyV2StopSubscribe(v2StopSubscribeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2UseShotRequest} v2UseShotRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public supplyV2UseShot(v2UseShotRequest: V2UseShotRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .supplyV2UseShot(v2UseShotRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2UseTemporaryUseRequest} v2UseTemporaryUseRequest
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public supplyV2UseTemporaryUse(v2UseTemporaryUseRequest: V2UseTemporaryUseRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .supplyV2UseTemporaryUse(v2UseTemporaryUseRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} surveyId
   * @param {CreateSurveyQuestionResponsesRequest} createSurveyQuestionResponsesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public surveyCreateSurveyQuestionResponses(
    surveyId: string,
    createSurveyQuestionResponsesRequest: CreateSurveyQuestionResponsesRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .surveyCreateSurveyQuestionResponses(surveyId, createSurveyQuestionResponsesRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} surveyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public surveyGetSurvey(surveyId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .surveyGetSurvey(surveyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GetAddressByPostalCodeRequest} getAddressByPostalCodeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userGetAddressByPostalCode(
    getAddressByPostalCodeRequest: GetAddressByPostalCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .userGetAddressByPostalCode(getAddressByPostalCodeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userGetUser(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userGetUser(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateNotificationTokenRequest} updateNotificationTokenRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userUpdateNotificationToken(
    updateNotificationTokenRequest: UpdateNotificationTokenRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .userUpdateNotificationToken(updateNotificationTokenRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2CreateSmsVerificationRequest} v2CreateSmsVerificationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2CreateSmsVerification(
    v2CreateSmsVerificationRequest: V2CreateSmsVerificationRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .userV2CreateSmsVerification(v2CreateSmsVerificationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2GetActiveSubscribe(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2GetActiveSubscribe(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2GetAppContentSections(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2GetAppContentSections(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2GetFriendInvitation(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2GetFriendInvitation(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2GetHomeAppContents(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2GetHomeAppContents(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2GetMySchedules(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2GetMySchedules(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} pageSize
   * @param {string} pageToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2GetPointHistories(pageSize: number, pageToken: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2GetPointHistories(pageSize, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2GetPointSummary(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2GetPointSummary(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2GetTickets(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2GetTickets(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2GetUpdateSubscribeCoupons(id: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2GetUpdateSubscribeCoupons(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} limit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2GetUsualGyms(limit: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2GetUsualGyms(limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2LoginRequest} v2LoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2Login(v2LoginRequest: V2LoginRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2Login(v2LoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2PhoneNumberSendCodeRequest} v2PhoneNumberSendCodeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2PhoneNumberSendCode(
    v2PhoneNumberSendCodeRequest: V2PhoneNumberSendCodeRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .userV2PhoneNumberSendCode(v2PhoneNumberSendCodeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2PhoneNumberVerifyRequest} v2PhoneNumberVerifyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2PhoneNumberVerify(v2PhoneNumberVerifyRequest: V2PhoneNumberVerifyRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2PhoneNumberVerify(v2PhoneNumberVerifyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2SetPasswordRequest} v2SetPasswordRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2SetPassword(v2SetPasswordRequest: V2SetPasswordRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2SetPassword(v2SetPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2SetPasswordStrictRequest} v2SetPasswordStrictRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2SetPasswordStrict(v2SetPasswordStrictRequest: V2SetPasswordStrictRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2SetPasswordStrict(v2SetPasswordStrictRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2CreateRequest} v2CreateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2SignUp(v2CreateRequest: V2CreateRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2SignUp(v2CreateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2UpdateRequest} v2UpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2Update(v2UpdateRequest: V2UpdateRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2Update(v2UpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2UpdateMyScheduleRequest} v2UpdateMyScheduleRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2UpdateMySchedule(v2UpdateMyScheduleRequest: V2UpdateMyScheduleRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2UpdateMySchedule(v2UpdateMyScheduleRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {V2UpdateSmsVerificationRequest} v2UpdateSmsVerificationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2UpdateSmsVerification(
    v2UpdateSmsVerificationRequest: V2UpdateSmsVerificationRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .userV2UpdateSmsVerification(v2UpdateSmsVerificationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {V2UpdateSubscribeRequest} v2UpdateSubscribeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2UpdateUserSubscribes(
    id: number,
    v2UpdateSubscribeRequest: V2UpdateSubscribeRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .userV2UpdateUserSubscribes(id, v2UpdateSubscribeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userV2Withdraw(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userV2Withdraw(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
